<template>
    <section class="container sign-container">
        <h1 class="text-center fs-1 fw-bold">Login as patient</h1>
        <p class="text-center already-signed-text"> Don’t have an account? <a href="/sign-up" class="internal-link">Sign up</a></p>

        <div class="create-account-fields-wrapper d-flex flex-column align-items-center">
           <div class="sign-field-block w-100">
                <h6>Email</h6>
                <input type="email" :class="{error: dataError}" class="w-100 sign-field" v-model="userEmail" placeholder="Enter your email">
                <span v-if="dataError" class="error-text">Check your email and try again.</span>
           </div>
           <div class="sign-field-block w-100">
                <h6>Password</h6>
                <input type="password" :class="{erorr: dataError}" class="w-100 sign-field" v-model="userPassword" placeholder="Enter your password">
                <span v-if="dataError" class="error-text">Check your password and try again.</span>
           </div>

            <div class="sign-button-block">
                <button class="next-step-button" @click="signInUser()">Log in</button>
                <span v-if="googleError" class="error-text">Need to create an account with this email</span>
                <button class="next-step-button google-sign" @click="handleAuthClick"><img src="../assets/images/google-logo-colored.svg" alt="">Continue with Google</button>
            </div>
            
        </div>
        <a class="text-center privacy-text" href="/forgot-password">Forgot password?</a>
        <p class="text-center privacy-text" style="margin-top: 20px;">Are you a Therapist? <a class="join-link" href="/therapist/sign-up">Join us</a>/<a class="join-link" href="/therapist/sign-in">Log in</a></p>
     </section>
</template>

<script>
import axios from '@/axios';
import { gapi } from 'gapi-script';

export default{
    data(){
        return{
            userPassword: '',
            userEmail: '',
            dataError: false,
            googleError: false
        }
    },
    mounted(){
        this.handleClientLoad();
    },
    methods: {
        async signInUser(){
            try {
                await axios.post('/client/login', {
                    email: this.userEmail,
                    password: this.userPassword,
                }).then((response) => { // использование стрелочной функции здесь
                    this.$store.dispatch('setTokens', {
                        access: response.data.token, 
                        refresh: response.data.refresh_token
                    });
                    this.$store.dispatch('setUserData', {
                        firstName: response.data.user.first_name,
                        lastName: response.data.user.last_name,
                        email: response.data.user.email,
                        isLoggedIn: true
                    })
                    this.$router.push('/');
                }).catch(async () => {
                    await axios.post('/therapist/login', {
                        email: this.userEmail,
                        password: this.userPassword,
                    }).then((response) => { // использование стрелочной функции здесь
                        this.$store.dispatch('setTokens', {
                            access: response.data.token, 
                            refresh: response.data.refresh_token
                        });
                        this.$store.dispatch('setTherapistData', {
                            id: response.data.user.practitioner_id,
                            firstName: response.data.user.first_name,
                            lastName: response.data.user.last_name,
                            email: response.data.user.email,
                            isLoggedIn: true
                        })
                        this.$router.push('/therapist/dashboard');
                    });
                });
            } catch (error) {
                this.dataError = true;
            }
        },
        handleClientLoad() {
            gapi.load('client:auth2', this.initClient);
        },
        initClient() {
            gapi.client.init({
                clientId: '200963144297-q7h4rc7ouoqn4a7r6erpe3kqsqfck6op.apps.googleusercontent.com',
                scope: 'email'
            }).then(() => {
                this.GoogleAuth = gapi.auth2.getAuthInstance();
            });
        },
        handleAuthClick() {
            this.GoogleAuth.signIn().then(async (googleUser) => {
                // Тут ваш код после успешного входа, например:
                await axios.post('/client/google-sign-in', {
                    first_name: googleUser.getBasicProfile().getGivenName(),
                    email: googleUser.getBasicProfile().getEmail(),
                }).then((response) => { // использование стрелочной функции здесь
                    this.$store.dispatch('setTokens', {
                        access: response.data.token, 
                        refresh: response.data.refresh_token
                    });
                    this.$store.dispatch('setUserData', {
                        firstName: response.data.user.first_name,
                        lastName: response.data.user.last_name,
                        email: response.data.user.email,
                        isLoggedIn: true
                    })
                    this.$router.push('/');
                })
            }).catch(error => {
                console.log(error);
                this.googleError = true;
            });
        }
    }
}
</script>

<style scoped>

    .sign-field{
        background: #fff;
        height: 44px;
        border: 1px solid #E6E8ED;
        border-radius: 8px;
        padding: 10px 12px 10px 12px;
        line-height: 24px;
    }

    h6{
        font-weight: 600;
    }

    .already-signed-text{
        margin-bottom: 0px;
    }

    .privacy-text{
        font-size: 14px;
        color: #3A466E;
        text-decoration: none;
        width: 311px;
    }

    .sign-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 60px;
        margin-bottom: 190px;
    }

    .sign-button-block{
        margin-bottom: 32px;
        width: 312px;
    }

    .create-account-fields-wrapper{
        margin-top: 32px;
        width: 532px;
    }

    .sign-field-block{
        margin-bottom: 24px;
    }

    .google-sign{
        background: #fff;
        border: 1px solid #D0D5DD;
    }

    .error{
        border-color: #FF4E4E;
    }
    
    .error-text{
        color: #FF4E4E;
        font-size: 14px;
    }

    .sign-button{
        padding: 8px 24px 8px 24px;
        border-radius: 40px;
        font-size: 17px;
        font-weight: 600;
        line-height: 28px;
        height: 52px;
        color: #fff;
        border-color: transparent;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 24px;
    }

    .internal-link{
        color: #00809D;
        text-decoration: none;
    }

    @media (max-width: 570px) { 
        .create-account-fields-wrapper{
            width: 100%;
        }
    }
</style>