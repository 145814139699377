<template>
    <footer class="footer mt-auto py-3">
      <div class="container-fluid">
        <div class="footer-top">
          <div class="row flex-wrap">
            <div class="col-xl-3 col-lg-4 col-md-3 col-sm-6 col-xs-12 info-block">
              <img src="../assets/images/footer-logo.svg">
            </div>
            <div class="col-xl-3 col-lg-4 col-md-3 col-sm-6 col-xs-12 info-block">
              <h5 class="footer-heading">Therapy App</h5>
              <ul class="footer-list">
                <li><a @click="redirectToSearchPage()" style="text-decoration: none;">Find a Therapist or Psychiatrist</a></li>
              </ul>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12 info-block">
              <h5 class="footer-heading">Support</h5>
              <ul class="footer-list">
                <li><a class="list-links" href="/frequently-asked-questions">Frequently Asked Questions</a></li>
                <li><a class="list-links" href="/privacy-policy">Privacy Policy</a></li>
                <li><a class="list-links" href="/terms-and-conditions">Terms & Conditions</a></li>
              </ul>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12 info-block">
              <h5 class="footer-heading">Download the App</h5>
              <div class="footer-images">
                <a href="https://apps.apple.com/us/app/therapy-app/id1589055854"><img src="../assets/images/app-store.svg" alt=""></a>
                <a href="https://play.google.com/store/apps/details?id=com.theprivatetherapy.app"><img src="../assets/images/google-play.svg" alt=""></a>
              </div>
              <div class="footer-text pt-1">
                Are you a Therapist? <a class="join-link" href="/therapist/sign-up">Join us</a>/<a class="join-link" href="/therapist/sign-in">Log in</a>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bottom py-2">
          <div class="footer-bottom-text">
            Copyright © 2024 site by Therapy App 
          </div>
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterComponent',
    methods: {
      redirectToSearchPage(){
        this.$router.push({name: 'found_therapists', query: { 
          therapistName: null, 
          selectedSessionType: "online", 
          selectedProviders: JSON.stringify([]),
          selectedTherapistGender: null,
          minPrice: 300,
          maxPrice: 1000,
          selectedInsuranceCompanies: JSON.stringify([]),
          selectedLanguages: JSON.stringify([]),
          instantBooking: true,
          selectedTherapyTypes: JSON.stringify([]),
          showIssuesDropdown: false,
          showInsuranceCompaniesDropdown: false,
          showLanguagesDropdown: false,
          showTherapyTypesDropdown: false,
          date: JSON.stringify(this.date),

          selectedIssues: JSON.stringify([])
        
        }})
      }
    }
  }
  </script>
  
  <style scoped>

  .footer {
    margin-top: auto;
  }

  *{
    color: #13204E;
  }
  .footer-heading {
    font-size: 14px;
    font-weight: 600;
  }
  .footer-top{
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .footer-list {
    list-style: none;
    padding: 0;
    margin-top: 14px;
    line-height: 22px;
    font-weight: 400;
    margin-bottom: 0px;
  }
  
  .footer-list li + li {
    margin-top: 6px;
  }
  
  .footer-images {
    margin-top: 14px;
  }
  
  .footer-text {
    padding-top: 6px;
    margin-top: 14px;
  }

  .footer-bottom{
    width: 100%;
    border-top: 1px solid #F3F3F6;
  }
  .footer-bottom-text{
    font-size: 12px;
    font-weight: 400;
  }

  .info-block {
    margin-bottom: 20px;
  }

  .join-link{
    color: #2F80ED;
    text-decoration: none;
  }

  .list-links{
    text-decoration: none;
  }
</style>