<template>
    <div class="container">
        <div class="breadcrumbs d-flex">
            <div class="breadcrumb-element d-flex"><img src="../assets/images/home-icon.svg" alt=""><a href="/">Home</a></div>
            <img src="../assets/images/arrow-right.svg" alt="">
            <div class="breadcrumb-element d-flex"> <a href="/find-therapist">Find a Therapist or Psychiatrist</a></div>
            <img src="../assets/images/arrow-right.svg" alt="">
            <div class="breadcrumb-element d-flex">Recommended therapy</div>
        </div>
        <section class="w-100 type-section d-flex flex-column align-items-center">
            <p class="text-center type-description">Based on your answers we recommend you the following therapy: </p>

            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-8">
                <div class="card">
                  <div class="card-header p-0" :style="{backgroundImage: 'url(' + image + ')', backgroundSize: 'cover'}">
                  </div>
                  <div class="card-body" style="z-index: 100;">
                    <h5 class="card-title">{{ typeName }}</h5>
                    <p class="card-text">{{ typeDescription }}</p>
                  </div>
                </div>
              </div>

            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-8 col-xs-8 d-flex flex-column align-items-center booking-button-wrapper">
                <button class="btn btn-primary" @click="redirectToSearchPage()">Find my Therapist</button>
            </div>
        </section>
    </div>
</template>

<script>
export default{
  data(){
    return {
      image: '',
      typeDescription: '',
      typeName: '',
      typeId: '',
      date: {
        value: null,
      }
    }
  },
  methods: {
    redirectToSearchPage(){
      this.$router.push({name: 'found_therapists', query: { 
        therapistName: null, 
        // selectedSessionType: "face_to_face", 
        selectedProviders: JSON.stringify([]),
        selectedTherapistGender: "it doesn't matter",
        // minPrice: 0,
        // maxPrice: 1800,
        selectedInsuranceCompanies: JSON.stringify([]),
        selectedLanguages: JSON.stringify([]),
        instantBooking: false,
        selectedTherapyTypes: JSON.stringify([{'therapy_type_id': this.typeId, 'therapy_type_name': this.typeName}]),
        showIssuesDropdown: false,
        showInsuranceCompaniesDropdown: false,
        showLanguagesDropdown: false,
        showTherapyTypesDropdown: false,
        // date: JSON.stringify(this.date),

        selectedIssues: JSON.stringify([])
      
      }})
    }
    
  },
  mounted() {
    this.image = this.$route.query.image;
    this.typeDescription = this.$route.query.typeDescription;
    this.typeName = this.$route.query.typeName;
    this.typeId = Number(this.$route.query.typeId);
    const startDate = new Date();
    const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
    this.date.value = [startDate, endDate];
  }
}
</script>

<style scoped>

  .breadcrumbs{
      padding: 18px 0px;
  }
  .breadcrumb-element{
      margin-left: 6px;
      margin-right: 6px;
      cursor: pointer;
  }
  .breadcrumb-element a{
      text-decoration: none;
      color: #13204E;
  }

  .breadcrumb-element img{
      margin-right: 6px;
  }
  .type-section{
      padding: 38px 0px 60px;
  }
  .booking-data{
      background: #fff;
      padding: 32px;
      border-radius: 16px;
      margin-bottom: 32px;
  }
  .btn-primary {
      padding: 8px 0;
      border-radius: 22px;
      background: linear-gradient(46deg, #43C9F3 0%, #3CAEE0 100%);
      color: #fff;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 85%;
      margin-top: 32px;
  }
  .btn-primary img {
      margin: 0px 12px;
  }
  .card{
    border-radius: 16px;
  }
  .card-header {
    height: 256px;
    border-radius: 16px 16px 0px 0px;
  }
  
  .card-body {
    padding: 40px;
  }
  
  .card-title {
    font-size: 40px;
    font-weight: 600;
  }
  
  .card-text {
    font-size: 16px;
    font-weight: 400;
    margin-top: 18px;
  }
</style>