<template>
  <section class="container find-therapist-section">
      <div class="w-100 row general-row-class">
        <div v-if="isLargeScreen" class="col-xl-4 col-lg-4 lg-filter">
          <SearchFiltersComponent :key="'main-filter'" @set-practitioners="handlePractitionersUpdate"></SearchFiltersComponent>
        </div>
        <div v-else class="col-xl-4 col-lg-4 lg-filter d-md-none d-sm-none d-xs-none d-lg-block">
          <SearchFiltersComponent v-if="!onceUsedForMobile" :key="'main-filter'" @set-practitioners.once="handlePractitionersUpdate"></SearchFiltersComponent>
        </div>

          <section class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 list-of-therapists">
              <h1 class="list_of_therapists_page_title">List of Therapist or Psychiatrist</h1>
              <p class="result-label">{{ practitioners.length }} results</p>
            <section class="therapist-data" v-for="practitioner in practitioners" :key="practitioner.practitioner_id">
              <div class="therapist-basic-description d-flex">
                  <img :src="practitioner.main_image" alt="Avatar">
                  <div class="text-part" style="margin-left: 22px;">
                      <p class="therapist-name">{{practitioner.full_name}}</p>
                      <p><span v-for="provider_type in practitioner.provider_types" :key="provider_type.index">{{ provider_type+' ' }}</span></p>
                      <p>{{ practitioner.experience }}</p>
                  </div>
              </div>
              <div class="w-100 row short-description-block">
                  <div class="practitioner-general-data d-flex">
                      <div class="therapist-data-short-description">
                          <span><img class="therapist-data-short-description-img" src="../assets/images/star-icon.svg" alt="">{{ practitioner.reviews_rating }}</span>
                      </div>
                      <div class="therapist-data-short-description">
                          <span><img class="therapist-data-short-description-img" src="../assets/images/comment-icon.svg" alt="">{{ practitioner.reviews_total }} reviews</span>
                      </div>
                      <div class="therapist-data-short-description" v-if="practitioner.min_fee != practitioner.max_fee">
                          <span><img class="therapist-data-short-description-img" src="../assets/images/tag-black.svg" alt="">£{{practitioner.min_fee}}-{{practitioner.max_fee}}</span>
                      </div>
                      <div class="therapist-data-short-description" v-else>
                          <span><img class="therapist-data-short-description-img" src="../assets/images/tag-black.svg" alt="">£{{practitioner.min_fee}}</span>
                      </div>
                  </div>
                  <div class="practitioner-general-data therapist-short-descripton-last-part">
                     <span class="insurance-status" v-if="practitioner.works_with_insurance">
                        <img src="../assets/images/health-protect-icon.svg" alt="">Therapist works with insurance<img src="../assets/images/info-icon.svg" alt="">
                     </span>
                  </div>
              </div>
              <div class="w-100 therapist-data-short-description-wrapper-bottom">
                  <div class="therapist-data-short-description">
                      <span v-for="address in practitioner.addresses" :key="address.index"><img class="therapist-data-short-description-img" src="../assets/images/geo-position.svg" alt="">{{address}}</span>
                  </div>
              </div>
              
              <!-- <hr style="margin-top: 24px; margin-bottom: 24px" v-if="practitioner.available_hours.length > 0">

              <div class="available-session-wrapper" v-if="practitioner.available_hours.length > 0">
                  <h5 class="availavble-session-title">Next available online sessions:</h5>
                  <div class="row chips-block">   
                      <div class="chip" :class="{active: selectedAvailableHour.time == availableHour && selectedAvailableHour.id == practitioner.practitioner_id}" v-for="availableHour in practitioner.available_hours" :key="availableHour.index">
                          <p @click="selectAvailableHour(availableHour, practitioner.practitioner_id)">Today {{availableHour}}</p>
                      </div>
                  </div>
              </div> -->

              <hr style="margin-top: 24px; margin-bottom: 24px">

              <div class="w-100 buttons-block">
                <button class="see-profile-button" @click="$router.push({name: 'recommended_therapist', query: {practitionerId: practitioner.practitioner_id}})">See profile</button>
                <!-- <button class="book-now-button" @click="$router.push(
                  {
                    name: 'book_therapist', 
                    query: {
                      practitionerId: practitioner.practitioner_id, 
                      sessionTime: selectedAvailableHour.time,
                    }
                  })">Book now</button> -->
                  <button class="book-now-button" @click="createChat(practitioner)">Chat now</button>
              </div>                  
              </section>
              <div class="mobile-filter d-md-block d-lg-none">
                <button class="filter-open-button" @click="showModal = true"><img src="../assets/images/filter-icon-white.svg" alt="">Filter results</button>
              </div>
          </section>
          <ModalComponent v-if="onceUsedForMobile" :key="'modal-filter'" :isOpen="showModal" :onceUsedForMobile="onceUsedForMobile" :queryValues="this.$route.query" @close="showModal = false" @set-practitioners="handlePractitionersUpdate"/>
      </div>
  </section>
</template>

<script>
  import connectyCube from "../config/connectycube-config";
  import 'vue-slider-component/theme/default.css'
  import '@vuepic/vue-datepicker/dist/main.css';
  import axios from '@/axios';
  import ModalComponent from './Components/FilterModalComponent.vue';
  import SearchFiltersComponent from './Components/SearchFiltersComponent.vue';
  // you need to import the CSS manually (in case you want to override it)
 

  export default{
      name: 'TherapistSearch',
      components: {
        ModalComponent,
        SearchFiltersComponent
      },
      data() {
        return {
          practitioners: [],
          showModal: false,
          isLargeScreen: true,
          onceUsedForMobile: false,
          selectedAvailableHour: {
            time: null,
            id: null
          }, 
          user: null,
        };
      },
      created() {
         this.checkScreenSize();
         window.addEventListener('resize', this.checkScreenSize);
      },
      async mounted(){
         await this.getMyUserData();
         await this.connectUser();
      },
      beforeUnmount() {
         window.removeEventListener('resize', this.checkScreenSize);
      },
      methods: {
          checkScreenSize() {
            // Например, не рендерить компонент если ширина экрана меньше 800px
            this.isLargeScreen = window.innerWidth > 992;
          },
          async handlePractitionersUpdate(practitioners){
            this.practitioners = practitioners;
            this.onceUsedForMobile = true;
          },
          selectAvailableHour(availableHour, id){
            this.selectedAvailableHour.time = availableHour;
            this.selectedAvailableHour.id = id;
          },
          connectUser() {
            if(this.$store.state.accessToken != null){
               connectyCube.createSession()
                  .then(() => {
                  return connectyCube.login({
                     id: this.user.cc_id,
                     password: this.user.cc_password,
                     login: this.user.cc_username
                  });
               }).catch((error) => {
                  console.error('Ошибка авторизации', error);
               });
            }
         },
         async createChat(therapistData){
            if(this.$store.state.accessToken != null){
               const params = {
                  type: 3,
                  occupants_ids: [therapistData.cc_id],
                  extensions: {
                     practitioner: JSON.stringify({
                     ...therapistData
                     }),
                     client: JSON.stringify({
                        full_name: this.user.first_name+" "+this.user.last_name,
                        main_image: this.user.profile_image ? this.user.profile_image.thumb_256 : null,
                     }),
                  }
               };

               connectyCube.chat.dialog
                  .create(params)
                  .catch((error) => {
                     console.log(error)
               });

               this.$router.push('/chats')
            }else{
               this.$router.push('/sign-in')
            }
         },
         async getMyUserData(){
            if(this.$store.state.accessToken != null){ 
               const response = await axios.get('client/profile/show ', {
                  headers: {
                     Authorization: `Bearer ${this.$store.state.accessToken}`
                  },
               });
               this.user = response.data;
            }
         },
      }
  }
</script>

<style scoped lang="scss" rel="stylesheet/scss">

.general-row-class{
   margin-left: 0px;
   margin-right: 0px;
}
.lg-filter{
   padding-right: 40px;
   padding-left: 0px;
}
.chip{
  margin-bottom: 5px;
  cursor: pointer;
  background: #fff;
}

.find-therapist-section{
    background-image: url('../assets/images/home-background.svg');
    background-repeat: no-repeat;
    background-position: 90%;
    background-position-y: bottom;
    padding: 42px 0px;
  }
.mobile-filter{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    border: 1px solid var(--main-colors-dark-10, #E6E8ED);
    background: var(--main-colors-white, #FFF);
    /* Shadow / Up */
    box-shadow: 0px -2px 4px 0px rgba(7, 20, 60, 0.05);
    height: 92px;
    padding: 24px 48px;
  }
  
  h5{
    margin-top: 40px;
  }

  h5:first-child{
    margin-top: 0px;
  }
.short-description-block{
    margin-left: 0px !important;
    margin-top: 24px;
 }

 .detailed-info-block > h4{
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #3CAEE0;
    margin-bottom: 18px;
 }

 .therapist-info{
    margin-bottom: 18px;
 }

 .therapist-info > p{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0px;
 }

 .therapist-info > a{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0px;
    color: #2F80ED;
 }

 .therapist-info > h6{
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
 }

 .therapist-info > h6 > img{
    margin-left: 4px;
 }

 .instagram-link{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px 12px 16px;
    border: 1px solid #DADCE4;
    border-radius: 12px;
    text-decoration: none;
    margin-top: 13px;
 }

 .instagram-link-with-logo-block{
    align-items: center;
 }

 .instagram-link-with-logo-block p{
    margin-bottom: 0px;
    font-size: 12px;
    line-height: 20px;
    margin-left: 8px;
 }

 .video-iframe{
    height: 384px;
    border-radius: 16px;
 }

 .availavble-session-title{
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
 }

 .dateText{
    font-size: 12px;
 }

 .therapist-data{
    height: fit-content;
 }

 .therapist-data-short-description-wrapper-bottom{
    margin-top: 4px;
 }

 .therapist-short-descripton-last-part{
   display: flex;
   justify-content: flex-end;
 }

 .therapist-short-descripton-last-part img:last-child{
    margin-left: 5px;
 }

 .therapist-short-descripton-last-part img:first-child{
    margin-right: 5px;
 }

 .short-description-block div{
    padding: 0px;
 }

 .practitioner-general-data{
   width: 50%;
 }

 .therapist-data-short-description span{
    display: flex;
    align-items: center;
    font-size: 14px;
 }

 .therapist-data-short-description {
    margin-right: 24px;
 }

 .therapist-data-short-description-img {
    width: 12px;
    margin-right: 3px;
 }

 .type-section{
    margin-left: 0px;
 } 

 .back_button{
    width: 20px;
    height: 20px;
    background: #fff;
    box-shadow: 0px 4px 8px 0px #3353C414;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
 }

 .breadcrumbs{
    padding: 18px 0px;
 }
 
 .breadcrumb-element{
    margin-left: 6px;
    margin-right: 6px;
    cursor: pointer;
 }
 .breadcrumb-element a{
    text-decoration: none;
    color: #13204E;
 }
 .therapist-data{
    background: #fff;
    padding: 32px;
    border-radius: 16px;
    margin-bottom: 32px;
 }

 .therapist-basic-description img{
    border-radius: 200px;
    width: 96px;
    height: 96px;
    object-fit: cover;
 }

 .list_of_therapists_page_title{
    font-size: 38px;
    font-weight: 600;
    margin-bottom: 12px;
 }
 .result-label{
    margin-bottom: 24px;
    line-height: 24px;
 }

 .see-profile-button{
    width: 48%;
    height: 44px;
    background: #fff;
    color: #43C9F3;
    font-size: 17px;
    padding: 8px 0;
    line-height: 28px;
    border: 1px solid #43C9F3;
    border-radius: 22px;
    cursor: pointer;
    margin-top: 16px;
    font-weight: 600;
 }

 .book-now-button{
    width: 48%;
    height: 44px;
    background: linear-gradient(90deg, #43C9F3 0%, #3CAEE0 100%);
    color: #fff;
    font-size: 17px;
    padding: 8px 0;
    line-height: 28px;
    border: 1px solid #43C9F3;
    border-radius: 22px;
    cursor: pointer;
    margin-top: 16px;
    font-weight: 600;
 }
 
 .filter-open-button{
    width: 100%;
    height: 44px;
    background: linear-gradient(90deg, #43C9F3 0%, #3CAEE0 100%);
    color: #fff;
    font-size: 17px;
    padding: 8px 0;
    line-height: 28px;
    border: 1px solid #43C9F3;
    border-radius: 22px;
    cursor: pointer;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
 }

 .therapist-name{
   font-size: 24px;
   line-height: 32px;
   font-weight: 600;
 }

 .insurance-status{
   font-size: 14px;
   line-height: 22px;
 }

 .buttons-block{
  margin-left: 0px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
 }

 @media (max-width: 1399px) { 
  .lg-filter{
    padding-right: 20px;
  }
 }

 @media (max-width: 570px) { 
   .see-profile-button{
      width: 100%;
      margin-top: 0px;
   }

   .book-now-button{
      width: 100%;
   }

   .practitioner-general-data{
      width: 100%;
   }

   .therapist-short-descripton-last-part{
      justify-content: flex-start;
   }

   .practitioner-general-data:first-child{
      justify-content: center;
      margin-bottom: 16px;
   }
 }
</style>