<template>
    <section class="container">
      <h1 class="text-center fs-1 fw-bold">Get psychological help within a few minutes</h1>
      <h3 class="text-center">Choose your problem and we will find you a relevant Therapist.</h3>
      <div class="d-flex w-100 justify-content-center align-items-center price-tag-wrapper">
        <p class="price-text">Fixed price: </p>
        <span class="d-flex"><img src="../../assets/images/tag.svg" alt="">£25</span>
      </div>
      <div class="w-100 d-flex row types-wrapper">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 type-wrapper" 
        v-for="type in helpTypes" :key="type.issue_id" 
        @click="$emit('changeComponent', 'HelpTypeComponent', type)">
            <div class="type">
                <h4 class="text-center fw-bold">
                    {{ type.issue_name }}
                </h4>
                <p class="text-center type-description">
                    {{ type.description }}
                </p>
            </div>
            
        </div>
    </div>
    </section>
  </template>
  
  <script>
  import axios from '@/axios';

  export default {
    data() {
      return {
        helpTypes: [
          // Add more types as needed
        ],
      };
    },
    mounted(){
      this.getIssues();
    },
    methods: {
        async getIssues(){
            try {
                await axios.get('issue/get-issues').then((response) => { // использование стрелочной функции здесь
                    response.data.forEach((issue) => {
                      this.helpTypes.push(issue)
                    })
                })
            } catch (error) {
                this.$router.push('/');
                console.error('Ошибка при получении данных от API:', error)
            }
        }
    }
  }
  </script>
  
  <style scoped>

  .button-wrapper{ 
    width: 100%;
    padding-top: 16px;
    font-size: 17px;
    font-weight: 600;
  }

  h1 {
    font-size: 38px;
    margin-bottom: 12px;
  }

  h3 {
    font-size: 16px;
    margin-bottom: 12px;
  }

  h4 {
    font-size: 20px;
    margin-bottom: 12px;
    line-height: 28px;
  }

  .price-text{
    margin-bottom: 0px;
    line-height: 24px;
    height: fit-content;
  }

  .type-description{
    margin-bottom: 0px;
    line-height: 24px;
  }
  
  .container {
    padding: 60px 0px 60px;
  }

  .type{
    background: #fff;
    padding: 16px;
    box-shadow: 0px 4px 8px 0px rgba(51, 83, 196, 0.08);
    border-radius: 16px;
    border: 1px solid #E6E8ED;
    margin-bottom: 24px;
    transition: 0.3s ease;
    cursor: pointer;
  }

  .type:hover{
    background: #F3F3F6;
    border-color: #43C9F3;
  }

 span {
    padding: 4px 12px;
    background: #3A466E;
    border-radius: 16px;
    color: #fff;
    font-size: 14px;
    line-height: 22px;
    align-items: center;
    width: fit-content;
    height: fit-content;
    margin-left: 12px;
  }

  span img {
    margin-right: 4px;
  }

  .price-tag-wrapper{ 
    padding-top: 12px;
  }

  .types-wrapper{
    margin-top: 32px;
  }

  </style>
  