<template>
      <div class="w-100">
        <div class="container-fluid home-option-wrapper">
          <section class="home-option-section py-5 w-100">
            <div class="row home-block-wrapper">
              <div class="find-therapist-card">
                <div class="home-block">
                  <h1 class="home-heading mb-3">Find a Therapist or Psychiatrist  to book a session</h1>
                  <div>
                    <p class="home-text mb-3">Do you know what type of Psychological Practitioner or Therapy approach you are interested in? </p>
                    <div class="toggle-switch-wrapper">
                      <div class="toggle-switch mb-3">
                        <div class="toggle-option" :class="{ active: selectedOption === 'need' }" @click="selectedOption = 'need'">
                          <span>I need assistance</span>
                        </div>
                        <div class="toggle-option" :class="{ active: selectedOption === 'know' }" @click="selectedOption = 'know'">
                          <span>I know</span>
                        </div>
                        <div class="toggle-slider" :class="{ 'know': selectedOption === 'know' }"></div>
                      </div>
                    </div>
                  </div>
                  <a @click="$router.push({ name: 'therapist_search', query: {filterType: this.selectedOption}})">
                    <button class="next-step-button-for-togglers">Next step</button>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="container-fluid overflow-hidden">
          <!-- <div v-if="$store.state.therapist.isLoggedIn || $store.state.user.isLoggedIn && sessions.length > 0">
            <h1 class="fw-bold" style="margin-left: 24px; margin-bottom: 18px; margin-top: 24px">Next Session</h1>
            <div v-for="session in sessions" :key="session.id" class="w-100 session-block">
              <div class="w-50">
                <div class="session-info w-100">
                  <div class="therapist-block d-flex align-items-center">
                    <div class="">
                        <img src="../assets/images/therapist-sample.jpg" alt="Psychotherapist" class="img-fluid rounded-circle therapist_image">
                    </div>
                    <div class="therapist-info" style="margin-bottom: 0px">
                      <div class="d-flex align-items-center therapist_name">
                        {{ session.practitioner.first_name }} {{ session.practitioner.last_name}}
                      </div>
                      <div class="d-flex align-items-center therapist_provider_type">
                        <span v-for="provider_type in session.practitioner.provider_types" :key="provider_type.index">{{ provider_type }}</span>
                      </div>
                    </div>
                  </div>
                      
                  <div class="session-details d-flex">
                    <div class="d-flex align-items-center session-datetime">
                      <div class="datetime-element">
                        <img src="../assets/images/calender.svg" alt=""> 
                        <p class="session_datetime"> {{ session.date }}</p>
                      </div>
                      <div class="datetime-element">
                        <img src="../assets/images/clock.svg" alt="">
                        <p class="session_datetime"> {{ session.time }}</p>
                      </div>
                      </div>
                      <div class="devider"></div>
                      <div class="d-flex">
                        <div class="d-flex align-items-center session-type">
                          <img src="../assets/images/online-icon.svg" alt="">
                          <p>{{ session.booking_type }}</p>
                        </div>
                        <div class="d-flex align-items-center session-type">
                          <img src="../assets/images/individual-therapy.svg" alt="">
                          <p v-for="(service, index) in session.services" :key="index">{{service.service_name}}</p>
                        </div>
                      </div>    
                    </div>
                  </div>
              </div>

              <div class="session-actions">
                  <div class="d-flex align-items-center">
                    <button class="edit-button" @click="openModal(session)" style="margin-right: 24px">Edit</button>
                  </div>
                  <div class="d-flex align-items-center">
                    <img class="close-img" style="width: 16px; height: 16px" src="../assets/images/close-icon.svg" @click="deleteSession(session.appointment_id)" alt="">
                  </div>
                </div>
              </div>

            <div class="w-100 d-flex justify-content-between" style="padding: 12px 24px 12px 24px; margin-top: 18px;">
              <div class="d-flex align-items-center">
                <img src="../assets/images/info-icon.svg" alt="">
                <p style='margin-bottom: 0px; margin-left: 4px'>You have {{ sessions.length }} scheduled upcoming sessions</p>
              </div>

              <a style="text-decoration: none; color: #00809D; font-size: 12px" class="fw-bold" href="/my-sessions">See all my sessions</a>
            </div>
          </div> -->

          <section class="home-section overflow-visible py-5">
            <h2 class="section-heading text-center mb-1">Connecting you with help for your mental health</h2>
            <p class="text-center mb-5">The most ethical platform for therapists. Your practitioner keeps 100% of the fees you pay to see them.</p>
            <div class="row flex-nowrap overflow-auto hide-scrollbar">
              <div class="col-12">
                <div class="full-width-section h-auto dashboard" style="border-radius: 24px;">
                  <div class="dashboard-image-part">
                    <img src="../assets/images/card_3.jpg" alt="" class="w-100 h-100 main-dashboard-image">
                  </div>
                  <div class="dashboard-text-part">
                    <h1 class="dashboard-title fw-bold">Easily book online or in-person sessions</h1>
                    <p class="dashboard-text">We check Therapists' and Psychiatrist's qualifications, ensure they are subscribed to a professional code of ethics and that they are insured to carry out private work.</p>
                    <button class="find-out-button" style="margin-top: 18px" @click="redirectToSearchPage()">Book online</button>
                  </div>
                </div>
              </div>
              <!-- Repeat the above div for the other two cards -->
            </div>
          </section>
          <section class="home-section therapists-section py-5">
            <div class="meet-therapists">
              <div class="meet-therapists-content">
                <div class="meet-therapists-content-text-wrapper">
                  <h2 class="dashboard-heading-text">Meet our therapists and Psychiatrists</h2>
                  <p class="dashboard-heading-content-text">All of our Therapists and Psychiatrists undergo a rigorous vetting process. We only work with practitioners who are committed to upholding a strict code of ethics set by a recognised professional body.</p>
                </div>
                <button class="find-out-button" @click="redirectToSearchPage()">Find out more</button>
              </div>
            </div>
          </section>
          <section class="home-section therapy-app-section">
              <div class="therapy-app-image-preview-block">
                <img src="../assets/images/therapy-app-preivew.svg" alt="">
              </div>
              <div class="therapy-app-image-preview-mobile-block">
                <img src="../assets/images/therapy-app-preview-mobile.svg" alt="">
              </div>
              <div class="therapy-app-description-text-block d-flex flex-column justify-content-center">
                <h3 class="fw-bold therapy-description-heading">Therapy App</h3>
                <p class="therapy-description-subheading">By Private Therapy Clinic</p>
                <p class="therapy-description-text">All fees, straight to your Practitioner!</p>
                <button class="find-out-button" style="width: 185px" @click="redirectToSearchPage()">Find a Practitioner</button>
              </div>
          </section>
        </div>
        <!-- <ModalComponent v-if="showModal" :isOpen="showModal" :session="currentEditableSession" @close="showModal = false" /> -->
    </div>      
  </template>
  
  <script>
  // import ModalComponent from './Components/SessionModalComponent.vue';
  import axios from '@/axios';

  export default {
    name: 'HomePage',
    // components: {
    //   ModalComponent
    // },
    data() {
        return {
          selectedOption: 'know',
          isUserLoggedIn: false, // Default selected option
          // currentEditableSession: null,
          showModal: false,
          sessions: [],
          date: {
            value: null
          }
        };
    },
    methods: {
      // openModal(session){
      //   this.currentEditableSession = session;
      //   this.showModal = true;
      // },
      async getProfileData(){
        if(this.$store.state.user.firstName != null){
          await axios.get('/client/profile/', {
              headers: {
                  Authorization: `Bearer ${this.$store.state.accessToken}`
              }
          });
        }
      },
      redirectToSearchPage(){
        this.$router.push({name: 'found_therapists', query: { 
          therapistName: null, 
          selectedSessionType: "online", 
          selectedProviders: JSON.stringify([]),
          selectedTherapistGender: null,
          minPrice: 300,
          maxPrice: 1000,
          selectedInsuranceCompanies: JSON.stringify([]),
          selectedLanguages: JSON.stringify([]),
          instantBooking: true,
          selectedTherapyTypes: JSON.stringify([]),
          showIssuesDropdown: false,
          showInsuranceCompaniesDropdown: false,
          showLanguagesDropdown: false,
          showTherapyTypesDropdown: false,
          date: JSON.stringify(this.date),

          selectedIssues: JSON.stringify([])
        
        }})
      }
    },
   async mounted(){
      this.isUserLoggedIn = this.$store.state.user.isLoggedIn;
      if(this.isUserLoggedIn){
        await this.getProfileData();
      }
      const startDate = new Date();
      const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
      this.date.value = [startDate, endDate];
    }
  }
  </script>
  
  <style scoped>
  .therapy-app-image-preview-block{
    margin-left: 140.5px;
  }

  .therapy-app-description-text-block{
    margin-left: 56px;
  }

  .therapy-app-image-preview-mobile-block{
    display: none;
  }

  .therapists-section{
    padding-top: 20px !important;
  }

  .therapy-description-heading{
    font-size: 48px;
    margin-bottom: 8px;
  }

  .therapy-description-subheading{
    font-weight: 600;
    font-size: 24px;
    color: #00D2FF;
    margin-bottom: 18px;
  }

  .therapy-description-text{
    font-size: 32px;
    font-style: italic;
    margin-bottom: 24px;
  }

  .therapy-app-section{
    padding-left: 48px;
    padding-right: 48px;
    padding-bottom: 80px;
    display: flex;
  }

  .meet-therapists{
    background-image: url('../assets/images/Card.jpg');
    background-size: cover;
    background-position-x: left;
    background-position-y: top;
    background-repeat: no-repeat;
    border-radius: 16px;
  }

  .meet-therapists-content{
    padding: 72px;
    width: 100%;
    height: 100%;
  }

  .meet-therapists-content-text-wrapper{
    margin-bottom: 32px;
    width: 65%
  }

  .home-option-section{
    padding-bottom: 80px !important;
  }

  .dashboard-text-part{
    width: 50%;
    height: 100%;
    padding: 40px
  }

  .dashboard-image-part{
    width: 50%;
    border-radius: 24px 0px 0px 24px;
    overflow: hidden;
  }

  .dashboard{
    display: flex;
  }

  .main-dashboard-image{
    object-fit: cover; 
    object-position: center;
  }
  
  .home-option-wrapper{
    background-image: url('../assets/images/Home-Banner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .home-block {
    background-color: #fff;
    padding: 48px;
    border-radius: 16px;
  }
  
  .home-heading {
    font-size: 46px;
    font-weight: 600;
  }
  
  .home-text {
    font-size: 16px;
    font-weight: 600;
  }

  .toggle-switch {
    position: relative;
    width: 370px;
    height: 38px;
    background-color: #F3F3F6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    border-radius: 15px;
  }
  
  .toggle-option {
    cursor: pointer;
    z-index: 2;
    width:50%;
    text-align: center;
    transition: all 0.3s ease;
    font-size: 16px;
    font-weight: 700;
  }
  
  .toggle-option.active span {
    font-weight: bold;
  }
  
  .dashboard-heading-content-text {
    width: 72%;
    font-weight: 400;
  }

  .toggle-slider {
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(50% - 2px);
    height: calc(100% - 4px);
    background-color: #fff;
    border-radius: 15px;
    transition: all 0.3s ease;
    z-index: 1;
    box-shadow: 0px 3px 8px 0px #182D420D;
  }
  
  .toggle-slider.know {
    transform: translateX(calc(100%));
  }
  
  .toggle-switch-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .section-heading {
    font-size: 38px;
    font-weight: 600;
  }
  
  .card {
    border: none;
    border-radius: 16px;
    flex-shrink: 0; /* Prevent cards from shrinking */
  }
  
  .card-header {
    height: 192px;
    border-radius: 16px 16px 0 0 !important;
  }
  
  .card-body {
    padding: 32px;
    border-radius: 0 0 16px 16px;
  }
  
  .card-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 18px;
  }
  
  .card-text {
    font-size: 16px;
    font-weight: 400;
    height: 68px;
    margin-bottom: 18px;
  }
  
  .card-link {
    border: 2px solid #000;
    padding: 8px 14px;
    font-size: 12px;
    font-weight: 700;
    color: #000;
    text-decoration: none;
    margin-top: 22px;
    border-radius: 50px;
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .cards-wrapper{
    justify-content: space-around;
  }

  .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .find-therapist-card{
    width: 706px;
    margin-left: 146px;
  }

  @media (max-width: 1280px) { 
    .therapy-app-image-preview-block{
      margin-left: 48px;
    }

    .therapy-app-description-text-block{
      margin-left: 40px;
    }

    .card-title{
      min-height: 56px;
    }

    .meet-therapists-content-text-wrapper{
      width: 70%
    }

    .meet-therapists-content{
      background-position: 98%;
      background-position-y: 100%;
      background-size: 26%;
    }

    .find-therapist-card{
      width: 506px;
      margin-left: 0px;
    }

    .home-option-section{
      background-position: 91%;
      background-size: 35%;
      background-position-y: bottom;
    }

    .home-heading{
      font-size: 28px;
    }

  }

  @media (max-width: 768px) { 

    .find-therapist-card{
      width: 556px;
      margin-left: 0px;
    }

    .therapy-app-section{
      flex-direction: column;
    }

    .therapy-app-image-preview-block{
      margin-left: 0px;
    }

    .therapy-app-description-text-block{
      margin-left: 0px;
      width: 100%;
      align-items: center;
      margin-top: 24px;
    }

    .dashboard{
      flex-direction: column;
    }

    .dashboard-text-part{
      width: 100%;
      height: 100%;
      padding: 40px
    }

    .dashboard-image-part{
      width: 100%;
      border-radius: 24px 24px 0px 0px;
      overflow: hidden;
    }

    .card-text{
      margin-bottom: 80px !important;
    }

    .card-title{
      min-height: 85px;
    }

    .home-block-wrapper{
      justify-content: center !important;
    }

    .cards-wrapper{
      justify-content: flex-start;
    }

    .home-heading{
      font-size: 28px;
    }

    .meet-therapists-content-text-wrapper{
      width: 100%
    }

  }

  @media (max-width: 500px) { 

    /* .home-option-wrapper{
      padding-left: 12px !important;
      padding-right: 12px !important;
      margin-left: 0px;
    } */

    .therapy-app-section{
      padding-left: 0px;
      padding-right: 0px;
    }

    .therapy-app-image-preview-mobile-block{
      display: flex;
      justify-content: center;
    }

    .therapy-app-image-preview-block{
      display: none;
    }

    .home-block{
      padding: 24px;
    }

    .dashboard-title {
      font-size: 24px
    }

    .dashboard-text {
      font-size: 16px
    }

    .meet-therapists-content{
      padding: 32px;
    }
}

  
  </style>