<template>
    <div class="container">

        <h1 class="text-center" style="font-weight: 600; margin-top: 30px; margin-bottom: 30px">View your therapist profile</h1>

        <section class="w-100 type-section row d-flex justify-content-center">
            <section class="col-lg-8 col-md-12 therapist-data" v-show="practitioner != {}">
                <div class="therapist-basic-description d-flex">
                    <img :src="profileImage" alt="" class="profile-image">
                    <div class="text-part">
                        <h4 class="therapist-name">{{practitioner.full_name}}</h4>
                        <p><span v-for="provider_type in practitioner.provider_types" :key="provider_type.index">{{ provider_type }}</span></p>
                        <p v-if="practitioner.experience">{{practitioner.experience}} years of experience</p>
                    </div>
                </div>
                <div class="w-100 row short-description-block">
                    <div class="w-50 d-flex">
                        <div class="therapist-data-short-description">
                            <span><img class="therapist-data-short-description-img" src="../assets/images/star-icon.svg" alt="">{{ practitioner.reviews_rating }}</span>
                        </div>
                        <div class="therapist-data-short-description">
                            <span><img class="therapist-data-short-description-img" src="../assets/images/comment-icon.svg" alt="">{{ practitioner.reviews_total }}</span>
                        </div>
                        <div class="therapist-data-short-description" v-if="practitioner.min_fee != practitioner.max_fee">
                            <span><img class="therapist-data-short-description-img" src="../assets/images/tag-black.svg" alt="">£{{practitioner.min_fee}}-{{practitioner.max_fee}}</span>
                        </div>
                        <div class="therapist-data-short-description" v-else>
                            <span><img class="therapist-data-short-description-img" src="../assets/images/tag-black.svg" alt="">£{{practitioner.min_fee}}</span>
                        </div>
                    </div>
                    <div class="w-50 d-flex justify-content-end therapist-short-descripton-last-part">
                        <span v-if="practitioner.works_with_insurance">
                            <img src="../assets/images/health-protect-icon.svg" alt="">Therapist works with insurance<img src="../assets/images/info-icon.svg" alt="">
                        </span>
                    </div>
                </div>
                <div class="w-100 therapist-data-short-description-wrapper-bottom">
                    <div class="therapist-data-short-description">
                        <span v-for="address in practitioner.addresses" :key="address.index"><img class="therapist-data-short-description-img" src="../assets/images/geo-position.svg" alt="">{{address}}</span>
                    </div>
                </div>
                
                <hr style="margin-top: 24px; margin-bottom: 24px">

                <iframe v-if="practitioner.profile_video_link" :src="convertYouTubeURL(practitioner.profile_video_link)" frameborder="0" class="w-100 video-iframe"></iframe>
                
                <hr style="margin-bottom: 24px; margin-top: 30x">

                <div class="detailed-info-block">
                    <h4>Experience</h4>

                    <div class="therapist-info">
                        <h6>Accreditation / Licence</h6>
                        <div>
                            <p>{{ practitioner.licence }}</p>
                            <p>License/registration No. {{practitioner.licence_number}}</p>
                        </div>
                    </div>
                    <!-- <div class="therapist-info">
                        <h6>Qualifications / Experience</h6>
                        <p>Counselling Psychologist in Training</p>
                    </div> -->
                    <div class="therapist-info">
                        <h6>About</h6>
                        <ShowMoreComponent v-if="practitioner.about" :fullText="practitioner.about" />
                    </div>
                    <div class="therapist-info" v-if="practitioner.insurance_certificate">
                        <h6>Insurance Certificate <img src="../assets/images/info-icon.svg" alt=""></h6>
                        <p>Expired on 31/03/2020</p>
                        <a href="">View Insurance certificate</a>
                    </div>
                    <div class="therapist-info">
                        <h6>Languages:</h6>
                        <div class="row chips-block" style="margin-top: 8px"> 
                            <div class="chip language-chip" v-for="language in practitioner.languages_list" :key="language.index">
                                <p>{{language}}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <hr style="margin-bottom: 24px; margin-top: 24x">

                <div class="detailed-info-block">
                    <h4>Approaches</h4>
                    <div class="therapist-info">
                        <h6 v-for="therapy_approach in practitioner.therapist_approaches" :key="therapy_approach.index">{{therapy_approach}}</h6>
                    </div>
                </div>
            </section>
            
            <div class="d-flex justify-content-center">
                <button class="next-step-button" @click="this.$router.push('/therapist/personal-info?isEditing=true');" style="width: 311px; margin-bottom: 48px">Edit profile</button>
            </div>
        </section>
    </div>
</template>

<script>
    import 'vue-slider-component/theme/default.css'
    import '@vuepic/vue-datepicker/dist/main.css'
    import axios from '@/axios';
    import ShowMoreComponent from './Components/ShowMoreComponent.vue';
   

    export default{
        name: 'TherapistSearch',
        components: {
            ShowMoreComponent,
        },
        data() {
            return {
                selectedOption: 'online', // Default selected option
                startDate: this.getMonday(new Date()),
                selectedDate: "",
                selectedTime: "",
                days: [],
                isDragging: false,
                startX: 0,
                lastScrollLeft: 0,
                practitionerId: null,
                practitioner: {},
                profileImage: '',
                therapistServices: {},
                service: null,
                customerId: null,
                showModal: false
            };
        },
        methods: {
            getMonday(d) {
                d = new Date(d);
                let day = d.getDay();
                let diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is Sunday
                return new Date(d.setDate(diff));
            },
            selectDate(date) {
                this.selectedDate = date;
            },
            moveSlider(direction) {
                this.startDate.setDate(this.startDate.getDate() + direction);
                this.generateDays();
            },
            handleMouseDown(event) {
                event.preventDefault();
                this.isDragging = true;
                this.startX = event.clientX;
                this.lastScrollLeft = this.$refs.timeBlockContainer.scrollLeft;
            },
            handleMouseUp() {
                this.isDragging = false;
            },
            handleMouseMove(event) {
                if (!this.isDragging) return;
                const dx = this.startX - event.clientX;
                this.$refs.timeBlockContainer.scrollLeft = this.lastScrollLeft + dx;
            },
            selectTime(time){
                this.selectedTime = time;
            },
            async getProfileData(id){
                const response = await axios.get('/practitioner/'+id+'/profile');
                return response.data;
            },
            async getPractitionerAvailableHours(id){
                const response = await axios.get('/practitioner/'+id+'/next-available-sessions ');

                const currentDate = new Date();

                // 2. Извлекаем день и месяц из текущей даты
                const currentDay = currentDate.getDate();
                const currentMonth = currentDate.getMonth() + 1; // Месяцы в JavaScript начинаются с 0

                // 3. Фильтруем массив дат
                const filteredDates = response.data.free_datetime.filter(dateStr => {
                    const dateParts = dateStr.split(" ")[0].split("-"); // Разбиваем строку даты на части
                    const day = parseInt(dateParts[2], 10);
                    const month = parseInt(dateParts[1], 10);

                    return day === currentDay && month === currentMonth;
                });

                const availableTodayHours = filteredDates.map((date) => {
                    let timeParts = date.split(" ")[1].split(":");
                    timeParts.pop();

                    return timeParts.join(':');
                })

                return availableTodayHours; 
            },
            async getPractitionerServices(id){
                const response = await axios.get('/practitioner/'+id+'/service-types ');

                this.therapistServices = response.data;
            },
            convertYouTubeURL(url) {
                const match = url.match(/watch\?v=([a-zA-Z0-9_-]+)/);
                if (match && match[1]) {
                    return `https://www.youtube.com/embed/${match[1]}`;
                }
                return null; // или вернуть исходный URL, если не соответствует ожидаемому формату
            },
            async bookSession(){
                
            },
            createDateTime() {
                // Предполагается, что dateStr имеет формат 'Tue, Oct 31', а timeStr - '12:00'
                const dateParts = this.selectedDate.split(', ')[1].split(' '); // Разбиваем строку на части
                const timeParts = this.selectedTime.split(':'); // Разбиваем время на часы и минуты

                // Создаем новую дату для текущего года
                const dateTime = new Date(new Date().getFullYear(), this.months[dateParts[0]], dateParts[1], timeParts[0], timeParts[1]);
                
                return dateTime;
            },
        },
        async mounted() {
            this.practitioner = await this.getProfileData(this.$store.state.therapist.id);
            this.practitioner.availableTodayHours = await this.getPractitionerAvailableHours(this.practitioner.id);
            this.profileImage = this.practitioner.main_image;
            this.getPractitionerAvailableHours(this.practitioner.practitioner_id);
            this.getPractitionerServices(this.practitioner.practitioner_id);
        },
    }
</script>

<style scoped>
    .steps {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 371px;
    }

    .steps img {
        width: 6px;
        height: 6px;
    }

    .step {
        display: flex;
        align-items: center;
    }

    .step span {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        font-size: 16px;
        font-weight: 600;
        background-color: transparent;
        color: var(--font-dark-80, #3A466E);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #E6E8ED;
    }

    .back-arrow-block{
        background: #F3F3F6;
        border: 1px solid #E6E8ED;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
    }

    .progress-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100% - 64px);
    }

    .progress-bar {
        height: 100%;
        background-color: #43C9F3;
        border-radius: 5px 0 0 5px; /* Rounded corners on the left side */
    }

    .progress-bar-wrapper {
        height: 4px;
        background-color: #E6E8ED;
        border-radius: 5px 0 0 5px; /* Rounded corners on the left side */
        width: 127px;
    }

    .progress-content-block > span {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; 
        margin-bottom: 4px;
    }

   .content-block-wrapper{
    padding: 18px 24px 18px 24px;
    background: #fff;
    border-radius: 16px;
    margin-bottom: 32px;
    border: 1px solid #E6E8ED;
    margin-top: 24px;
  }

  .dropdown-item{
    cursor: pointer;
  }

  .dropdown{
    position: absolute;
    z-index: 999;
    background: #fff;
    width: 100%;
    left: -2%;
    padding: 15px;
    border: 2px solid #F3F3F6;
    border-radius: 8px;
    height: 200px;
    overflow-y: scroll;
  }
  .dropdown-arrow{
    width: 18px;
    height: 18px;
    cursor: pointer;
  }


 .sidebar-exeternal-session{
    background: #fff;
    padding: 24px;
    width: 100%;
    border-radius: 16px;
 }

 .short-description-block{
    margin-left: 0px !important;
    margin-top: 24px;
 }

 .detailed-info-block > h4{
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #3CAEE0;
    margin-bottom: 18px;
 }

 .therapist-info{
    margin-bottom: 18px;
 }

 .therapist-info > p{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0px;
 }

 .therapist-info > div > p{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0px;
 }

 .therapist-info > a{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0px;
    color: #2F80ED;
 }

 .therapist-info > h6{
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
 }

 .therapist-info > h6 > img{
    margin-left: 4px;
 }

 .instagram-link{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px 12px 16px;
    border: 1px solid #DADCE4;
    border-radius: 12px;
    text-decoration: none;
    margin-top: 13px;
 }

 .instagram-link-with-logo-block{
    align-items: center;
 }

 .instagram-link-with-logo-block p{
    margin-bottom: 0px;
    font-size: 12px;
    line-height: 20px;
    margin-left: 8px;
 }

 .video-iframe{
    height: 384px;
    border-radius: 16px;
 }

 .availavble-session-title{
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
 }

 .dateText{
    font-size: 12px;
 }

 .therapist-data-short-description-wrapper-bottom{
    margin-top: 4px;
 }

 .therapist-short-descripton-last-part img:last-child{
    margin-left: 5px;
 }

 .therapist-short-descripton-last-part img:first-child{
    margin-right: 5px;
 }

 .short-description-block div{
    padding: 0px;
 }

 .therapist-data-short-description span{
    display: flex;
    align-items: center;
    font-size: 14px;
 }

 .therapist-data-short-description {
    margin-right: 24px;
 }

 .therapist-data-short-description-img {
    width: 12px;
    margin-right: 3px;
 }

 .type-section{
    margin-left: 0px;
 } 

 .back_button{
    width: 20px;
    height: 20px;
    background: #fff;
    box-shadow: 0px 4px 8px 0px #3353C414;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
 }

 .breadcrumbs{
    padding: 18px 0px;
 }
 
 .breadcrumb-element{
    margin-left: 6px;
    margin-right: 6px;
    cursor: pointer;
 }
 .breadcrumb-element a{
    text-decoration: none;
    color: #13204E;
 }
 .therapist-data{
    background: #fff;
    padding: 32px;
    border-radius: 16px;
    margin-bottom: 32px;
 }
.therapist-basic-description{
    width: 100%;
    display: flex;
    align-items: center;
 }
.therapist-basic-description img{
    width: 136px;
    height: 136px;
 }
.therapist-name{
    font-size: 38px;
    font-family: Libre Franklin;
    font-weight: 600;
    line-height: 46px;
    margin-bottom: 0px;
 }
.text-part{
    margin-left: 24px;
 }
.text-part > p {
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 22px;
 }

.therapist-sidebar{
    padding-left: 24px;
    padding-right: 0px;
 }

.chip{
    padding: 4px 12px 4px 12px;
    display: flex;
    width: fit-content;
    align-items: center;
    font-size: 12px;
    height: 28px;
    border-radius: 16px;
    margin-right: 8px;
    background: #F3F3F6;
 }

.chip.active {
    background: #43C9F3;
 }

.chip p{
    margin-bottom: 0px;
 }

.chip.active p {
    color: #fff;
 }

.chips-block{
    margin-left: 0px;
 }

.date-options-title{
    font-size: 12px;
    margin-top: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
 }

.language-chip{
    background: #9DA3B7;
 }

.language-chip p {
    color: #fff;
 }

.toggle-slider.face_to_face {
    transform: translateX(calc(100%));
 }
  
  .toggle-switch-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
 }

  .type-description{
    margin-bottom: 32px;
 }

  .toggle-switch {
    position: relative;
    width: 370px;
    height: 38px;
    background-color: #F3F3F6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    border-radius: 15px;
    border: 1px solid var(#E6E8ED);
 }

  .toggle-switch-internal {
    position: relative;
    width: 100%;
    height: 38px;
    background-color: #F3F3F6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    border-radius: 15px;
    border: 1px solid var(#E6E8ED);
 }

  .toggle-option {
    cursor: pointer;
    z-index: 2;
    width:50%;
    text-align: center;
    transition: all 0.3s ease;
 }
  
  .toggle-option.active span {
    font-weight: bold;
 }

  .toggle-slider {
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(50% - 2px);
    height: calc(100% - 4px);
    background-color: #fff;
    border-radius: 15px;
    transition: all 0.3s ease;
    z-index: 1;
    box-shadow: 0px 3px 8px 0px #182D420D;
 }
  
  .toggle-slider.face_to_face {
    transform: translateX(calc(100%));
 }

  .sidebar_title{
    font-size: 24px;
    font-weight: bold;
 }

  .sidebar_sub_title{ 
    font-size: 16px;
    font-weight: bold;
 }

  .service-selection{
    background: #F3F3F6;
    padding: 8px 12px 8px 12px;
    min-height: 44px;
    border-radius: 8px;
    border: 1px solid#E6E8ED;
 }

  .additional-meeting-data{
    margin-top: 15px;
 }

  .day {
    padding: 10px;
    margin: 5px;
    border: 1px solid black;
 }
  
  .selected {
    background: linear-gradient(73.96deg, #43C9F3 10.17%, #3CAEE0 91.5%) !important;
    color: #fff !important;
 }

 .day_of_the_week{
    width: 36px;
    height: 36px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border-radius: 20px;
    background: #F3F3F6;
    color: #6B7492;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
 }

 .day_of_the_week_block{
    width: 39px;
    padding: 0px;
    margin-left: 4px;
    margin-right: 6px;
 }

 .calender-switch-button{
    width: 6px;
    padding: 0px;
    background: transparent;
    border: none;
    margin-left: 1px;
    margin-right: 0px;
}

 .calender_block{
    padding: 24px;
 }

 .calender-row{
    margin-left: 0px;
    margin-right: 0px;
 }

 .days-row{
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px;
    display: flex;
    width: fit-content;
 }

 #timeBlockContainer {
    display: flex;
    width: 100%;
    overflow: hidden;
    cursor: grab;
    white-space: nowrap;
    user-select: none;
    height: 28px;
    margin-top: 16px;
  }
  
  #timeBlockContainer:active {
    cursor: grabbing;
  }
  
  .timeBlock {
    width: 57px;
    height: 28px;
    padding: 4px 12px 4px 12px;
    border-radius: 15px;
    background: #F3F3F6;
    gap: 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: #6B7492;
    margin-right: 8px;
  }
  
  .profile-image{
    border-radius: 100px;
    border: 1px solid #E6E8ED;
  }

  .mobile-filter{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    border: 1px solid var(--main-colors-dark-10, #E6E8ED);
    background: var(--main-colors-white, #FFF);
    box-shadow: 0px -2px 4px 0px rgba(7, 20, 60, 0.05);
    height: 92px;
    padding: 24px 48px;
  }

  .filter-open-button{
    width: 100%;
    height: 44px;
    background: linear-gradient(90deg, #43C9F3 0%, #3CAEE0 100%);
    color: #fff;
    font-size: 17px;
    padding: 8px 0;
    line-height: 28px;
    border: 1px solid #43C9F3;
    border-radius: 22px;
    cursor: pointer;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
 }

 @media (max-width: 786px) { 
  .therapist-sidebar{
    padding-left: 0px;
  }
}
</style>