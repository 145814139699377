<template>
    <section class="container sign-container">
        <img src="../assets/images/referal-link.svg" alt="">
        <h1 class="text-center fs-1 fw-bold">Referral code</h1>
        <p class="text-center already-signed-text">Enter a referral code to get a 20% discount on your first session</p>

        <div class="col-3 create-account-fields-wrapper">
           <div class="sign-field-block w-100">
                <h6>Referal code</h6>
                <input type="text" class="w-100 sign-field" v-model="referralCode" placeholder="Enter your code">
           </div>

            <div class="w-100 sign-button-block">
                <button class="next-step-button" @click="referralCodeRequestSend()">Confirm referral code</button>
            </div>

            <div class="w-100 sign-button-block">
                <button class="next-step-button with-white-bg">Skip</button>
            </div>
            
        </div>
     </section>
</template>

<script>
import { inject } from 'vue'
import axios from 'axios'

export default{
    data(){
        return{
            referralCode: null
        }
    },
    setup() {
        const apiUrlStart = inject('apiUrlStart') // используем предоставленный apiURL
        return { apiUrlStart }
    },
    mounted(){
    },
    methods: {
        async referralCodeRequestSend(){
            try {
                await axios.post(this.apiUrlStart + '/client/check-referral-code', {
                    referral_code: this.referralCode,
                }).then((response) => { // использование стрелочной функции здесь
                    
                    console.log(response);
                })
            } catch (error) {
                this.$router.push('/');
                console.error('Ошибка при получении данных от API:', error)
            }
        }
    }
}
</script>

<style scoped>

    .sign-field{
        background: #F3F3F6;
        height: 44px;
        border: 1px solid #E6E8ED;
        border-radius: 8px;
        padding: 10px 12px 10px 12px;
        line-height: 24px;
    }

    h6{
        font-weight: 600;
    }

    .already-signed-text{
        margin-bottom: 0px;
    }

    .privacy-text{
        font-size: 14px;
    }

    .sign-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 60px;
        margin-bottom: 180px;
    }

    .sign-button-block{
        padding: 0px 16px 0px 16px;
        margin-bottom: 18px;
        height: 52px;
    }

    .create-account-fields-wrapper{
        margin-top: 32px;
    }

    .sign-field-block{
        margin-bottom: 24px;
    }

    .with-white-bg{
        background: #fff;
        color: #3CAEE0;
        border: 1px solid #3CAEE0;
    }

</style>