<template>
    <div class="container h-100">
        <div class="breadcrumbs d-flex">
            <div class="breadcrumb-element d-flex"><img src="../assets/images/home-icon.svg" alt=""><a href="/">Home</a></div>
            <img src="../assets/images/arrow-right.svg" alt="">
            <div class="breadcrumb-element d-flex" @click="$router.push('/get-help')">Get help right now</div>
            <img src="../assets/images/arrow-right.svg" alt="">
            <div class="breadcrumb-element d-flex">Booking</div>
        </div>
        <section class="w-100 h-75 type-section d-flex flex-column align-items-center justify-content-center">
            <h1 class="fw-bold text-center">Session is booked, thank you!</h1>

            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-8 col-xs-8 booked_session">
            </div>

            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-8 col-xs-8 d-flex flex-column align-items-center booking-button-wrapper">
                <button class="btn btn-primary" @click="$router.push({name: 'get_help_search_therapists', query: { 
                                    issueId: issueId
                                }} )">Search available Therapist</button>
            </div>
            
        </section>
    </div>
    
</template>

<script>

export default{
    data(){
        return{
            issueId: null
        }
    },
    mounted(){
        if(this.$route.query.issueId){
            this.issueId = this.$route.query.issueId
        }
    }
}

</script>

<style scoped>
    .btn-primary {
        padding: 8px 0;
        border-radius: 22px;
        background: linear-gradient(46deg, #43C9F3 0%, #3CAEE0 100%);
        color: #fff;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 85%;
        margin-bottom: 24px;
    }
    .btn-primary img {
        margin: 0px 12px;
    }
    .booking-button-wrapper p{
        width: 85%;
        font-size: 14px;
        line-height: 22px;
    }

    .booked_session{
        padding: 0px 32px 0px 32px;
        border-radius: 16px;
        margin-bottom: 32px;
        background-image: url('./../assets/images/booked_session.svg');
        height: 240px;
        background-position: center;
        background-repeat: no-repeat;
    }
</style>