<template>
    <div class="container">
        <div class="breadcrumbs d-flex">
            <div class="breadcrumb-element d-flex"><img src="../../assets/images/home-icon.svg" alt=""><a href="/">Home</a></div>
            <img src="../../assets/images/arrow-right.svg" alt="">
            <div class="breadcrumb-element d-flex" @click="$emit('changeComponent', 'HelpTypesComponent')">Get help right now</div>
            <img src="../../assets/images/arrow-right.svg" alt="">
            <div class="breadcrumb-element d-flex">Booking</div>
        </div>
        <section class="w-100 type-section d-flex flex-column align-items-center">
            <h1 class="fw-bold text-center">Your Therapist</h1>
            <p class="text-center type-description">We found a Therapist for your case. You can start a consultation right now.</p>

            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-8 col-xs-8 booking-data">
                <div class="therapist-basic-description d-flex">
                    <img src="../../assets/images/therapist-sample.jpg" alt="">
                    <div class="text-part">
                        <h4 class="therapist-name">Olga Pacholec</h4>
                        <p>Psychotherapist</p>
                        <p>12 years of experience</p>
                    </div>
                </div>
                <hr>
                <h4 class="text-center therapist-specialization-title">Therapist specialization:</h4>
                <div class="d-flex justify-content-around">
                    <span class="therapist-specialization">Depression</span> <span class="therapist-specialization">Depression</span> <span class="therapist-specialization">Depression</span>
                </div>
                <p class="text-center all-specs-text">All specializations <img src="../../assets/images/arrow-down.svg" alt=""></p>
            </div>

            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-8 col-xs-8 d-flex flex-column align-items-center booking-button-wrapper">
                <button class="btn btn-primary" @click="$emit('changeComponent', 'HelpTypesComponent')">£25<img src="../../assets/images/circle.svg" alt="">Pay</button>
            </div>
        </section>
    </div>
</template>

<script>
import axios from '@/axios';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    mounted(){
      
    },
    methods: {
        async getIssues(){
            console.log(this.$store.state.accessToken);
            try {
                await axios.get('/find-therapist/get-help-right-now', {
                    headers: {
                        'Authorization': `Bearer ${this.$store.state.accessToken}`,
                        'Accept': 'application/json'
                    }, 
                    params: {
                        issue_id: this.data.issue_id
                    }
                }).then((response) => { // использование стрелочной функции здесь
                    console.log(response.data);
                })
            } catch (error) {
                console.error('Ошибка при получении данных от API:', error)
            }
        }
    }
  },
};
</script>

<style scoped>
  .breadcrumbs{
      padding: 18px 0px;
  }
  .breadcrumb-element{
      margin-left: 6px;
      margin-right: 6px;
      cursor: pointer;
  }
  .breadcrumb-element a{
      text-decoration: none;
      color: #13204E;
  }

  .breadcrumb-element img{
      margin-right: 6px;
  }
  .type-section{
      padding: 38px 0px 60px;
  }
  h1{
      font-size: 38px;
      line-height: 46px;
      margin-bottom: 12px;
  }

  h2{
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      margin-bottom: 0px;
  }

  h4{
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 4px;
  }
  .detail-block-section{
      margin-bottom: 16px;
  }
  .detail-block-section:last-child{
      margin-bottom: 0px;
  }
  .detail-block-section p{
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 0px;
  }
  .detail-block-section span{
      padding: 4px 12px;
      background: #3A466E;
      border-radius: 15px;
      width: fit-content;
      color: #fff;
      font-size: 12px;
  }
  .type-section{
      line-height: 24px;
  }
  .type-description{
      margin-bottom: 32px;
  }
  .booking-data{
      background: #fff;
      padding: 32px;
      border-radius: 16px;
      margin-bottom: 32px;
  }
  .btn-primary {
      padding: 8px 0;
      border-radius: 22px;
      background: linear-gradient(46deg, #43C9F3 0%, #3CAEE0 100%);
      color: #fff;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 85%;
      margin-bottom: 24px;
  }
  .btn-primary img {
      margin: 0px 12px;
  }
  .booking-button-wrapper p{
      width: 85%;
      font-size: 14px;
      line-height: 22px;
  }
  .therapist-basic-description{
      width: 100%;
      display: flex;
      align-items: center;
  }
  .therapist-basic-description img{
      width: 96px;
      height: 96px;
  }
  .therapist-name{
      font-size: 20px;
      font-family: Libre Franklin;
      font-weight: 600;
      line-height: 28px;
      margin-bottom: 0px;
  }
  .therapist-basic-description p{
      margin-bottom: 0px;
  }
  .text-part{
      margin-left: 8px;
  }

  .therapist-specialization{
      padding: 4px 12px;
      background: #9DA3B7;
      border-radius: 15px;
      width: fit-content;
      color: #fff;
      font-size: 12px;
  }
  .therapist-specialization-title{
      margin-bottom: 12px;
  }
  .all-specs-text{
      margin-top: 14px;
      margin-bottom: 0px;
  }
</style>