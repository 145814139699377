<template>
  <div>
    <GetHelpDescriptionComponent v-if="currentComponent === 'GetHelpDescriptionComponent'" @changeComponent="changeComponent"></GetHelpDescriptionComponent>
    <HelpTypesComponent v-else-if="currentComponent === 'HelpTypesComponent'" @changeComponent="changeComponent"></HelpTypesComponent>
    <HelpTypeComponent v-else-if="currentComponent === 'HelpTypeComponent'" :data="currentData"></HelpTypeComponent>
    <YourTherapistComponent v-else-if="currentComponent === 'YourTherapistComponent'" :data="currentData"></YourTherapistComponent>
    <AddCardComponent v-else-if="currentComponent === 'AddCardComponent'" :data="currentData"></AddCardComponent>
  </div>
</template>

<script>
import GetHelpDescriptionComponent from './Components/GetHelpDescriptionComponent.vue';
import HelpTypesComponent from './Components/HelpTypesComponent.vue';
import HelpTypeComponent from './Components/HelpTypeComponent.vue';
import YourTherapistComponent from './Components/YourTherapistComponent.vue';
import AddCardComponent from './Components/AddCardComponent.vue'

export default {
  components: {
    GetHelpDescriptionComponent,
    HelpTypesComponent,
    HelpTypeComponent,
    YourTherapistComponent,
    AddCardComponent
  },
  data() {
    return {
      currentComponent: 'GetHelpDescriptionComponent',
      currentData: null,
    };
  },
  methods: {
    changeComponent(componentName, data = null) {
      this.currentComponent = componentName;
      this.currentData = data;
    },
  },
};
</script>