<template>
    <div class="container-fluid overflow-hidden">
        <div class="col-12 d-flex align-items-center h-100">
            <!-- <div>
                <h1 class="fw-bold" style="margin-left: 24px; margin-bottom: 18px; margin-top: 24px">Upcoming sessions</h1>
                <div v-for="session in sessions" :key="session.id" class="w-100 session-block">
                    <div class="w-50">
                    <div class="session-info w-100">
                        <div class="therapist-block d-flex align-items-center">
                        <div class="">
                            <img src="../assets/images/therapist-sample.jpg" alt="Psychotherapist" class="img-fluid rounded-circle therapist_image">
                        </div>
                        <div class="therapist-info" style="margin-bottom: 0px">
                            <div class="d-flex align-items-center therapist_name">
                            {{ session.practitioner.first_name }} {{ session.practitioner.last_name}}
                            </div>
                            <div class="d-flex align-items-center therapist_provider_type">
                            <span v-for="provider_type in session.practitioner.provider_types" :key="provider_type.index">{{ provider_type }}</span>
                            </div>
                        </div>
                        </div>
                            
                        <div class="session-details d-flex">
                        <div class="d-flex align-items-center session-datetime">
                            <div class="datetime-element">
                            <img src="../assets/images/calender.svg" alt=""> 
                            <p class="session_datetime"> {{ session.date }}</p>
                            </div>
                            <div class="datetime-element">
                            <img src="../assets/images/clock.svg" alt="">
                            <p class="session_datetime"> {{ session.time }}</p>
                            </div>
                            </div>
                            <div class="devider"></div>
                            <div class="d-flex">
                            <div class="d-flex align-items-center session-type">
                                <img src="../assets/images/online-icon.svg" alt="">
                                <p>{{ session.booking_type }}</p>
                            </div>
                            <div class="d-flex align-items-center session-type">
                                <img src="../assets/images/individual-therapy.svg" alt="">
                                <p v-for="(service, index) in session.services" :key="index">{{service.service_name}}</p>
                            </div>
                            </div>    
                        </div>
                        </div>
                    </div>

                    <div class="session-actions">
                        <div class="d-flex align-items-center">
                        <button class="edit-button" @click="openModal(session)" style="margin-right: 24px">Edit</button>
                        </div>
                        <div class="d-flex align-items-center">
                        <img class="close-img" style="width: 16px; height: 16px" src="../assets/images/close-icon.svg" @click="deleteSession(session.appointment_id)" alt="">
                        </div>
                    </div>
                    </div>

                <div class="w-100 d-flex justify-content-between" style="padding: 12px 24px 12px 24px; margin-top: 18px;">
                    <div class="d-flex align-items-center">
                    <img src="../assets/images/info-icon.svg" alt="">
                    <p style='margin-bottom: 0px; margin-left: 4px'>You have {{ sessions.length }} scheduled upcoming sessions</p>
                    </div>

                    <a style="text-decoration: none; color: #00809D; font-size: 12px" class="fw-bold" href="/my-sessions">See all my sessions</a>
                </div>
            </div> -->

            <div style="margin-bottom: 80px" class="w-100">
                <h1 class="fw-bold" style="margin-left: 24px; margin-bottom: 18px; margin-top: 24px">Dashboard</h1>

                <div class="full-width-section">
                    <div class="w-100 h-100" style="padding: 48px">
                        <div class="d-flex justify-content-between">
                            <h3 class="fw-bold">Overview</h3>
                            <div v-if="subscriptionStatus" style="margin-bottom: 0px; width: 200px;" class="d-flex align-items-center">
                                <p style="margin-bottom: 0px; line-height: 24px !important; height: 24px !important">Subscription enabled</p>
                            </div>
                            <div v-else style="margin-bottom: 0px; width: 200px;" class="d-flex align-items-center">
                                <button @click="$router.push('/therapist/add-card')" class="book-now-button">Enable subscription</button>
                            </div>
                        </div>
                        <hr style="margin-bottom: 0px;">
                        <div class="col-12 d-flex flex-wrap">
                            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 d-flex align-items-top" style="margin-top: 32px;">
                                <img src="../assets/images/success.svg" alt="" style="height: 62px">
                                <div style="margin-left: 24px">
                                    <p style="font-size: 48px; font-weight: bold; margin-bottom: 8px; line-height: 52px;">
                                        {{ therapistsChats > 0 ? therapistsChats : 1 }}
                                    </p>
                                    <p style="margin-bottom: 0px;">No of enquiries received</p>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 d-flex align-items-top" style="margin-top: 32px;">
                                <img src="../assets/images/earnings.svg" alt="" style="height: 62px">
                                <div style="margin-left: 24px">
                                    <a href="/therapist/personal-info?isEditing=true" style="font-size: 30px; font-weight: bold; margin-bottom: 8px; line-height: 52px; color: #000; text-decoration: none;">
                                        Edit profile
                                    </a>
                                </div>
                            </div>
                            <!-- <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 d-flex align-items-top" style="margin-top: 32px;">
                                <img src="../assets/images/schedule-icon.svg" alt="" style="height: 62px">
                                <div style="margin-left: 24px">
                                    <p style="font-size: 48px; font-weight: bold; margin-bottom: 8px; line-height: 52px;">
                                        {{ dashboardOverview != null ? dashboardOverview[0].patients_attended : 0 }}
                                    </p>
                                    <p style="margin-bottom: 0px;">The average number of sessions patients attended</p>
                                </div>
                            </div>
                             -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <TherapistOnlineSessionModalComponent 
            v-if="showModal && currentEditableSession.type == 'online'" 
            :isOpen="showModal" 
            :session="currentEditableSession" 
            @close="showModal = false" 
        /> -->
    </div>
</template>

<script>
// import TherapistOnlineSessionModalComponent from './Components/TherapistOnlineSessionModalComponent.vue';
import axios from '@/axios';
import connectyCube from "../config/connectycube-config"

export default{
    // components: {
    //     TherapistOnlineSessionModalComponent
    // },
    data(){
        return {
            sessions: [],
            // currentEditableSession: null,
            showModal: false,
            dashboardOverview: null,
            subscriptionStatus: false,
            therapistsChats: 0,
        }
    },
    async mounted(){
        await axios.get('/therapist/home/get-dashboard-overview', {
            headers: {
                Authorization: `Bearer ${this.$store.state.accessToken}`
            }
        }).then((response) => {
            this.dashboardOverview = response.data;
        });

        await axios.get('/therapist/payment/card', {
            headers: {
                Authorization: `Bearer ${this.$store.state.accessToken}`
            }
        });
        await axios.get('/therapist/subscription', {
            headers: {
                Authorization: `Bearer ${this.$store.state.accessToken}`
            }
        }).then(() => {
            this.subscriptionStatus = true;
        }).catch(() => {
            this.subscriptionStatus = false;
        });
        // await this.getSessionData();
    },
    methods: {
        // async getSessionData(){
        //     try {
        //         const response = await axios.get('therapist/home/get-upcoming-sessions', {
        //                 headers: {
        //                     Authorization: `Bearer ${this.$store.state.accessToken}`
        //                 }
        //             });
        //         if (response.data && response.data.length > 0) {
        //             this.sessions = response.data
        //         }
        //         this.sessions.forEach(
        //             async (session) => await this.getDateAndTimeOfSession(session)
        //         )
        //     } catch (error) {
        //         console.log(error);
        //     }
        // },
        cancelMeeting(){
            
        },
        connectUser() {
            connectyCube.createSession()
                .then(() => {
                    return connectyCube.login({
                        id: this.user.cc_id,
                        password: this.user.cc_password,
                        login: this.user.cc_username
                    });
                })
                .then(async () => {
                    await this.getChats();
                })
                .catch((error) => {
                console.error('Ошибка авторизации', error);
            });
        },
        async getChats(){
            this.therapistsChats = await connectyCube.chat.dialog.list({});
        },
        changeComponent(componentName, data = null) {
            this.currentComponent = componentName;
            
            this.currentData = data;
        },
        async getDateAndTimeOfSession(session){

            // Преобразование строки в объект Date
            var dateObj = new Date(session.appointment_datetime);

            // Получение даты
            var date = dateObj.getDate(); // День
            var month = dateObj.getMonth() + 1; // Месяц (начиная с 0)
            var year = dateObj.getFullYear(); // Год

            // Получение времени
            var hours = dateObj.getHours(); // Часы
            var minutes = dateObj.getMinutes(); // Минуты

            // Форматирование даты и времени в виде строк
            var dateString = `${year}-${month}-${date}`;
            var timeString = `${hours}:${minutes}`;

            session.date = dateString;
            session.time = timeString;
        },
        openModal(session){
            this.currentEditableSession = session;
            this.showModal = true;
        }
    }
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
    .no-sessions{
        padding: 0px 32px 0px 32px;
        border-radius: 16px;
        margin-bottom: 32px;
        background-image: url('../assets/images/no-therapists.svg');
        height: 240px;
        background-position: center;
        background-repeat: no-repeat;
    }
    .type-description{
        margin-bottom: 32px;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
    }

    .close-img{
        width: 16px;
    }

    .therapist_image{
        width: 48px;
        height: 48px;
    }

    .therapist-info{
        margin-bottom: 0px;
        margin-left: 12px;
    }

    .therapist_name{
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    .therapist_provider_type {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
    .session-type p{
        margin-bottom: 0px;
        margin-left: 4px; 
    }

    .session-type{
        margin-left: 24px;
    }

    .devider{
        width: 1px;
        height: 100%;
        background: #E6E8ED;
    }

    .session-details{
        padding: 13px 0px 13px 0px;
    }

    .datetime-element{
        display: flex;
        align-items: center;
        margin-right: 24px;
    }

    .datetime-element > p{
        margin-left: 4px;
    }

    .edit-button{
        margin-right: 22px;
    }

    .book-now-button{
        background: linear-gradient(90deg, #43C9F3 0%, #3CAEE0 100%);
        color: #fff;
        font-size: 17px;
        padding: 8px 12px;
        border: 1px solid #43C9F3;
        border-radius: 22px;
        cursor: pointer;
        font-weight: 600;
    }
</style>