<template>
    <section class="container h-100 d-flex align-items-center">
        <div class="w-100 d-flex align-items-center flex-column main-wrapper">
            <h1 class="text-center fw-bold">Privacy Policy for Private Therapy App</h1>
            <div class="steps-container steps-wrapper-block w-100">
                <h5 class="fw-bold">Introduction</h5>
                
                <p>Welcome to Private Therapy App. We are committed to protecting the privacy and security of our users' information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [<a href="http://therapyapp.co">therapyapp.co</a>] and use our services.</p>

                <h5 class="fw-bold">Information We Collect</h5>

                <p>We may collect personal information that you voluntarily provide to us when you register on the App, express an interest in obtaining information about us or our products and services, when you participate in activities on the App or otherwise contact us. The personal information we collect may include:</p>

                <ul>
                    <li>Name</li>
                    <li>Email address</li>
                    <li>Telephone number</li>
                    <li>Health information</li>
                    <li>Payment information</li>
                    <li>Any other personal information you choose to provide</li>
                </ul>

                <h5 class="fw-bold">How We Use Your Information</h5>

                <p>We use the information we collect for various purposes, including to:</p>

                <ul>
                    <li>Provide, operate, and maintain our App</li>
                    <li>Improve, personalize, and expand our App</li>
                    <li>Understand and analyze how you use our App</li>
                    <li>Develop new products, services, features, and functionality</li>
                    <li>Communicate with you for customer service, to provide you with updates and other information relating to the App, and for marketing and promotional purposes</li>
                    <li>Process your transactions</li>
                    <li>Send you emails</li>
                </ul>

                <h5 class="fw-bold">How We Share Your Information</h5>

                <p>We may share information we have collected about you in certain situations. Your information may be disclosed as follows:</p>

                <ul>
                    <li>To our subsidiaries and affiliates</li>
                    <li>To service providers, contractors, and other third parties we use to support our business</li>
                    <li>In response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law, regulation, or legal process</li>
                    <li>With your consent</li>
                </ul>

                <h5 class="fw-bold">Data Security</h5>

                <p>We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information.</p>

                <h5 class="fw-bold">Your Data Protection Rights Under GDPR</h5>

                <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. Private Therapy App aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</p>

                <h5 class="fw-bold">Changes to This Privacy Policy</h5>

                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website, [<a href="http://therapyapp.co">therapyapp.co</a>].</p>

                <h5 class="fw-bold">Contact Us</h5>

                <p>If you have any questions about this Privacy Policy, please contact us at </p>

                <p>[<a href="mailto:team@privatetherapyclinic.com">team@privatetherapyclinic.com</a>]</p>

                <p class="fst-italic">Last Updated: January 10, 2024</p>   
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'StepsComponent',
  data() {
    return {
    }
  }
}
</script>

<style scoped>

h1{
    font-size: 36px;
    margin-bottom: 12px;
}

h5 {
  margin-bottom: 4px;
}

p{
  margin-bottom: 24px;
}

.description-text {
    margin-bottom: 0px;
}

.main-wrapper{
    margin-top: 60px;
    margin-bottom: 60px;
}
.steps-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0px;
}

.step-item {
  display: flex;
  align-items: flex-start;
}

.step-number-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;
}

.step-number {
  padding: 12px;
  color: var(--font-dark-100, #13204E);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  border: 1px solid var(--main-colors-dark-15, #DADCE4);
  background: var(--MainColors-White, #FFF);
  box-shadow: 0px 4px 8px 0px rgba(51, 83, 196, 0.08);
}

.step-number > span {
    width: 24px !important;
    height: 24px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
}

.step-line {
  width: 2px;
  height: 32px; /* Adjust the height as needed */
  background-color: #43C9F3;
}

.step-content h3 {
  margin: 0;
  font-size: 1.1rem;
}

.step-content p {
  margin: 0;
}

.content-text > h3{
    display: flex;
    align-items: center;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
}

.steps-wrapper-block{
    width: 612px;
    padding: 48px;
    background: #fff;
    border-radius: 16px;
    margin-bottom: 32px;
    margin-top: 32px;
}

.next-step-button{
    width: 311px;
    margin-top: 18px;
}

@media (max-width: 612px) { 
  .steps-wrapper-block{
    width: calc(100% - 24px);
    padding: 24px;
  }

  h1{
    font-size: 24px;
  }
}
</style>