<template>
    <section class="container find-therapist-section h-100">
        <div class="w-100 general-wrapper">
          <h1 class="text-center fs-1 fw-bold main-text">Find a Therapist or Psychiatrist</h1>
          <p class="text-center type-description main-text">Answer a few questions to find the best Practitioner for you.</p>
            <div class="w-100">
                <div>
                  <p class="text-center home-text mb-3 main-text">Do you know what type of practitioner or approach you are interested in?</p>
                  <div class="toggle-switch-wrapper">
                      <div class="toggle-switch mb-3">
                          <div class="toggle-option" :class="{ active: filtersType === 'need' }" @click="filtersType = 'need'">
                              <span>I need assistance</span>
                          </div>
                          <div class="toggle-option" :class="{ active: filtersType === 'know' }" @click="filtersType = 'know'">
                              <span>I know</span>
                          </div>
                          <div class="toggle-slider" :class="{ 'know': filtersType === 'know' }"></div>
                      </div>
                  </div>
                </div>
              <div class="d-flex flex-column align-items-center w-100">
                
                <div class="filters-section" v-if="filtersType == 'need'">

                  <div class="w-100" v-if="!needAssistenceStepTwo">
                    <div class="find-therapist-block">
                      <h5 class="home-heading mb-3">What does the problem most relate to?</h5>
                      <div class="therapist-genders">
                        <div class="custom-checkbox">
                          <input class="gender-input" type="radio" id="radio_individual" v-model="selectedCategory" value='0'>
                          <label for="radio_individual">Individual difficulties</label>
                        </div>
                        <div class="custom-checkbox">
                          <input class="gender-input" type="radio" id="radio_relationship" v-model="selectedCategory" value='1'>
                          <label for="radio_relationship">Problems in my relationship</label>
                        </div>
                        <div class="custom-checkbox">
                          <input class="gender-input" type="radio" id="radio_relationship_trauma" v-model="selectedCategory" value='2'>
                          <label for="radio_relationship_trauma">A traumatic event</label>
                        </div>
                        <div class="custom-checkbox">
                          <input class="gender-input" type="radio" id="radio_relationship_medication" v-model="selectedCategory" value="3">
                          <label for="radio_relationship_medication">I want to discuss medication</label>
                        </div>
                      </div>
                      <button class="next-step-button" @click="needAssistenceStepTwo = true">Next step</button>
                    </div>
                  </div>
                  <div class="w-100" v-if="needAssistenceStepTwo">
                    <div class="find-therapist-block" v-if="currentQuestion"> 
                      <h5 class="home-heading mb-3">{{ currentQuestion.question_name }}</h5>
                      <div v-if="currentQuestion.type == 1" class="w-100">
                        <div 
                          class="w-100" 
                          v-for="(type, index) in currentQuestion.therapy_filter_answers" 
                          :key="index"  
                          @click="addOrRemoveTypeInList(type, currentQuestion.therapy_filter_question_id)"
                        >
                            <div class="type" :class="{ 'unchoosed-type': !type.isActive }" >
                                <h4 class="fw-bold type-title">
                                    {{ type.title }}
                                </h4>
                            </div>
                        </div>
                        <button class="next-step-button" @click="moveToNextBlock()">Next step</button>
                      </div>
                      <div class="therapist-genders" v-else>
                        <div class="custom-checkbox" v-for="(answer, index) in currentQuestion.therapy_filter_answers" :key="index">
                          <input class="gender-input" type="radio" :id="`answer-${answer.therapy_filter_answer_id}`" v-model="selectedNeedAssistanceAnswer" :value='answer'>
                          <label :for="`answer-${answer.therapy_filter_answer_id}`">{{answer.title}}</label>
                        </div>
                        <button class="next-step-button" @click="moveToNextBlockWithDataSaving()">Next step</button>
                      </div>
                      
                      
                    </div>
                  </div>
                  </div>
                  <div class="filters-section" v-if="filtersType == 'know'">
                    <div class="find-therapist-block">
                      <h5 class="home-heading mb-3">What type of practitioner do you need?</h5>
                      <div v-for="(item, index) in providerTypes" :key="index" class="custom-checkbox">
                        <input type="checkbox" :id="`provider-checkbox-${index}`" v-model="selectedProviders" :value="item.provider_id">
                        <label :for="`provider-checkbox-${index}`">{{ item.provider_type }}</label>
                      </div>

                      <h5 class="home-heading mb-3">I need help with:</h5>
                      <div class="chip-container">
                        <div class="chip-list row justify-content-between align-center">
                          <div class="row" style="padding: 0px;">
                            <div class="chip" v-for="(chip, index) in selectedIssues" :key="index">
                              <p>{{ chip.issue_name }}</p>
                              <img class="chip-close-button" src="../assets/images/delete-chip.svg" @click="removeIssue(index)" alt="">
                            </div>
                          </div>
                          
                          <div class="dropdown-arrow">
                            <img src="../assets/images/arrow-down-dark.svg" @click="showIssuesDropdown = !showIssuesDropdown">
                          </div>
                        </div>
                        <div class="dropdown" v-if="showIssuesDropdown">
                          <div class="dropdown-item" v-for="(item, index) in issues" :key="index" @click="addIssue(item)">
                            {{ item.issue_name }}
                          </div>
                        </div>
                      </div>
                      <h5 class="home-heading mb-3">Gender of Therapist:</h5>
                      <div class="therapist-genders">
                        <div class="gender-checkbox">
                          <input class="gender-input" type="radio" id="checkbox_male" v-model="selectedTherapistGender" value='Male'>
                          <label for="checkbox_male">Male</label>
                        </div>
                        <div class="gender-checkbox">
                          <input class="gender-input" type="radio" id="checkbox_female" v-model="selectedTherapistGender" value='Female'>
                          <label for="checkbox_female">Female</label>
                        </div>
                        <div class="gender-checkbox">
                          <input class="gender-input" type="radio" id="checkbox_non_binary" v-model="selectedTherapistGender" value='Non-binary'>
                          <label for="checkbox_non_binary">Non-binary</label>
                        </div>
                        <div class="gender-checkbox">
                          <input class="gender-input" type="radio" id="checkbox_any" v-model="selectedTherapistGender" value="it doesn't matter">
                          <label for="checkbox_any">It doesn't matter</label>
                        </div>
                      </div>

                      <!-- <div class="d-flex justify-content-between w-100" style="margin-top: 40px">
                        <h5 class="home-heading mb-3">Price per session (£)</h5>  
                        <div>
                          <p class="price-number-paragraph">£{{this.range[0]}}-£{{this.range[1]}}</p>
                        </div>
                      </div>

                      <vue-slider 
                        v-model="range" 
                        :min="minPrice" 
                        :max="maxPrice" 
                        :tooltip="'never'" 
                        :dotStyle="{backgroundColor: '#43C9F3', width: '24px', height:'16px', borderRadius: '10px', boxShadow: 'none'}"
                        :processStyle="{backgroundColor: '#43C9F3'}"
                        :railStyle="{backgroundColor: '#B5BAC9', borderRadius: '16px'}"
                      /> -->

                      <h5 class="home-heading mb-3">Language</h5>
                      <div class="chip-container">
                        <div class="chip-list row justify-content-between align-center">
                          <div class="row">
                            <div class="chip" v-for="(chip, index) in selectedLanguages" :key="index">
                              <p>{{ chip.language_name }}</p>
                              <img class="chip-close-button" src="../assets/images/delete-chip.svg" @click="removeChip(index)" alt="">
                            </div>
                          </div>
                          
                          <div class="dropdown-arrow">
                            <img src="../assets/images/arrow-down-dark.svg" @click="showLanguagesDropdown = !showLanguagesDropdown">
                          </div>
                        </div>
                        <div class="dropdown" v-if="showLanguagesDropdown">
                          <div class="dropdown-item" v-for="(item, index) in languages" :key="index" @click="addLanguage(item)">
                            {{ item.language_name }}
                          </div>
                        </div>
                      </div>

                      <!-- <h5 class="home-heading mb-3">Instant booking:</h5>
                      <div class="therapist-genders">
                        <div class="gender-checkbox">
                          <input class="gender-input" type="radio" id="checkbox_instant" v-model="instantBooking" value='true'>
                          <label for="checkbox_instant">Only instant booking</label>
                        </div>
                        <div class="gender-checkbox">
                          <input class="gender-input" type="radio" id="checkbox_any_booking" v-model="instantBooking" value='false'>
                          <label for="checkbox_any_booking">It doesn't matter</label>
                        </div>
                      </div>

                      <h5 class="home-heading mb-3">Select type of session:</h5>
                      <div class="toggle-switch-wrapper">
                          <div class="toggle-switch-internal mb-3">
                              <div class="toggle-option" :class="{ active: selectedSessionType === 'online' }" @click="selectedSessionType = 'online'">
                                  <span>Online</span>
                              </div>
                              <div class="toggle-option" :class="{ active: selectedSessionType === 'face_to_face' }" @click="selectedSessionType = 'face_to_face'">
                                  <span>Face-to-face</span>
                              </div>
                              <div class="toggle-slider" :class="{ 'face_to_face': selectedSessionType === 'face_to_face' }"></div>
                          </div>
                      </div>

                      <h5 class="home-heading mb-3">Location:</h5>
                      <input type="text" class="w-100 sign-field"  placeholder="Location"> -->

                      <!-- <h5 class="home-heading mb-3">Select date:</h5>
                      <div class="chip-list row justify-content-between align-center">
                        <date-picker class="not-selected-calendar" v-model="date" range :format="formatFunction" v-if="date.value">
                          <template #time-picker="">
                          </template>
                        </date-picker>
                        <date-picker class="selected-calendar" v-model="date" range :format="formatFunction" v-else>
                          <template #time-picker="">
                          </template>
                        </date-picker>
                      </div> -->

                      <h5 class="home-heading mb-3">Insurance companies: </h5>
                      <div class="chip-container">
                        <div class="chip-list row justify-content-between align-center">
                          <div class="row">
                            <div class="chip" v-for="(chip, index) in selectedInsuranceCompanies" :key="index">
                              <p>{{ chip.insurance_company }}</p>
                              <img class="chip-close-button" src="../assets/images/delete-chip.svg" @click="removeInsurenceCompany(index)" alt="">
                            </div>
                          </div>
                          
                          <div class="dropdown-arrow">
                            <img src="../assets/images/arrow-down-dark.svg" @click="showInsuranceCompaniesDropdown = !showInsuranceCompaniesDropdown">
                          </div>
                        </div>
                        <div class="dropdown" v-if="showInsuranceCompaniesDropdown">
                          <div class="dropdown-item" v-for="(item, index) in insuranceCompanies" :key="index" @click="addInsuranceCompany(item)">
                            {{ item.insurance_company }}
                          </div>
                        </div>
                      </div>

                      <h5 class="home-heading mb-3">Type of Therapy: </h5>
                      <div class="chip-container">
                        <div class="chip-list row justify-content-between align-center">
                          <div class="row">
                            <div class="chip" v-for="(chip, index) in selectedTherapyTypes" :key="index">
                              <p>{{ chip.therapy_type_name }}</p>
                              <img class="chip-close-button" src="../assets/images/delete-chip.svg" @click="removeTherapyType(index)" alt="">
                            </div>
                          </div>
                          
                          <div class="dropdown-arrow">
                            <img src="../assets/images/arrow-down-dark.svg" @click="showTherapyTypesDropdown = !showTherapyTypesDropdown">
                          </div>
                        </div>
                        <div class="dropdown" v-if="showTherapyTypesDropdown">
                          <div class="dropdown-item" v-for="(item, index) in therapyTypes" :key="index" @click="addTherapyType(item)">
                            {{ item.therapy_type_name }}
                          </div>
                        </div>
                      </div>

                      <h5 class="home-heading mb-3">Therapist's name:</h5>
                      <input type="text" class="w-100 sign-field" v-model="therapistName" placeholder="Therapist's name">

                      <button @click="goToPractionersPage()" class="next-step-button" style="margin-top: 40px"> <a style="color:#000; text-decoration: none"> Find my Therapist </a></button>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    // import VueSlider from 'vue-slider-component'
    import 'vue-slider-component/theme/default.css'
    import { reactive, toRefs } from 'vue'
    // import DatePicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css'
    import axios from '@/axios';
    // you need to import the CSS manually (in case you want to override it)
   

    export default{
        name: 'TherapistSearch',
        components: {
          // VueSlider,
          // DatePicker
        },
        setup() {
          const data = reactive({ range: [300, 1000] })
          
          return toRefs(data);
        },
        data() {
            return {
                therapistName: '',
                filtersType: 'need', // Default selected option
                selectedSessionType: 'face_to_face',
                
                selectedCategory: 0,
                selectedItems: [],
                
                providerTypes: [],
                selectedProviders: [],
          
                issues: [],
                selectedIssues: [],

                genders: [],
                selectedTherapistGender: "it doesn't matter",

                minPrice: 0,
                maxPrice: 1800,

                insuranceCompanies: [],
                selectedInsuranceCompanies: [],

                languages: [],
                selectedLanguages: [],

                instantBooking: true,

                therapyTypes: [],
                selectedTherapyTypes: [],

                items: [
                    { label: 'Item 1', value: 'item1' },
                    { label: 'Item 2', value: 'item2' },
                    { label: 'Item 3', value: 'item3' },
                ],
                helpTypes: [
                  { id: 1, name: 'Abuse', description: 'Emotional, physical, sexual abuse or etc.', isActive: false},
                  { id: 2, name: 'Addiction', description: 'Alcohol, drug, gambling, love or etc.', isActive: false },
                  { id: 3, name: 'Anger', description: 'Aggression, anger management or etc.', isActive: false },
                  { id: 4, name: 'Anxiety', description: 'Death, panic attack, agoraphobia or etc.', isActive: false },
                  { id: 5, name: 'Health', description: 'Abortion, cancer, dementia or etc.', isActive: false },
                  { id: 6, name: 'Trauma', description: 'Childhood trauma, sudden incident, etc. ', isActive: false },
                  { id: 5, name: 'Bipolar disorder', description: 'Manic episodes, mood swings, etc. ', isActive: false },
                  { id: 6, name: 'It’s hard to describe', description: 'I don’t know / I am not sure which issue.', isActive: false },
                  // Add more types as needed
                ],
                typesList: [
                ],
                chips: [],
                dropdownItems: ['Item 1', 'Item 2', 'Item 3'], // Замените на ваши элементы
                showIssuesDropdown: false,
                showInsuranceCompaniesDropdown: false,
                showLanguagesDropdown: false,
                showTherapyTypesDropdown: false,
                needAssistenceStepTwo: false,
                date: {
                  value: null
                },
                formatDate: new Date(),
                months: [
                  'January', 'February', 'March', 'April', 'May', 'June',
                  'July', 'August', 'September', 'October', 'November', 'December'
                ],
                needAssistanceListOfQuestions: {},
                needAssistanceFilters: [],
                currentQuestionIndex: 0,
                selectedNeedAssistanceAnswer: null
            };
        },
        computed: {
          currentQuestion() {
            return this.needAssistanceFilters[this.selectedCategory].therapy_filter_questions[this.currentQuestionIndex];
          }
        },
        async mounted(){
          const startDate = new Date();
          const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
          this.date.value = [startDate, endDate];
          this.$nextTick(() => {
              const element = document.querySelector('[aria-label="Open time picker"]');
              if (element) {
                  element.style.display = 'none';
              }
          });
          if(Object.keys(this.$route.query).length != 0){
            this.filtersType = this.$route.query.filterType;
          }
          await this.getIKnowFilters();
          await this.getINeedAssistanceFilters();
        },
        methods: {
          addOrRemoveTypeInList(type, index){
            this.currentQuestion.therapy_filter_answers.forEach((answer) => {
              answer.isActive = false;
            })
            this.needAssistanceListOfQuestions[index] = type.therapy_filter_answer_id;

            type.isActive = true;
          },
          addIssue(item) {
            if(this.selectedIssues.indexOf(item) == -1){
              this.selectedIssues.push(item);
              this.showIssuesDropdown = false;
            }
          },
          addLanguage(item) {
            if(this.selectedLanguages.indexOf(item) == -1){
              this.selectedLanguages.push(item);
              this.showLanguagesDropdown = false;
            }
          },
          addInsuranceCompany(item) {
            if(this.selectedInsuranceCompanies.indexOf(item) == -1){
              this.selectedInsuranceCompanies.push(item);
              this.showInsuranceCompaniesDropdown = false;
            }
          },
          addTherapyType(item) {
            if(this.selectedTherapyTypes.indexOf(item) == -1){
              this.selectedTherapyTypes.push(item);
              this.showTherapyTypesDropdown = false;
            }
          },
          removeIssue(index) {
            this.selectedIssues.splice(index, 1);
          },
          removeLanguage(index) {
            this.selectedLanguages.splice(index, 1);
          },
          removeInsurenceCompany(index) {
            this.selectedInsuranceCompanies.splice(index, 1);
          },
          removeTherapyType(index) {
            this.selectedTherapyTypes.splice(index, 1);
          },
          addChip(item) {
            this.chips.push(item);
            this.showDropdown = false;
          },
          removeChip(index) {
            this.chips.splice(index, 1);
          },
          formatFunction(date) {
            const startDay = date[0].getDate();
            let endDay = '';
            if(date.length == 2){
              endDay = date[1].getDate();
            }
          
            const monthName = this.months[date[0].getMonth()];

            return `${monthName} ${startDay}-${endDay}`;
          },
          async getIKnowFilters(){
              try {
                  await axios.get('/filter/i-know', {
                  }).then((response) => { // использование стрелочной функции здесь
                      this.providerTypes = response.data.provider_types;
                      this.issues = response.data.issues;
                      this.minPrice = response.data.min_price;
                      this.maxPrice = response.data.max_price;
                      this.insuranceCompanies = response.data.insurance_companies;
                      this.therapyTypes = response.data.therapy_types;
                      this.languages = response.data.languages;
                  })
              } catch (error) {
                  this.$router.push('/');
                  console.error('Ошибка при получении данных от API:', error)
              }
          },
          async getINeedAssistanceFilters(){
              try {
                  await axios.get('/filter/need-assistance', {
                    headers: {
                      Authorization: `Bearer ${this.$store.state.accessToken}`
                    }
                  }).then((response) => { // использование стрелочной функции здесь
                      this.needAssistanceFilters = response.data;

                      console.log(this.needAssistanceFilters);
                  })
              } catch (error) {
                  this.$router.push('/');
                  console.error('Ошибка при получении данных от API:', error)
              }
          },
          async getRecommededTherapy(){
              try {
                  await axios.post('/find-recommended-therapy', {
                      'therapy_category_id': Number(this.selectedCategory)+1,
                      'question_answer_array': this.needAssistanceListOfQuestions
                    }).then((response) => { // использование стрелочной функции здесь
                      this.$router.push({ name: 'recommended_therapy', query: { 
                          // therapistsData: response.data,
                          image: response.data.image,
                          typeDescription: response.data.therapy_type_description,
                          typeName: response.data.therapy_type_name,
                          typeId: response.data.therapy_type_id,
                          issueId: response.data.issue_id,
                        } 
                      });
                  })
              } catch (error) {
                  console.error('Ошибка при получении данных от API:', error)
              }
          },
          async goToPractionersPage(){
            console.log(this.selectedTherapyTypes);
            this.$router.push({ name: 'found_therapists', query: { 
                // therapistsData: response.data,
                therapistName: this.therapistName,
                selectedSessionType: this.selectedSessionType,
                
                selectedProviders: JSON.stringify(this.selectedProviders),
          
                selectedIssues: JSON.stringify(this.selectedIssues),

                selectedTherapistGender: this.selectedTherapistGender,

                // minPrice: this.range[0],
                // maxPrice: this.range[1],

                selectedInsuranceCompanies: JSON.stringify(this.selectedInsuranceCompanies),

                selectedLanguages: JSON.stringify(this.selectedLanguages),

                // instantBooking: this.instantBooking,

                selectedTherapyTypes:JSON.stringify(this.selectedTherapyTypes),

                showIssuesDropdown: this.showIssuesDropdown,
                showInsuranceCompaniesDropdown: this.showInsuranceCompaniesDropdown,
                showLanguagesDropdown: this.showLanguagesDropdown,
                showTherapyTypesDropdown: this.showTherapyTypesDropdown,
                
                // date: JSON.stringify(this.date),
              } 
            });
          },
          moveToNextBlock() {
            if (this.currentQuestionIndex < this.needAssistanceFilters[this.selectedCategory].therapy_filter_questions.length - 1) {
              this.currentQuestionIndex++;
            } 
          },
          moveToNextBlockWithDataSaving() {
            this.needAssistanceListOfQuestions[Object.keys(this.needAssistanceListOfQuestions).length] = this.selectedNeedAssistanceAnswer.therapy_filter_answer_id;

            if(this.selectedNeedAssistanceAnswer.trigger_next){
              this.selectedNeedAssistanceAnswer = null;
              if (this.currentQuestionIndex < this.needAssistanceFilters[this.selectedCategory].therapy_filter_questions.length - 1) {
                this.currentQuestionIndex++;
              } 
            }else{
              this.getRecommededTherapy();
            }
          },
        }
    }
</script>

<style scoped lang="scss" rel="stylesheet/scss">
  h5{
    margin-top: 40px;
  }

  h5:first-child{
    margin-top: 0px;
  }

  .filters-section{
    width: 612px;
  }

  .gender-checkbox {
    position: relative;
    margin: 10px 0;
  }

  .gender-checkbox input[type="radio"] {
    display: none;
  }

  .price-number-paragraph{
    margin-bottom: 0px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .sign-field{
    background: #fff;
    height: 44px;
    border: none;
    border-radius: 8px;
    padding: 10px 12px 10px 12px;
    line-height: 24px;
  }

  .vue-slider-dot-tooltip{
    display: none !important;
  }

  .find-therapist-block{
    /deep/ .dp__calendar_item {
      width: 40px;
      height: 34px;
      margin-left: 2px;
      margin-right: 2px;
    }

    /deep/ .dp__cell_inner {
      width: 100%;
    }

    /deep/ .dp__range_between {
      background-color: #43C9F3 !important;
      color: #fff;
      border: 1px solid #43C9F3;
      border-radius: 4px;
    }

    /deep/ .dp__range_start{
      background: #43C9F3;
      color: #fff;
      border-radius: 4px;
    }

    /deep/ .dp__range_end{
      background: #43C9F3;
      color: #fff;
      border-radius: 4px;
    }

    /deep/ .dp__date_hover_end:hover{
      background: #43C9F3;
      color: #fff;
      border-radius: 4px;
    }

    /deep/ .dp__today{
      border: none;
    }

    /deep/ .dp__date_hover_start:hover{
      background: #43C9F3;
      color: #fff;
      border-radius: 4px;
    }

    /deep/ .dp__menu{
      border-radius: 16px;
    }
    
    /deep/ .dp__icon{
      display: none;
    }

    /deep/ .dp__action_select{
      background: #00D2FF;
      border-radius: 16px;
      padding: 4px 12px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }
    
    /deep/ .dp__action_cancel{
      border: 1px solid #00D2FF;
      background: #fff;
      color: #00D2FF;
      border-radius: 16px;
      padding: 4px 12px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }
  }

  .not-selected-calendar{
    /deep/ .dp__input{
      color: #fff;
      background: transparent;
      font-size: 12px;
      padding: 4px 12px 4px 12px;
      border-radius: 15px;
      border: none;
    }
  }
  
  .selected-calendar{
    /deep/ .dp__input{
      color: #fff;
      background: #43C9F3;
      font-size: 12px;
      padding: 4px 12px 4px 12px;
      border-radius: 15px;
      min-width: min-content;
      max-width: 120px;
    }
  }
  
  .gender-checkbox label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
  }
  
  .gender-checkbox label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #B5BAC9;
    border-radius: 8px;
  }
  
  .gender-checkbox input[type="radio"]:checked + label:before {
    background-color: #fff;
    border-color: #13204E;
  }
  
  .gender-checkbox input[type="radio"]:checked + label:after {
    content: '';
    position: absolute;
    width:10px;
    height: 10px;
    left: 4px;
    top: 4px;
    color: white;
    font-size: 16px;
    background: #13204E;
    border-radius: 16px;
  }
   
  h5{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  .home-text{
    font-size: 16px;
    font-weight: 600;     
  }

  .chip-container{
    position: relative;
  }

  .dropdown-item{
    cursor: pointer;
  }

  .dropdown{
    position: absolute;
    z-index: 999;
    background: #fff;
    width: 100%;
    left: 0%;
    padding: 15px;
    border: 2px solid #F3F3F6;
    border-radius: 8px;
    height: 200px;
    overflow-y: scroll;
  }
  .dropdown-arrow{
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .container {
    padding: 60px 0px 60px;
  }

  .chip-close-button{
    cursor: pointer;
  }

  .dp__pointer{
    width: 100% !important;
  }

  .chip-list{
    background: #fff;
    padding: 8px 12px 8px 12px;
    min-height: 44px;
    border-radius: 8px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .chip{
    padding: 4px 12px 4px 12px;
    background-color: #43C9F3;
    display: flex;
    width: fit-content;
    align-items: center;
    font-size: 12px;
    height: 28px;
    border-radius: 16px;
    margin-right: 8px;
    margin-bottom: 3px;
  }

  .chip > p{
    margin-bottom: 0px;
    color: #fff;
    margin-right: 4px;
  }

  .toggle-switch {
    position: relative;
    width: 370px;
    height: 38px;
    background-color: #F3F3F6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    border-radius: 15px;
    border: 1px solid var(#E6E8ED);
  }

  .toggle-switch-internal {
    position: relative;
    width: 100%;
    height: 38px;
    background-color: #F3F3F6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    border-radius: 15px;
    border: 1px solid var(#E6E8ED);
  }
  
  .toggle-option {
    cursor: pointer;
    z-index: 2;
    width:50%;
    text-align: center;
    transition: all 0.3s ease;
  }
  
  .toggle-option.active span {
    font-weight: bold;
  }

  .type{
    background: #E8E9EA;
    padding: 16px;
    color: #005B70;
    box-shadow: 0px 4px 8px 0px rgba(51, 83, 196, 0.08);
    border: 1px solid #005B70;
    border-radius: 16px;
    border-style: solid;
    border-width: 1px;
    margin-bottom: 24px;
    transition: 0.3s ease;
    cursor: pointer;
  }

  .unchoosed-type{
    border-color: #E6E8ED;
    color: #000;
    background: #fff;
  }

  .type-title{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px;
    padding-top: 12;
    padding-bottom: 12;
  }

  .type-description{
    margin-bottom: 32px !important;
    line-height: 24px;
  }
  
  .toggle-slider {
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(50% - 2px);
    height: calc(100% - 4px);
    background-color: #fff;
    border-radius: 15px;
    transition: all 0.3s ease;
    z-index: 1;
    box-shadow: 0px 3px 8px 0px #182D420D;
  }
  
  .toggle-slider.know {
    transform: translateX(calc(100%));
  }

  .toggle-slider.face_to_face {
    transform: translateX(calc(100%));
  }
  
  .toggle-switch-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .type-description{
    margin-bottom: 32px;
  }

  .find-therapist-section{
    background-image: url('../assets/images/home-background.svg');
    background-repeat: no-repeat;
    background-position: 90%;
    background-position-y: bottom;
  }

  .footer{
    background-color: #fff;
    margin-top: 0px;
  }
  .find-therapist-block {
    background-color: #fff;
    padding: 48px;
    border-radius: 16px;
  }

.custom-checkbox{
  margin-bottom: 12px;
}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox input[type="radio"] {
  display: none;
}

.custom-checkbox label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
}

.custom-checkbox label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #B5BAC9;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center center;
}
.custom-checkbox input[type="radio"]:checked + label:before,
.custom-checkbox input[type="checkbox"]:checked + label:before {
  background-color: #43C9F3;
  border-color: #43C9F3;
  background-image: url('../assets/images/check-sign.svg');
}


/* Optional: Styles for hover and focus states */
.custom-checkbox input[type="radio"]:hover + label:before,
.custom-checkbox input[type="radio"]:focus + label:before
.custom-checkbox input[type="checkbox"]:hover + label:before,
.custom-checkbox input[type="checkbox"]:focus + label:before {
  border-color: #43C9F3;
}

@media (max-width: 768px) { 
  .filters-section{
    width: 556px;
  }
}

@media (max-width: 570px) { 
  .filters-section{
    width: 100%;
  }

  .find-therapist-block{
    border-radius: 0px;
    padding: 48px 16px;
  }

  .main-text {
    text-align: left !important;
    padding: 0px 16px;
  }

  .find-therapist-section{
    background: #fff;
  }
}
</style>