<template>
    <section class="container sign-container">
        <div class="w-100 sign-container" v-if="!passwordCofirmed">
            <img src="../assets/images/forgot-password.svg" alt="">
            <h1 class="text-center fs-1 fw-bold">Forgot your password?</h1>
            <p class="text-center already-signed-text">Enter your e-mail to reset your password and get a new one.</p>

            <div class="col-xl-3 col-lg-3 col-md-6 create-account-fields-wrapper">
            <div class="sign-field-block w-100">
                    <h6>Email</h6>
                    <input type="email" class="w-100 sign-field" v-model="userEmail" placeholder="Enter your email">
            </div>

                <div class="w-100 sign-button-block">
                    <button class="next-step-button" @click="forgotPassword()">Reset password</button>
                </div>
                
            </div>
        </div>
        <div v-else class="w-100 sign-container">
            <img src="../assets/images/password-confirm.svg" alt="">
            <p class="text-center fw-bold">We sent you a message with a new password.</p>
        </div>
        
     </section>
</template>

<script>
import axios from '@/axios';

export default{
    data(){
        return{
            userEmail: '',
            passwordCofirmed: false,
        }
    },
    mounted(){
    },
    methods: {
        async forgotPassword(){
            try {
                await axios.post('/client/forgot-password', {
                    email: this.userEmail,
                }).then((response) => { // использование стрелочной функции здесь
                    if(response.data == 'success'){
                        this.passwordCofirmed = true;
                    }
                   
                })
            } catch (error) {
                this.$router.push('/');
                console.error('Ошибка при получении данных от API:', error)
            }
        }
    }
}
</script>

<style scoped>

    .sign-field{
        background: #F3F3F6;
        height: 44px;
        border: 1px solid #E6E8ED;
        border-radius: 8px;
        padding: 10px 12px 10px 12px;
        line-height: 24px;
    }

    h6{
        font-weight: 600;
    }

    .already-signed-text{
        margin-bottom: 0px;
    }

    .privacy-text{
        font-size: 14px;
    }

    .sign-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 60px;
        margin-bottom: 235px;
    }

    .sign-button-block{
        padding: 0px 16px 0px 16px;
        margin-bottom: 32px;
    }

    .create-account-fields-wrapper{
        margin-top: 32px;
    }

    .sign-field-block{
        margin-bottom: 24px;
    }

</style>