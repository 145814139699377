<template>
    <div class="find-therapist-block">
        <h5 class="home-heading mb-3">What type of practitioner do you need?</h5>
        <div v-for="(item, index) in providerTypes" :key="index" class="custom-checkbox">
            <input type="checkbox" :id="`provider-checkbox-${index}`" v-model="selectedProviders" :value="item.provider_id" @input="getPractioners()">
            <label :for="`provider-checkbox-${index}`">{{ item.provider_type }}</label>
            </div>

        <h5 class="home-heading mb-3">I need help with:</h5>
        <div class="chip-container">
            <div class="chip-list row justify-content-between align-center">
            <div class="row">
                <div class="chip" v-for="(chip, index) in selectedIssues" :key="index">
                <p>{{ chip.issue_name }}</p>
                <img class="chip-close-button" src="../../assets/images/delete-chip.svg" @click="removeIssue(index)" alt="">
                </div>
            </div>
            
            <div class="dropdown-arrow">
                <img src="../../assets/images/arrow-down-dark.svg" @click="showIssuesDropdown = !showIssuesDropdown">
            </div>
            </div>
            <div class="dropdown" v-if="showIssuesDropdown">
            <div class="dropdown-item" v-for="(item, index) in issues" :key="index" @click="addIssue(item)">
                {{ item.issue_name }}
            </div>
            </div>
        </div>
        <h5 class="home-heading mb-3">Gender of Therapist:</h5>
        <div class="therapist-genders">
            <div class="gender-checkbox">
            <input class="gender-input" type="radio" id="checkbox_male" v-model="selectedTherapistGender" value='Male' @input="getPractioners()">
            <label for="checkbox_male">Male</label>
            </div>
            <div class="gender-checkbox">
            <input class="gender-input" type="radio" id="checkbox_female" v-model="selectedTherapistGender" value='Female' @input="getPractioners()">
            <label for="checkbox_female">Female</label>
            </div>
            <div class="gender-checkbox">
            <input class="gender-input" type="radio" id="checkbox_non_binary" v-model="selectedTherapistGender" value='Non-binary' @input="getPractioners()">
            <label for="checkbox_non_binary">Non-binary</label>
            </div>
            <div class="gender-checkbox">
            <input class="gender-input" type="radio" id="checkbox_any" v-model="selectedTherapistGender" value="it doesn't matter" @input="getPractioners()">
            <label for="checkbox_any">It doesn't matter</label>
            </div>
        </div>

        <!-- <h5 class="home-heading mb-3">Price per session (£)</h5>

        <vue-slider 
            v-model="range" 
            :min=100 
            :max=1800 
            :tooltip="'never'" 
            :dotStyle="{backgroundColor: '#43C9F3', width: '24px', height:'16px', borderRadius: '10px', boxShadow: 'none'}"
            :processStyle="{backgroundColor: '#43C9F3'}"
            :railStyle="{backgroundColor: '#B5BAC9', borderRadius: '16px'}"
        /> -->

        <h5 class="home-heading mb-3">Language</h5>
        <div class="chip-container">
            <div class="chip-list row justify-content-between align-center">
            <div class="row">
                <div class="chip" v-for="(chip, index) in selectedLanguages" :key="index">
                <p>{{ chip.language_name }}</p>
                <img class="chip-close-button" src="../../assets/images/delete-chip.svg" @click="removeLanguage(index)" alt="">
                </div>
            </div>
            
            <div class="dropdown-arrow">
                <img src="../../assets/images/arrow-down-dark.svg" @click="showLanguagesDropdown = !showLanguagesDropdown">
            </div>
            </div>
            <div class="dropdown" v-if="showLanguagesDropdown">
            <div class="dropdown-item" v-for="(item, index) in languages" :key="index" @click="addLanguage(item)">
                {{ item.language_name }}
            </div>
            </div>
        </div>

        <!-- <h5 class="home-heading mb-3">Instant booking:</h5>
        <div class="therapist-genders">
            <div class="gender-checkbox">
            <input class="gender-input" type="radio" id="checkbox_instant" v-model="instantBooking" value='true'>
            <label for="checkbox_instant">Only instant booking</label>
            </div>
            <div class="gender-checkbox">
            <input class="gender-input" type="radio" id="checkbox_any_booking" v-model="instantBooking" value='false'>
            <label for="checkbox_any_booking">It doesn't matter</label>
            </div>
        </div> -->

        <!-- <h5 class="home-heading mb-3">Select type of session:</h5>
        <div class="toggle-switch-wrapper">
            <div class="toggle-switch-internal mb-3">
                <div class="toggle-option" :class="{ active: selectedSessionType === 'online' }" @click="selectedSessionType = 'online'">
                    <span>Online</span>
                </div>
                <div class="toggle-option" :class="{ active: selectedSessionType === 'face_to_face' }" @click="selectedSessionType = 'face_to_face'">
                    <span>Face-to-face</span>
                </div>
                <div class="toggle-slider" :class="{ 'face_to_face': selectedSessionType === 'face_to_face' }"></div>
            </div>
        </div> -->

        <!-- <h5 class="home-heading mb-3">Location:</h5>
        <input type="text" class="w-100 sign-field"  placeholder="Location">

        <h5 class="home-heading mb-3">Select date:</h5>
        <div class="chip-list row justify-content-between align-center">
          <date-picker class="not-selected-calendar" v-model="date" range :format="formatFunction" v-if="date.value">
            <template #time-picker="">
            </template>
          </date-picker>
          <date-picker class="selected-calendar" v-model="date" range :format="formatFunction" v-else>
            <template #time-picker="">
            </template>
          </date-picker>
        </div> -->

        <h5 class="home-heading mb-3">Insurance companies: </h5>
        <div class="chip-container">
            <div class="chip-list row justify-content-between align-center">
            <div class="row">
                <div class="chip" v-for="(chip, index) in selectedInsuranceCompanies" :key="index">
                <p>{{ chip.insurance_company }}</p>
                <img class="chip-close-button" src="../../assets/images/delete-chip.svg" @click="removeInsurenceCompany(index)" alt="">
                </div>
            </div>
            
            <div class="dropdown-arrow">
                <img src="../../assets/images/arrow-down-dark.svg" @click="showInsuranceCompaniesDropdown = !showInsuranceCompaniesDropdown">
            </div>
            </div>
            <div class="dropdown" v-if="showInsuranceCompaniesDropdown">
            <div class="dropdown-item" v-for="(item, index) in insuranceCompanies" :key="index" @click="addInsuranceCompany(item)">
                {{ item.insurance_company }}
            </div>
            </div>
        </div>

        <h5 class="home-heading mb-3">Type of Therapy: </h5>
        <div class="chip-container">
            <div class="chip-list row justify-content-between align-center">
            <div class="row">
                <div class="chip" v-for="(chip, index) in selectedTherapyTypes" :key="index">
                <p>{{ chip.therapy_type_name }}</p>
                <img class="chip-close-button" src="../../assets/images/delete-chip.svg" @click="removeTherapyType(index)" alt="">
                </div>
            </div>
            
            <div class="dropdown-arrow">
                <img src="../../assets/images/arrow-down-dark.svg" @click="showTherapyTypesDropdown = !showTherapyTypesDropdown">
            </div>
            </div>
            <div class="dropdown" v-if="showTherapyTypesDropdown">
            <div class="dropdown-item" v-for="(item, index) in therapyTypes" :key="index" @click="addTherapyType(item)">
                {{ item.therapy_type_name }}
            </div>
            </div>
        </div>

        <h5 class="home-heading mb-3">Therapist's name:</h5>
        <input type="text" class="w-100 sign-field" v-model="therapistName" placeholder="Therapist's name">

        <button @click="getPractioners()" class="next-step-button" style="margin-top: 40px"> <a style="color:#fff; text-decoration: none"> Find my Therapist </a></button>
    </div>
</template>

<script src="">
// import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import { reactive, toRefs } from 'vue'
// import DatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import axios from '@/axios';

export default{
    name: 'TherapistSearch',
    components: {
      // VueSlider,
      // DatePicker,
    },
    props: {
        onceUsedForMobile: {
            type: Boolean,
            default: false
        }
    },
    setup() {
      let data = reactive({ range: [300, 1000] })
      
      return toRefs(data);
    },
    data() {
      return {
        therapistName: '',
        selectedOption: 'option1', // Default selected option
        selectedSessionType: 'face_to_face',
        selectedItems: [],

        selectedAvailableHour: null,
        
        providerTypes: [],
        selectedProviders: [],
  
        issues: [],
        selectedIssues: [],

        genders: [],
        selectedTherapistGender: "it doesn't matter",

        minPrice: 0,
        maxPrice: 1800,

        insuranceCompanies: [],
        selectedInsuranceCompanies: [],

        languages: [],
        selectedLanguages: [],

        instantBooking: true,

        therapyTypes: [],
        selectedTherapyTypes: [],
        
        showIssuesDropdown: false,
        showInsuranceCompaniesDropdown: false,
        showLanguagesDropdown: false,
        showTherapyTypesDropdown: false,
        dateRange: {
          startDate: new Date(),
          endDate: new Date()
        },
        date: {
          value: null
        },
        formatDate: new Date(),
        months: [
          'January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
        ],
        practitioners: [],
      };
    },
    async mounted(){
      const startDate = new Date();
      const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
      this.date.value = [startDate, endDate];
      this.$nextTick(() => {
          const element = document.querySelector('[aria-label="Open time picker"]');
          if (element) {
              element.style.display = 'none';
          }
      });
      await this.setSelectedValues();
      await this.getIKnowFilters();
    },
    methods: {
      async addOrRemoveTypeInList(type, index){
        if(this.typesList.includes(type)){
          let typeIndex = this.typesList.indexOf(type);
           this.typesList.splice(typeIndex, 1);
        }else{
          this.typesList.push(type);
        }

        this.helpTypes[index].isActive = !this.helpTypes[index].isActive;

        await this.getPractioners();
      },
      async addIssue(item) {
        if(this.selectedIssues.indexOf(item) == -1){
          this.selectedIssues.push(item);
          this.showIssuesDropdown = false;
        }

        await this.getPractioners();
      },
      async addLanguage(item) {
        if(this.selectedLanguages.indexOf(item) == -1){
          this.selectedLanguages.push(item);
          this.showLanguagesDropdown = false;
        }
        await this.getPractioners();
      },
      async addInsuranceCompany(item) {
        if(this.selectedInsuranceCompanies.indexOf(item) == -1){
          this.selectedInsuranceCompanies.push(item);
          this.showInsuranceCompaniesDropdown = false;
        }
        await this.getPractioners();
      },
      async addTherapyType(item) {
        if(this.selectedTherapyTypes.indexOf(item) == -1){
          this.selectedTherapyTypes.push(item);
          this.showTherapyTypesDropdown = false;
        }

        await this.getPractioners();
      },
      async removeIssue(index) {
        this.selectedIssues.splice(index, 1);
        await this.getPractioners();
      },
      async removeLanguage(index) {
        this.selectedLanguages.splice(index, 1);

        await this.getPractioners();
      },
      async removeInsurenceCompany(index) {
        this.selectedInsuranceCompanies.splice(index, 1);
      
        await this.getPractioners();
      },
      async removeTherapyType(index) {
        this.selectedTherapyTypes.splice(index, 1);
        
        await this.getPractioners();
      },
      formatFunction(date) {
        const startDay = date[0].getDate();
        let endDay = '';
        if(date.length == 2){
          endDay = date[1].getDate();
        }
      
        const monthName = this.months[date[0].getMonth()];

        return `${monthName} ${startDay}-${endDay}`;
      },
      async getIKnowFilters(){
          try {
              await axios.get('/filter/i-know', {
                headers: {
                  Authorization: `Bearer ${this.$store.state.accessToken}`
                }
              }).then((response) => { // использование стрелочной функции здесь
                  this.providerTypes = response.data.provider_types;
                  this.issues = response.data.issues;
                  this.minPrice = response.data.min_price;
                  this.maxPrice = response.data.max_price;
                  this.insuranceCompanies = response.data.insurance_companies;
                  this.therapyTypes = response.data.therapy_types;
                  this.languages = response.data.languages;

                  // if(this.selectedTherapyTypes.length == 1){
                  //   if(!('issue_name' in this.selectedTherapyTypes[0])){
                  //     this.issues.forEach((issue) => {
                  //       if(issue.issue_id == this.selectedIssues[0].issue_id){
                  //         this.selectedIssues[0].issue_name = issue.issue_name;
                  //       }
                  //     })
                  //   }
                  // }
              })
          } catch (error) {
              this.$router.push('/');
              console.error('Ошибка при получении данных от API:', error)
          }
      },
      async setSelectedValues(){
        if(Object.keys(this.$route.query).length != 0){
          this.selectedIssues = JSON.parse(this.$route.query.selectedIssues);
          this.selectedInsuranceCompanies = JSON.parse(this.$route.query.selectedInsuranceCompanies);
          this.selectedLanguages = JSON.parse(this.$route.query.selectedLanguages);
          this.selectedProviders = JSON.parse(this.$route.query.selectedProviders);
          this.selectedTherapistGender = this.$route.query.selectedTherapistGender;
          // this.selectedSessionType = this.$route.query.selectedSessionType;
          this.selectedTherapyTypes = JSON.parse(this.$route.query.selectedTherapyTypes);
          // this.instantBooking = JSON.parse(this.$route.query.instantBooking);
          // this.range[0] = this.$route.query.minPrice;
          // this.range[1]= this.$route.query.maxPrice;
          this.therapistName = this.$route.query.therapistName;
          // this.date = [
          //   new Date(JSON.parse(this.$route.query.date)[0]),
          //   new Date(JSON.parse(this.$route.query.date)[1]),
          // ];

          if(!this.onceUsedForMobile){
            await this.getPractioners();
          } 
        }
      },
      async getPractioners(){
            let languageIds = [];
            this.selectedLanguages.forEach((language) => {
              languageIds.push(language.language_id);
            });

            let issueIds = [];
            this.selectedIssues.forEach((issue) => {
              issueIds.push(issue.issue_id);
            });

            let therapyTypeIds = [];
            this.selectedTherapyTypes.forEach((therapy_type) => {
              therapyTypeIds.push(therapy_type.therapy_type_id);
            });

            let insuranceCompanyIds = [];
            this.selectedInsuranceCompanies.forEach((insurance_company) => {
              insuranceCompanyIds.push(insurance_company.insurance_company_id);
            });

            // const dateStr = '["'+this.date[0].getFullYear()+'-'+(Number(this.date[0].getMonth())+1)+'-'+this.date[0].getDate()+'", "'+ this.date[1].getFullYear()+'-'+(Number(this.date[1].getMonth())+1)+'-'+this.date[1].getDate()+'"]';
        
            try {
                await axios.get('/practitioner/get-practitioners', {
                  params: {
                    'language_id': languageIds.join(','),
                    'issue_id': issueIds.join(','),
                    'therapy_type_id': therapyTypeIds.join(','),
                    'insurance_company_id': insuranceCompanyIds.join(','),
                    'provider_id': this.selectedProviders.join(','),
                    'gender': this.selectedTherapistGender,
                    // 'min_price': this.range[0],
                    // 'max_price': this.range[1],
                    'name': this.therapistName,
                    // 'instant_booking': this.instantBooking,
                    // 'session_type': this.selectedSessionType,
                    // 'date': dateStr
                  }
                }).then(async (response) => { // использование стрелочной функции здесь
                    this.practitioners = [];
                    response.data.data.forEach(async (practitioner) => {
                      practitioner.available_hours = await this.getPractitionerAvailableHours(practitioner.practitioner_id);
                      this.practitioners.push(practitioner);
                      
                    });
                })
                
                this.$emit('setPractitioners', this.practitioners);
            } catch (error) {
                console.error('Ошибка при получении данных от API:', error)
            }
          },
          async getPractitionerAvailableHours(id){
            const response = await axios.get('/practitioner/'+id+'/next-available-sessions ');

            const currentDate = new Date();

            // 2. Извлекаем день и месяц из текущей даты
            const currentDay = currentDate.getDate();
            const currentMonth = currentDate.getMonth() + 1; // Месяцы в JavaScript начинаются с 0

            // 3. Фильтруем массив дат
            const filteredDates = response.data.free_datetime.filter(dateStr => {
              const dateParts = dateStr.split(" ")[0].split("-"); // Разбиваем строку даты на части
              const day = parseInt(dateParts[2], 10);
              const month = parseInt(dateParts[1], 10);

              return day === currentDay && month === currentMonth;
            });

            const availableTodayHours = filteredDates.map((date) => {
              let timeParts = date.split(" ")[1].split(":");
              timeParts.pop();

              return timeParts.join(':');
            })

            return availableTodayHours; 
          },
    }
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
  h5{
    margin-top: 40px;
  }

  h5:first-child{
    margin-top: 0px;
  }

  .gender-checkbox {
    position: relative;
    margin: 10px 0;
  }

  .gender-checkbox input[type="radio"] {
    display: none;
  }

  .price-number-paragraph{
    margin-bottom: 0px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .sign-field{
    background: #fff;
    height: 44px;
    border: 1px solid #fff;
    border-radius: 8px;
    padding: 10px 12px 10px 12px;
    line-height: 24px;
  }

  .vue-slider-dot-tooltip{
    display: none !important;
  }

  .find-therapist-block{
    /deep/ .dp__calendar_item {
      width: 40px;
      height: 34px;
      margin-left: 2px;
      margin-right: 2px;
    }

    /deep/ .dp__cell_inner {
      width: 100%;
    }

    /deep/ .dp__range_between {
      background-color: #43C9F3 !important;
      color: #fff;
      border: 1px solid #43C9F3;
      border-radius: 4px;
    }

    /deep/ .dp__range_start{
      background: #43C9F3;
      color: #fff;
      border-radius: 4px;
    }

    /deep/ .dp__range_end{
      background: #43C9F3;
      color: #fff;
      border-radius: 4px;
    }

    /deep/ .dp__date_hover_end:hover{
      background: #43C9F3;
      color: #fff;
      border-radius: 4px;
    }

    /deep/ .dp__today{
      border: none;
    }

    /deep/ .dp__date_hover_start:hover{
      background: #43C9F3;
      color: #fff;
      border-radius: 4px;
    }

    /deep/ .dp__menu{
      border-radius: 16px;
    }
    
    /deep/ .dp__icon{
      display: none;
    }

    /deep/ .dp__action_select{
      background: #00D2FF;
      border-radius: 16px;
      padding: 4px 12px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }
    
    /deep/ .dp__action_cancel{
      border: 1px solid #00D2FF;
      background: #fff;
      color: #00D2FF;
      border-radius: 16px;
      padding: 4px 12px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }
  }

  .not-selected-calendar{
    /deep/ .dp__input{
      color: #fff;
      background: transparent !important;
      font-size: 12px;
      padding: 4px 12px 4px 12px;
      border-radius: 15px;
      border: none;
    }
  }
  
  .selected-calendar{
    /deep/ .dp__input{
      color: #fff;
      background: #43C9F3;
      font-size: 12px;
      padding: 4px 12px 4px 12px;
      border-radius: 15px;
      min-width: min-content;
      max-width: 120px;
    }
  }
  
  .gender-checkbox label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
  }
  
  .gender-checkbox label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #B5BAC9;
    border-radius: 8px;
  }
  
  .gender-checkbox input[type="radio"]:checked + label:before {
    background-color: #fff;
    border-color: #13204E;
  }
  
  .gender-checkbox input[type="radio"]:checked + label:after {
    content: '';
    position: absolute;
    width:10px;
    height: 10px;
    left: 4px;
    top: 4px;
    color: white;
    font-size: 16px;
    background: #13204E;
    border-radius: 16px;
  }
   
  h5{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  .home-text{
    font-size: 16px;
    font-weight: 600;     
  }

  .chip-container{
    position: relative;
  }

  .dropdown-item{
    cursor: pointer;
  }

  .dropdown{
    position: absolute;
    z-index: 999;
    background: #fff;
    width: 104%;
    left: -2%;
    padding: 15px;
    border: 2px solid #F3F3F6;
    border-radius: 8px;
    height: 200px;
    overflow-y: scroll;
  }
  .dropdown-arrow{
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .container {
    padding: 60px 0px 60px;
  }

  .chip-close-button{
    cursor: pointer;
  }

  .dp__pointer{
    width: 100% !important;
    background: #fff !important;
  }

  .chip-list{
    background: #fff;
    padding: 8px 12px 8px 12px;
    min-height: 44px;
    border-radius: 8px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .chip{
    padding: 4px 12px 4px 12px;
    background-color: #43C9F3;
    display: flex;
    width: fit-content;
    align-items: center;
    font-size: 12px;
    height: 28px;
    border-radius: 16px;
    margin-right: 8px;
    margin-bottom: 3px;
  }

  .chip > p{
    margin-bottom: 0px;
    color: #fff;
    margin-right: 4px;
  }

  .toggle-switch {
    position: relative;
    width: 370px;
    height: 38px;
    background-color: #F3F3F6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    border-radius: 15px;
    border: 1px solid var(#E6E8ED);
  }

  .toggle-switch-internal {
    position: relative;
    width: 100%;
    height: 38px;
    background-color: #F3F3F6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    border-radius: 15px;
    border: 1px solid var(#E6E8ED);
  }
  
  .toggle-option {
    cursor: pointer;
    z-index: 2;
    width:50%;
    text-align: center;
    transition: all 0.3s ease;
  }
  
  .toggle-option.active span {
    font-weight: bold;
  }

  .type{
    background: #fff;
    padding: 16px;
    box-shadow: 0px 4px 8px 0px rgba(51, 83, 196, 0.08);
    border-radius: 16px;
    border-style: solid;
    border-width: 1px;
    border-color:  #43C9F3;
    margin-bottom: 24px;
    transition: 0.3s ease;
    cursor: pointer;
  }

  .unchoosed-type{
    border-color: #E6E8ED;
  }

  .type-title{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px;
    padding-top: 12;
    padding-bottom: 12;
  }

  .type-description{
    margin-bottom: 0px !important;
    line-height: 24px;
  }
  
  .toggle-slider {
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(50% - 2px);
    height: calc(100% - 4px);
    background-color: #fff;
    border-radius: 15px;
    transition: all 0.3s ease;
    z-index: 1;
    box-shadow: 0px 3px 8px 0px #182D420D;
  }
  
  .toggle-slider.know {
    transform: translateX(calc(100%));
  }

  .toggle-slider.face_to_face {
    transform: translateX(calc(100%));
  }
  
  .toggle-switch-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .type-description{
    margin-bottom: 32px;
  }

  .find-therapist-section{
    background-image: url('../../assets/images/home-background.svg');
    background-repeat: no-repeat;
    background-position: 90%;
    background-position-y: bottom;
  }

  .footer{
    background-color: #fff;
    margin-top: 0px;
  }
  .find-therapist-block {
    background-color: #fff;
    padding: 48px;
    border-radius: 16px;
  }

  .custom-checkbox{
    margin-bottom: 12px;
  }

  .custom-checkbox{
  margin-bottom: 12px;
}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox input[type="radio"] {
  display: none;
}

.custom-checkbox label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
}

.custom-checkbox label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #B5BAC9;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center center;
}
.custom-checkbox input[type="radio"]:checked + label:before,
.custom-checkbox input[type="checkbox"]:checked + label:before {
  background-color: #43C9F3;
  border-color: #43C9F3;
  background-image: url('../../assets/images/check-sign.svg');
}


/* Optional: Styles for hover and focus states */
.custom-checkbox input[type="radio"]:hover + label:before,
.custom-checkbox input[type="radio"]:focus + label:before
.custom-checkbox input[type="checkbox"]:hover + label:before,
.custom-checkbox input[type="checkbox"]:focus + label:before {
  border-color: #43C9F3;
}

// @media (max-width: 1390px) { 
//   .find-therapist-block{
//     padding: 48px 16px;
//   }
// }

// @media (max-width: 570px) { 
//   .filters-section{
//     width: 100%;
//   }

//   .find-therapist-block{
//     border-radius: 0px;
//     padding: 48px 16px;
//   }

//   .main-text {
//     text-align: left !important;
//     padding: 0px 16px;
//   }

//   .find-therapist-section{
//     background: #fff;
//   }
// }
</style>