import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    accessToken: null,
    refreshToken: null,
    user: {
      firstName: null,
      lastName: null,
      email: null,
      isLoggedIn: null
    },
    therapist: {
      id: null,
      firstName: null,
      lastName: null,
      email: null,
      isLoggedIn: null
    }
  },
  mutations: {
    setTokens(state, tokens) {
      state.accessToken = tokens.access;
      state.refreshToken = tokens.refresh;
    },
    setUserData(state, userData) {
      state.user.firstName = userData.firstName;
      state.user.lastName = userData.lastName;
      state.user.email = userData.email;
      state.user.isLoggedIn = userData.isLoggedIn;

      state.therapist.id = null;
      state.therapist.firstName = null;
      state.therapist.lastName = null;
      state.therapist.email = null;
      state.therapist.isLoggedIn = null;
    },
    setTherapistData(state, therapistData) {
      state.therapist.id = therapistData.id;
      state.therapist.firstName = therapistData.firstName;
      state.therapist.lastName = therapistData.lastName;
      state.therapist.email = therapistData.email;
      state.therapist.isLoggedIn = therapistData.isLoggedIn;

      state.user.firstName = null;
      state.user.lastName = null;
      state.user.email = null;
      state.user.isLoggedIn = null;
    },
    clearAllData(state){
      state.user.firstName = null;
      state.user.lastName = null;
      state.user.email = null;
      state.user.isLoggedIn = null;

      state.therapist.id = null;
      state.therapist.firstName = null;
      state.therapist.lastName = null;
      state.therapist.email = null;
      state.therapist.isLoggedIn = null;
    }
  },
  actions: {
    setTokens({ commit }, tokens) {
      commit('setTokens', tokens);
    },
    setUserData({ commit }, userData) {
      commit('setUserData', userData)
    },
    setTherapistData({ commit }, therapistData) {
      commit('setTherapistData', therapistData)
    },
    clearAllData({ commit }) {
      commit('clearAllData')
    }
  },
  plugins: [createPersistedState({ // Добавьте этот блок в ваш Store
    storage: window.sessionStorage,   // По умолчанию используется localStorage, но вы можете выбрать sessionStorage
    key: 'my-app-name' // имя ключа, под которым данные будут сохранены. По умолчанию это "vuex"
  })]
});