<template>
    <section class="container h-100 d-flex align-items-center">
        <div class="w-100 d-flex align-items-center flex-column" v-if="therapistData.length != 0">
            <h1 class="text-center fw-bold">Booking</h1>
            <div class="col-xl-6 col-lg-6 col-md-8 col-sm-8 col-xs-8 therapist-data">
                <div class="therapist-basic-description d-flex justify-content-between">
                    <div class="d-flex">
                        <img class="therapist-basic-description-image" alt="wedwed">
                        <div class="text-part">
                            <h4 class="therapist-name">{{therapistData.first_name}} {{therapistData.last_name}}</h4>
                            <p class="therapist_provider"><span v-for="provider_type in therapistData.provider_types" :key="provider_type.index">{{ provider_type }}</span></p>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div class="d-flex align-items-center">
                                <img src="../assets/images/price-icon-colored.svg" class="price-icon" alt="">
                                <p class="therapist-price">£{{therapistData.min_fee}}</p>
                            </div>
                            <p style="margin-bottom: 0px">Session cost</p>
                        </div>
                    </div>
                </div>
                <hr>
                <div>
                    <div class="d-flex">
                        <div class="d-flex align-items-center">
                            <img src="../assets/images/calender-bw.svg" alt="">
                            <p class="date-text">{{ formatDate(today) }}</p>
                        </div>
                        
                        <div class="d-flex align-items-center" style="margin-left: 16px">
                            <img src="../assets/images/clock-bw.svg" alt="">
                            <p class="date-text">{{ sessionTime }}</p>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="d-flex align-items-center">
                            <img src="../assets/images/online-icon.svg" alt="">
                            <p class="date-text">Online</p>
                        </div>
                        
                        <div class="d-flex align-items-center" style="margin-left: 16px">
                            <img src="../assets/images/individual-therapy.svg" alt="">
                            <p class="date-text">Individual Therapy</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-8 col-xs-8 d-flex flex-column align-items-center booking-button-wrapper">
                <button class="btn btn-primary" @click="createChat()">Start chat with therapist</button>
            </div>
        </div>
    </section>
</template>

 <script>
import axios from '@/axios';

export default{
    data(){
        return{
            therapistData: {},
            sessionTime: null,
            today: new Date(),
            customerId: null,
            therapistId: null,
            user: null,
        }
    },
    async mounted(){
        this.therapistData = await this.getProfileData(this.$route.query.practitionerId)
        // this.sessionTime = await this.$route.query.sessionTime;
    },
    methods: {
        formatDate(date) {
            const options = { weekday: 'long', day: 'numeric', month: 'long'  };
            return date.toLocaleDateString('en-US', options);
        },
        async getProfileData(id){
            const response = await axios.get('/practitioner/'+id+'/profile ', {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                },
            });
            return response.data;
        },
        // async bookSession(){
        //     try {
        //         if(this.$store.state.user.isLoggedIn){
        //             await axios.post('/appointment/create-session', {
        //                 practitioner_id: this.therapistData.practitioner_id,
        //                 fee: this.therapistData.min_fee,
        //                 booking_type: 'instant'
        //             }, 
        //             {
        //                 headers: {
        //                     Authorization: `Bearer ${this.$store.state.accessToken}`
        //                 }
        //             }).then(async (response) => { 
        //                 // this.createInstantBooking(response.data);
        //                 if(this.customerId != null){
        //                     this.payWithExistingCard(response.data);
        //                 }else{
        //                     this.$router.push({name:'add_card'});
        //                 }
                        
        //             })
        //         } else {
        //             this.$router.push('/sign-in');
        //         }
                
        //     } catch (error) {
        //         this.$router.push('/');
        //         console.error('Ошибка при получении данных от API:', error)
        //     }
        // },

        // async payWithExistingCard(sessionData){
        //     console.log(sessionData);
        //     console.log({
        //                 session_id: sessionData.appointment_id,
        //                 fee_amount: 120,
        //                 customer_id: this.customerId
        //             });
        //     try {
        //         await axios.post('payment/stripe/existing-card-payment', {
        //                 session_id: sessionData.appointment_id,
        //                 fee_amount: 120,
        //                 customer_id: this.customerId
        //             }, 
        //             {
        //                 headers: {
        //                     Authorization: `Bearer ${this.$store.state.accessToken}`
        //                 }
        //             }).then(() => { // использование стрелочной функции здесь
        //                 this.createInstantBooking(sessionData);
        //             })
        //     } catch (error) {
        //         this.$router.push('/');
        //         console.error('Ошибка при получении данных от API:', error)
        //     }
        // },
        // async createInstantBooking(sessionData){
        //     try{
        //         await axios.post('/appointment/instant-book', {
        //             'practitioner_id': this.therapistData.practitioner_id,
        //             'appointment_id': sessionData.appointment_id,
        //             'patient_id': sessionData.patient_id,
        //             'location_id': sessionData.location_id,
        //             'appointment_type': sessionData.appointment_type,
        //             'client_category_id': sessionData.client_category_id,
        //             'datetime': this.createDateTime(),
        //             'service_id': 3,
        //             'booking_type': "schedule",
        //         },
        //         {
        //             headers: {
        //                 Authorization: `Bearer ${this.$store.state.accessToken}`
        //             }
        //         }).then(async (response) => {
        //             console.log(response.data);
        //             // this.$router.push('/my-sessions')
        //         })
        //     } catch (error) {
        //         this.$router.push('/');
        //         console.error('Ошибка при получении данных от API:', error)
        //     }
            
        // },
        createDateTime() {
            const timeParts = this.sessionTime.split(':'); // Разбиваем время на часы и минуты

            // Получаем текущую дату
            const now = new Date();

            // Создаем новую дату, используя текущий год, месяц и день
            const dateTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), timeParts[0], timeParts[1]);
            
            return dateTime;
        },
    }
}
</script>

<style scoped>
    .searching-therapists{
        padding: 0px 32px 0px 32px;
        border-radius: 16px;
        margin-bottom: 32px;
        background-image: url('./../assets/images/searching-therapisits.svg');
        height: 240px;
        background-position: center;
        background-repeat: no-repeat;
    }

    .btn-primary {
        padding: 8px 0;
        border-radius: 22px;
        background: linear-gradient(46deg, #43C9F3 0%, #3CAEE0 100%);
        color: #fff;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 85%;
        margin-bottom: 24px;
    }
    .btn-primary img {
        margin: 0px 12px;
    }

    .no-therapists{
        padding: 0px 32px 0px 32px;
        border-radius: 16px;
        margin-bottom: 32px;
        background-image: url('./../assets/images/no-therapists.svg');
        height: 240px;
        background-position: center;
        background-repeat: no-repeat;
    }

    .therapist-data{
        border-radius: 16px;
        border: 1px solid var(--main-colors-dark-10, #E6E8ED);
        background: var(--main-colors-white, #FFF);

        /* Shadow/Small Dark */
        box-shadow: 0px 4px 8px 0px rgba(51, 83, 196, 0.08);
    }

    .therapist-basic-description-image {
        width: 48px;
        height: 48px;
    }

    .text-part{
        margin-left: 8px;
    }
    
    .therapist-name{
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    .therapist_provider{
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
    }

    .price-icon{
        width: 16px;
        height: 16px;
    }

    .therapist-price{
        margin-bottom: 0px;
    }

    .date-text{
        margin-bottom: 0px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin-left: 4px;
    }
</style>