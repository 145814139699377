<template>
  <header>
    <div class="container-fluid header-structure">
      <div class="header-content d-flex align-items-center justify-content-between">
        <div class="logo">
          <a href="/">
            <img src="../assets/images/main-logo.svg" alt="Logo" class="logo-image"/>
          </a>
        </div>
        <div class="nav-wrapper d-flex align-items-center" v-if="currentUrl != 'sign-in' && currentUrl != 'sign-up'">
          <nav class="nav-links d-none d-lg-flex">
            <a @click="redirectToSearchPage()">Find a Therapist or Psychiatrist</a>
          </nav>
          <div class="divider d-none d-lg-block"></div>
          <div class="auth-buttons d-none d-sm-flex" v-if="!isUserLoggedIn">
            <button class="auth-button"><a href="/sign-in">Log in</a></button>
            <button class="auth-button"><a href="/sign-up">Create an account</a></button>
          </div>
          <div class="auth-icons d-flex d-md-none d-lg-block" v-else>
            <a href="/chats">
              <img src="../assets/images/chat-icon.svg" alt="">
            </a>
            <div class="dropdown-wrapper">
              <img src="../assets/images/account-icon.svg" alt="" @click="toggleDropdown">
              <div class="dropdown" v-if="dropdownOpen">
                  <div class="user-data dropdown-user-data">
                    <p class="user-name fw-bold" style="margin-bottom: 0px"  @click="this.$router.push('/therapist/profile-view')">
                      {{ this.$store.state.user.firstName ?? this.$store.state.therapist.firstName }} {{ this.$store.state.user.lastName ?? this.$store.state.therapist.lastName }}</p>
                    <p class="user-email" style="margin-bottom: 12px">{{ this.$store.state.user.email ?? this.$store.state.therapist.email  }}</p>  
                  </div>
                  <!-- <li class="dropdown-menu-item">
                    <a href="/my-sessions">My Sessions</a>
                  </li> -->
                  <a v-if="this.$store.state.therapist.isLoggedIn == true" href="/therapist/personal-info?isEditing=true" class="dropdown-bottom">Edit profile</a>
                  <a v-if="this.$store.state.therapist.isLoggedIn == true" href="/therapist/dashboard" class="dropdown-bottom">Dashboard</a>
                  <p @click="logout" class="dropdown-bottom">Logout</p>
                  <a v-if="this.$store.state.therapist.isLoggedIn == true" href="/sign-up" class="dropdown-bottom">Are you a Patient?</a>
                  <a v-if="this.$store.state.therapist.isLoggedIn != true" href="/therapist/sign-in" class="dropdown-bottom">Are you a Therapist?</a>
              </div>
            </div>
          </div>
          <div class="burger-icon d-lg-none" @click="openModal">
            <img src="../assets/images/burger-icon.svg" alt="">
          </div>
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="modal-menu" v-if="isModalOpen">
        <div class="modal-header d-flex justify-content-end">
          <div class="close-icon" @click="closeModal">
            <img src="../assets/images/close-icon.svg" alt="">
          </div>
        </div>
        <div class="modal-body d-flex flex-column justify-content-between">
          <div v-if="this.$store.state.user.isLoggedIn"> 
            <div class="user-data">
              <p class="user-name fw-bold" style="margin-bottom: 0px">{{ this.$store.state.user.firstName }} {{ this.$store.state.user.lastName }}</p>
              <p class="user-email" style="margin-bottom: 12px">{{ this.$store.state.user.email }}</p>  
            </div>
            <nav class="nav-links">
              <ul>
                <li class="modal-menu-item">
                  <a @click="redirectToSearchPage()">Find a Therapist or Psychiatrist</a>
                </li>
                <li class="modal-menu-item">
                  <a href="/my-sessions">My Sessions</a>
                </li>
              </ul>
            </nav>
          </div>
          <div v-else-if="this.$store.state.therapist.isLoggedIn"> 
            <div class="user-data" @click="this.$router.push('/therapist/profile-view')">
              <p class="user-name fw-bold" style="margin-bottom: 0px">{{ this.$store.state.therapist.firstName }} {{ this.$store.state.therapist.lastName }}</p>
              <p class="user-email" style="margin-bottom: 12px">{{ this.$store.state.therapist.email }}</p>  
            </div>
            <nav class="nav-links">
              <ul>
                <li class="modal-menu-item">
                  <a @click="redirectToSearchPage()">Find a Therapist or Psychiatrist</a>
                </li>
                <li class="modal-menu-item">
                  <a href="/my-sessions">My Sessions</a>
                </li>
              </ul>
            </nav>
          </div>
          <div class="bottom-auth-block">
            <div class="auth-block" v-if="!this.$store.state.user.isLoggedIn">
              <h2 class="auth-title">Are you a Patient?</h2>
              <div class="auth-buttons">
                <button class="auth-button"><a href="/sign-in">Log in</a></button>
                <button class="auth-button"><a href="/sign-up">Create an account</a></button>
              </div>
            </div>
            <div class="footer-text-wrapper">
              <p class="footer-text">Are you a Therapist or Psychiatrist? <a href="/" style="color: #2F80ED; text-decoration: none;">Join us</a>  </p>
            </div>
          </div>
        </div>
      </div>
    </transition> 
  </header>
</template>

<script>
export default {
  data() {
    return {
      isUserLoggedIn: false, // Change this based on your authentication status
      isModalOpen: false, // Change this based on whether the modal is open or not
      dropdownOpen: false,
      currentUrl: ''
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    toggleDropdown() {
        this.dropdownOpen = !this.dropdownOpen;
    },
    logout() {
      this.$store.dispatch('clearAllData', {});

      window.location.replace('/');
    },
    redirectToSearchPage(){
        this.$router.push({name: 'found_therapists', query: { 
          therapistName: null, 
          selectedSessionType: "online", 
          selectedProviders: JSON.stringify([]),
          selectedTherapistGender: null,
          minPrice: 300,
          maxPrice: 1000,
          selectedInsuranceCompanies: JSON.stringify([]),
          selectedLanguages: JSON.stringify([]),
          instantBooking: true,
          selectedTherapyTypes: JSON.stringify([]),
          showIssuesDropdown: false,
          showInsuranceCompaniesDropdown: false,
          showLanguagesDropdown: false,
          showTherapyTypesDropdown: false,
          date: JSON.stringify(this.date),

          selectedIssues: JSON.stringify([])
        
        }})
      }
  },
  mounted(){
    console.log(this.$store.state.user);
    console.log(this.$store.state.therapist);

    if(this.$store.state.therapist.isLoggedIn != null){
      this.isUserLoggedIn = this.$store.state.therapist.isLoggedIn
    }else if(this.$store.state.user.isLoggedIn != null){
      this.isUserLoggedIn = this.$store.state.user.isLoggedIn
    }
    this.currentUrl = window.location.pathname.split("/").pop();
  }
};
</script>

<style scoped>
a{
  text-decoration: none;
}

.logo-image{
  width: 324px;
}

.bottom-auth-block{
  position: absolute;
  bottom: 0;
  width: 80%;
}

.container {
  display: flex;
  justify-content: space-between;
}
.divider {
  height: 30px;
  width: 1px;
  background-color: #E6E8ED; /* Change this to your preferred color */
  margin: 0 24px;
}
.modal-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 370px;
  background-color: #fff; /* Change this to your preferred color */
  z-index: 1000; /* This should be higher than the z-index of all other elements */
}

header{
  height: 80px !important;
}

.modal-header {
  height: 80px;
  padding: 24px;
}
.modal-body {
  padding: 0px 24px 24px 48px;
  height: calc(100% - 80px);
  position: relative;
  /* Add your styles for the modal content here */
}
.modal-menu-item {
  padding: 8px 16px;
  border-bottom: 1px solid #E6E8ED; /* Change this to your preferred color */
  list-style: none;
}

.dropdown-menu-item{
  border-bottom: 1px solid #E6E8ED; /* Change this to your preferred color */
  list-style: none;
  padding: 8px 16px 8px 16px;
  cursor: pointer;
}

.dropdown-menu-item > a {
  color: var(--font-dark-80, #3A466E);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.nav-links > ul {
  list-style: none;
  padding: 0px;
  margin-bottom: 0px;
  width: 100%;
}
.auth-block {
  border-top: 1px solid #E6E8ED; /* Change this to your preferred color */
  padding: 8px 0;
}
.auth-title {
  font-size: 14px;
  font-weight: 600;
  padding: 8px 0 4px;
}
.auth-buttons {
  display: flex;
  justify-content: space-between;
  padding: 8px 0px;
}

.auth-button > p > a,
.auth-button > a
{
  min-width: 85px;
  margin: 0px;
  color: #000;
}

.footer-text-wrapper{
  padding: 4px 0px;
}

.footer-text{
  margin: 0px;
  padding: 8px 0px;
}

.burger-icon{
  margin-left: 18px;
}

.slide-enter-active, .slide-leave-active {
  transition: transform .3s;
}
.slide-enter-from, .slide-leave-to {
  transform: translateX(100%);
}

.auth-icons{
  cursor: pointer;
}

.auth-icons img:first-child{
  margin-right: 20px;
}

.header-structure{
  padding: 24px 0px 24px 0px;
}

.user-data{
  border-bottom: 1px solid #E6E8ED;
}

.dropdown-wrapper {
    position: relative;
    display: inline-block; /* чтобы wrapper не занимал всю ширину */
}

.dropdown {
    position: absolute;
    position: absolute;
    top: 176%; /* чтобы разместить меню прямо под иконкой */
    left: -190px;
    background-color: #fff;
    border: 1px solid var(--main-colors-dark-15, #DADCE4);
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    width: 224px; /* или другой размер по вашему усмотрению */
    z-index: 1;
    padding: 12px 0px 0px 0px;
}

.dropdown-user-data{
  padding: 0px 12px 0px 12px;
}

.dropdown-bottom{
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--font-dark-60, #6B7492);
  margin-bottom: 0px;
  padding: 8px 16px 8px 16px;
}

@media (max-width: 768px) { 
    .auth-button > a {
      font-size: 12px;
    }
  }

@media (max-width: 560px) { 
  .logo-image{
    width: 243px;
  }
  .auth-icons{
    display: none !important;
  }
}

</style>
