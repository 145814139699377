<template>
    <section class="container h-100 d-flex align-items-center">
        
        <div class="w-100 d-flex align-items-center flex-column main-wrapper">
            <div class="w-100 content-block-wrapper">
                <div class="d-flex align-items-center">
                    <div class="back-arrow-block">
                        <a href="">
                            <img src="../assets/images/back-arrow-button.svg" alt="">
                        </a>
                    </div>
                    <div class="progress-container">
                        <div class="steps-container">
                            <div class="steps">
                                <div class="step"><span>1</span></div>
                                <img src="../assets/images/separation-dot.svg" alt="">
                                <div class="step active"><span>2</span></div>
                                <div class="d-flex flex-column align-items-center progress-content-block">
                                    <span>Professional info</span>
                                    <div class="progress-bar-wrapper">
                                        <div class="progress-bar" :style="{ width: progress + '%' }"></div>
                                    </div>
                                </div>
                                <img src="../assets/images/separation-dot.svg" alt="">
                                <div class="step"><span>3</span></div>
                            </div>
                        </div>
                        </div>
                        
                </div>
            </div>

            <div v-if="profileDataStep == 1" class="personal-info-wrapper-block">
                <h2>Select Therapy approaches</h2>
                <p>Choose the services you would like to offer.</p>

                <div v-for="(sortedApproahes, index) in approaches" :key="index">
                    <h5 class="value-title">{{ sortedApproahes[0].therapy_approach_name[0] }}</h5>

                    <div class="chips-block select-chips-block">
                        <div
                            v-for="(approach, index) in sortedApproahes"
                            :key="index"
                            class="chip"
                            :class='{ active: selectedApproaches.includes(approach.therapy_approach_id)}'
                        >
                                <p 
                                    role="button"
                                    style="color: #000" 
                                    @click='!selectedApproaches.includes(approach.therapy_approach_id) ? 
                                            selectedApproaches.push(approach.therapy_approach_id) : 
                                            null'
                                    >
                                    {{ approach.therapy_approach_name }}
                                </p>
                                
                            <img 
                                v-if='selectedApproaches.includes(approach.therapy_approach_id)' 
                                style="margin-left: 5px;"
                                class="chip-close-button" src="../assets/images/delete-chip-black.svg" 
                                @click='selectedApproaches.splice(selectedApproaches.indexOf(approach.therapy_approach_id), 1)' 
                                alt=""
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="profileDataStep == 2" class="personal-info-wrapper-block">
                <h2>Select issues you can treat</h2>
                <p>Choose the services you would like to offer.</p>

                <div v-for="(sortedIssues, index) in issues" :key="index">
                    <h5 class="value-title">{{ sortedIssues[0].issue_name[0] }}</h5>

                    <div class="chips-block select-chips-block">
                        <div
                            v-for="(issue, index) in sortedIssues"
                            :key="index"
                            class="chip"
                            :class="{ active: selectedIssues.includes(issue.issue_id) }"
                        >
                                <p 
                                    role="button" 
                                    style="color: #000"
                                    @click="!selectedIssues.includes(issue.issue_id) ? selectedIssues.push(issue.issue_id) : null">
                                    {{ issue.issue_name }}
                                </p>

                            <img 
                                v-if="selectedIssues.includes(issue.issue_id)"
                                style="margin-left: 5px;" 
                                class="chip-close-button z-5" src="../assets/images/delete-chip-black.svg" 
                                @click="selectedIssues.splice(selectedIssues.indexOf(issue.issue_id), 1)" 
                                alt=""
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="profileDataStep == 3" class="personal-info-wrapper-block">
                <h2>Great! Now add a photo</h2>
                <p>It's preferable to add a picture with a light  background taken in daylight. Smile, we have noticed that people are more likely to choose smiling Therapists. </p>

                <div class="w-100 d-flex" style="height: 206px">
                    <div class="w-50">
                        <div style="width: fit-content" class="d-flex flex-column align-items-center">
                            <img src="../assets/images/photo-sample.png" alt="">
                            <p>Photo example</p>
                        </div>
                    </div>
                    <div class="w-50 d-flex align-items-center justify-content-center">
                        <label role="button" for="photo-upload">
                            <img src="../assets/images/add-a-photo.svg" alt="">
                        </label>
                        <input @change="handlePhotoUpload" v-show="false" type="file" id="photo-upload">
                    </div>
                </div>
            </div>

            <div v-if="profileDataStep == 4" class="personal-info-wrapper-block">
                <h2>Bio</h2>
                <p>Introduce yourself, describing your skills, experience, and the issues you can work with. Minimum 500 words.</p>

                <a @click="showModal = true" style="color: #2F80ED">Read guidelines</a>

                <h3 class="sub-title">Introduce yourself</h3>

                <textarea v-model="therapistData.bio" class="bio-field" name="" id="" cols="30" rows="10" placeholder="Enter your text"></textarea>
            </div>

            <div v-if="profileDataStep == 5" class="personal-info-wrapper-block">
                <h2>How do you wish to add your practitioner introduction video to your profile:</h2>

                <div class="therapist-genders">
                    <div class="custom-checkbox">
                        <input class="gender-input" type="radio" id="radio_upload" v-model="selectedVideoType" value='0'>
                        <label for="radio_upload">I will upload my own self filmed video</label>
                    </div>
                    <div class="custom-checkbox">
                        <input class="gender-input" type="radio" id="radio_over_zoom" v-model="selectedVideoType" value='1'>
                        <label for="radio_over_zoom">I would like you to produce the video for me over a zoom call</label>
                    </div>
                    <div class="custom-checkbox">
                        <input class="gender-input" type="radio" id="radio_from_cloud_storage" v-model="selectedVideoType" value='2'>
                        <label for="radio_from_cloud_storage">I will add a link with my own self-filmed version from cloud storage</label>
                    </div>
                    <div class="custom-checkbox">
                        <input class="gender-input" type="radio" id="radio_no_video" v-model="selectedVideoType" value="3">
                        <label for="radio_no_video">I don't wish to have a video on my profile</label>
                    </div>
                </div>

                <p style="font-size: 10px">Please note: Therapists without videos on their profile tend to get less booking, remember even a video as short as 60 seconds is better than no video at all.</p>
            </div>

            <div v-if="profileDataStep == 5 && selectedVideoType == 0" class="personal-info-wrapper-block">
                <div class="d-flex flex-column align-items-center w-100">
                    <label role="button" for="image-upload" class="upload-document-button d-flex align-items-center">Upload video</label>
                    
                    <input v-show="false" type="file" id="image-upload" @change="handleVideoUpload"/>
                </div>
            </div>

            <div v-if="profileDataStep == 5 && selectedVideoType == 1" class="personal-info-wrapper-block">
                <h2 style="margin-bottom: 16px">Professional Therapist introduction video</h2>

                <div>
                    <div class="d-flex align-items-start" style="margin-bottom: 24px">
                        <img src="../assets/images/dot.svg" alt="" style="margin-right: 4px; margin-top: 7px">
                        <p style="margin-bottom: 0px">We'll meet you at a specific time on Zoom.</p>
                    </div>
                    <div class="d-flex align-items-start" style="margin-bottom: 24px"> 
                        <img src="../assets/images/dot.svg" alt="" style="margin-right: 4px; margin-top: 7px">
                        <p style="margin-bottom: 0px">We'll ask you specific questions about your work.</p>
                    </div>
                    <div class="d-flex align-items-start" style="margin-bottom: 24px">   
                        <img src="../assets/images/dot.svg" alt="" style="margin-right: 4px; margin-top: 7px">
                        <p style="margin-bottom: 0px">We'll edit the recorded video to create a compelling video to display to clients.</p>
                    </div>
                </div>

                <p class="text-center">Fee is £60</p>
                <p class="text-center" style="font-weight: 600;">The current special offer of £60 reduces to £10 for the next 30 days only.</p>

                <div class="w-100 d-flex justify-content-center">
                    <button class="next-step-button">£10 Produce the video</button>
                </div>
            </div>

            <div v-if="profileDataStep == 5 && selectedVideoType == 2" class="personal-info-wrapper-block">
                <h2 style="margin-bottom: 16px">Add youtube link</h2>

                <input v-model="youtubeLink" type="text" class="sign-field w-100"  placeholder="Enter youtube link">
            </div>

            <div v-if="profileDataStep == 6" class="personal-info-wrapper-block">
                <h2>Professional insurance</h2>
                <p>Do you have professional indemnity insurance to cover you for the work you are carrying out i.e. this protects you against any litigation?</p>

                <p>Not to be confused with health insurance companies that you are registered with.</p>

                <p>Do you have professional insurance?</p>
                <div class="therapist-genders">
                    <div class="gender-checkbox">
                        <input class="gender-input" type="radio" id="checkbox_yes" v-model="selectProfessionalInsuranceStatus" value='yes'>
                        <label for="checkbox_yes">Yes</label>
                    </div>
                    <div class="gender-checkbox">
                        <input class="gender-input" type="radio" id="checkbox_no" v-model="selectProfessionalInsuranceStatus" value="no">
                        <label for="checkbox_no">No (A note will be added to you profile which may discourage people from choosing to book with you)</label>
                    </div>
                </div>

                <div class="w-100 d-flex justify-content-center" style="margin-top: 24px">
                    <label role="button" for="certificate-upload" class="upload-document-button d-flex align-items-center"><img src="../assets/images/plus.svg" alt="">Upload insurance certificate</label>
                    
                    <input v-show="false" type="file" id="certificate-upload" @change="handleCertificateFileUpload"/>
                </div>
            </div>

            <button class="next-step-button" @click="goToNextStep" style="margin-bottom: 80px;">Next step</button>
        </div>

        <ModalComponent v-if="showModal" :isOpen="showModal" @close="showModal = false"/>
    </section>
</template>

<script>
import axios from '@/axios';
import BioGuidelineModalComponent from './Components/BioGuidelineModalComponent.vue'

    export default{
        data(){
            return {
                showModal: false,
                profileDataStep: 1,
                progress: 60,
                selectedApproaches: [],
                rawApproaches: [],
                approaches: null,
                selectedIssues: [],
                issues: [],
                licensingBodies: [],
                licenseFile: null,
                documentFile: null,
                photo: null,
                video: null,
                selectedVideoType: null,
                youtubeLink: null,
                selectProfessionalInsuranceStatus: null,
                certificate: null,
                therapistData: null,
            }
        },
        components: {
            ModalComponent: BioGuidelineModalComponent
        },
        async mounted(){
            if (typeof this.$route.query.isEditing !== 'undefined') {
                this.isEditing = this.$route.query.isEditing;
            }

            await this.getTherapistData();
            await axios.get('/therapist/get-therapy-approach-list', {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).then((response) => {
                this.approaches = this.sortAndGroupApproaches(response.data);
            });
            await axios.get('/therapist/get-therapy-approaches', {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).then((response) => {
                response.data.forEach((therapyApproach) => {
                    this.selectedApproaches.push(therapyApproach.therapy_approach_id);
                })
            });
            await axios.get('/therapist/get-issue-list', {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).then((response) => {
                this.issues = this.sortAndGroupIssues(response.data);
            });

            await axios.get('/therapist/get-issues', {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).then((response) => {
                response.data.forEach((issue) => {
                    this.selectedIssues.push(issue.issue_id);
                })
            });
        },
        methods: {
            async goToNextStep(){
                switch (this.profileDataStep) {
                    case 1:
                        await this.firstStepProcess()
                        break;
                    case 2: 
                        await this.secondStepProcess()
                        break;
                    case 3: 
                        await this.thirdStepProcess()
                        break;
                    case 4: 
                        await this.fourthStepProcess()
                        break;
                    case 5: 
                        await this.fifthStepProcess()
                        break;
                    case 6: 
                        await this.sixthStepProcess()
                        if(this.isEditing){
                            this.$router.push('/therapist/service-info?isEditing=true');
                        }else{
                            this.$router.push('/therapist/service-info');
                        }
                        break;
                    default:
                        break;
                }
                this.getTherapistData();
                this.profileDataStep += 1;
            },
            async getTherapistData(){
                await axios.get(`/therapist/${this.$store.state.therapist.id}/get-profile`).then(async (response) => {
                    this.therapistData = await response.data;
                    this.therapistData.bio = this.therapistData.about;
                })
            },
            async firstStepProcess(){
                await axios.post('/therapist/add-therapy-approach-list', {
                    therapy_approach_list: this.selectedApproaches
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`
                    }
                });
            },
            async secondStepProcess(){
                await axios.post('/therapist/add-issue-list', {
                    issue_list: this.selectedIssues
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`
                    }
                });
            },
            async thirdStepProcess() {
                let formData = new FormData(); // Создаем экземпляр FormData
                formData.append('image', this.photo); // Добавляем файл в FormData. 'file' - это ключ, по которому сервер ожидает получить файл

                try {
                    await axios.post('/therapist/upload-profile-image', formData, {
                        headers: {
                            'Authorization': `Bearer ${this.$store.state.accessToken}`,
                            'Content-Type': 'multipart/form-data' // Этот заголовок очень важен при отправке файлов
                        }
                    });
                } catch (error) {
                    // Обработка возможных ошибок при отправке
                    console.error('Ошибка при загрузке файла:', error);
                }
            },
            async uploadTherapistVideo(){
                let formData = new FormData(); // Создаем экземпляр FormData
                formData.append('video', this.video); // Добавляем файл в FormData. 'file' - это ключ, по которому сервер ожидает получить файл

                try {
                    await axios.post('/therapist/upload-profile-video', formData, {
                        headers: {
                            'Authorization': `Bearer ${this.$store.state.accessToken}`,
                            'Content-Type': 'multipart/form-data' // Этот заголовок очень важен при отправке файлов
                        }
                    });
                } catch (error) {
                    // Обработка возможных ошибок при отправке
                    console.error('Ошибка при загрузке файла:', error);
                }
            },
            async sixthStepProcess() {
                let formData = new FormData(); // Создаем экземпляр FormData
                formData.append('insurance_certificate', this.certificate); // Добавляем файл в FormData. 'file' - это ключ, по которому сервер ожидает получить файл

                try {
                    await axios.post('/therapist/upload-insurance-certificate', formData, {
                        headers: {
                            'Authorization': `Bearer ${this.$store.state.accessToken}`,
                            'Content-Type': 'multipart/form-data' // Этот заголовок очень важен при отправке файлов
                        }
                    });
                } catch (error) {
                    // Обработка возможных ошибок при отправке
                    console.error('Ошибка при загрузке файла:', error);
                }
            },
            async uploadTherapistData(){
                await axios.put('/therapist/update-profile', {
                    ...this.therapistData
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`
                    }
                });
            },
            async fourthStepProcess() {
                await this.uploadTherapistData();
            },
            async fifthStepProcess() {
                switch (this.selectedVideoType) {
                    case '0':
                        await this.uploadTherapistVideo()
                        break;
                    case '1': 
                        break;
                    case '2': 
                        this.therapistData.video_url_link = this.youtubeLink
                        await this.uploadTherapistData();
                        break;
                    default:
                        break;
                }
            },
            sortAndGroupApproaches(approaches) {
                const sortedApproaches = approaches.sort((a, b) => a.therapy_approach_name.localeCompare(b.therapy_approach_name));

                const groupedApproaches = sortedApproaches.reduce((acc, approach) => {
                    const firstLetter = approach.therapy_approach_name[0];
                    if (!acc[firstLetter]) {
                        acc[firstLetter] = []; 
                    }
                    acc[firstLetter].push(approach); 
                    return acc;
                }, {});

                return groupedApproaches;
            },
            sortAndGroupIssues(issues) {
                const sortedIssues = issues.sort((a, b) => a.issue_name.localeCompare(b.issue_name));

                const groupedIssues = sortedIssues.reduce((acc, issue) => {
                    const firstLetter = issue.issue_name[0]; 
                    if (!acc[firstLetter]) {
                        acc[firstLetter] = []; 
                    }
                    acc[firstLetter].push(issue); 
                    return acc;
                }, {});

                return groupedIssues;
            },
            handlePhotoUpload(event){
                this.photo = event.target.files[0]; 
            },
            handleVideoUpload(event){
                this.video = event.target.files[0]; 
            },
            handleCertificateFileUpload(event){
                this.certificate = event.target.files[0];
            }
        }
    }
</script>

<style scoped>

.gender-checkbox {
    position: relative;
    margin: 10px 0;
  }

.gender-checkbox input[type="radio"] {
    display: none;
}

.gender-checkbox label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
  }
  
  .gender-checkbox label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #B5BAC9;
    border-radius: 8px;
  }
  
  .gender-checkbox input[type="radio"]:checked + label:before {
    background-color: #fff;
    border-color: #13204E;
  }
  
  .gender-checkbox input[type="radio"]:checked + label:after {
    content: '';
    position: absolute;
    width:10px;
    height: 10px;
    left: 4px;
    top: 4px;
    color: white;
    font-size: 16px;
    background: #13204E;
    border-radius: 16px;
  }

.sub-title{
    font-size: 16px;
    font-weight: 600;
    margin-top: 32px;
}

.bio-field{
    background: #fff;
    border: 1px solid #E6E8ED;
    border-radius: 8px;
    width: 100%;
    padding: 10px 12px;
}
.personal-info-wrapper-block{
    width: 612px;
    padding: 48px;
    background: #fff;
    border-radius: 16px;
    margin-bottom: 32px;
    margin-top: 32px;
    border: 1px solid #E6E8ED;
}

.personal-info-wrapper-block > h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
}

.content-block-wrapper{
    padding: 18px 24px 18px 24px;
    background: #fff;
    border-radius: 16px;
    margin-bottom: 32px;
    border: 1px solid #E6E8ED;
    margin-top: 24px;
}

.next-step-button{
    width: 311px;
    margin-top: 18px;
}

.sign-field{
    background: #F3F3F6;
    height: 44px;
    border: 1px solid #E6E8ED;
    border-radius: 8px;
    padding: 10px 12px 10px 12px;
    line-height: 24px;
}

.phone-codes-select{
    width: 152px;
    background: #F3F3F6;
    height: 44px;
    border: 1px solid #E6E8ED;
    border-radius: 8px;
    padding: 10px 40px 10px 12px;
    -webkit-appearance: none; /* Удалить стандартную стрелку в WebKit-браузерах */
    -moz-appearance: none;    /* Удалить стандартную стрелку в Firefox */
    appearance: none;         /* Стандартный способ, который может не поддерживаться всеми браузерами */
    background-image: url('../assets/images/arrow-down.svg'); /* Путь к вашей кастомной стрелке */
    background-repeat: no-repeat;
    background-position: right 16px center; /* Сдвигаем стрелку на 10px от правого края */
}

.timezones-select{
    width: 100%;
    background: #F3F3F6;
    height: 44px;
    border: 1px solid #E6E8ED;
    border-radius: 8px;
    padding: 10px 40px 10px 12px;
    -webkit-appearance: none; /* Удалить стандартную стрелку в WebKit-браузерах */
    -moz-appearance: none;    /* Удалить стандартную стрелку в Firefox */
    appearance: none;         /* Стандартный способ, который может не поддерживаться всеми браузерами */
    background-image: url('../assets/images/arrow-down.svg'); /* Путь к вашей кастомной стрелке */
    background-repeat: no-repeat;
    background-position: right 16px center; /* Сдвигаем стрелку на 10px от правого края */
}

.sign-field{
    background: #F3F3F6;
    height: 44px;
    border: 1px solid #E6E8ED;
    border-radius: 8px;
    padding: 10px 12px 10px 12px;
    line-height: 24px;
    width: 350px;
}

.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 64px);
}

.progress-bar {
  height: 100%;
  background-color: #43C9F3;
  border-radius: 5px 0 0 5px; /* Rounded corners on the left side */
}

.progress-bar-wrapper {
  height: 4px;
  background-color: #E6E8ED;
  border-radius: 5px 0 0 5px; /* Rounded corners on the left side */
  width: 127px;
}

.progress-content-block > span {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; 
    margin-bottom: 4px;
}

.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 371px;
}

.steps img {
    width: 6px;
    height: 6px;
}

.step {
  display: flex;
  align-items: center;
}

.step span {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 600;
  background-color: transparent;
  color: var(--font-dark-80, #3A466E);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #E6E8ED;
}

.back-arrow-block{
    background: #F3F3F6;
    border: 1px solid #E6E8ED;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
}

.chips-block{
    display: flex;
    flex-wrap: wrap;
}

.personal-info-block-part{
    margin-bottom: 32px;
}

.chip{
    padding: 4px 12px 4px 12px;
    background-color: #fff;
    display: flex;
    width: fit-content;
    align-items: center;
    font-size: 12px;
    height: 28px;
    color: #000;
    border-radius: 16px;
    margin-right: 12px;
    margin-bottom: 3px;
    font-weight: bold;
  }

.chip.active{
    background-color: #43C9F3;
    color: #fff;
}

.chip > p{
    margin-bottom: 0px;
}

.block-part-title{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 12px;
}

.block-part-title > span 
{
    font-weight: 500;
}

.step.active span {
  background-color: #43C9F3; /* Active step color */
}

.value-title{
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    color: #3A466E;
    margin-bottom: 8px;
}

.select-chips-block{
    margin-bottom: 32px;
}

.upload-document-button{
    border: 1px solid #000;
    padding: 8px 73px;
    background: #fff;
    font-size: 16px;
    font-weight: 600;
    border-radius: 50px;
}

.custom-checkbox{
  margin-bottom: 12px;
}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox input[type="radio"] {
  display: none;
}

.custom-checkbox label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
}

.custom-checkbox label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #B5BAC9;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center center;
}
.custom-checkbox input[type="radio"]:checked + label:before,
.custom-checkbox input[type="checkbox"]:checked + label:before {
  background-color: #43C9F3;
  border-color: #43C9F3;
  background-image: url('../assets/images/check-sign.svg');
}


/* Optional: Styles for hover and focus states */
.custom-checkbox input[type="radio"]:hover + label:before,
.custom-checkbox input[type="radio"]:focus + label:before
.custom-checkbox input[type="checkbox"]:hover + label:before,
.custom-checkbox input[type="checkbox"]:focus + label:before {
  border-color: #43C9F3;
}

@media (max-width: 612px) { 
  .personal-info-wrapper-block{
    width: calc(100% - 24px);
    padding: 24px;
  }
}
</style>