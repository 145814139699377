<template>
    <div v-if="isOpen" class="modal-overlay d-flex w-100 justify-content-end">
      <div class="modal-structure d-flex flex-column align-items-center">
        <div class="modal-header d-flex justify-content-end w-100">
          <img class="close-icon" src="../../assets/images/close-icon.svg" @click="closeModal" alt="">
        </div>
        
        <div class="modal-body w-100 d-flex flex-column align-items-center">
            <div class="w-100">
              <SearchFiltersComponent :onceUsedForMobile="onceUsedForMobile" :key="'modal-filter'" @set-practitioners="handlePractitionersUpdate"></SearchFiltersComponent>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import 'vue-slider-component/theme/default.css'
  import '@vuepic/vue-datepicker/dist/main.css';
  import SearchFiltersComponent from './SearchFiltersComponent.vue';
  
  export default {
    components: {
      SearchFiltersComponent
    },
    props: {
      isOpen: {
        type: Boolean,
        default: false
      },
      onceUsedForMobile: {
        type: Boolean,
        default: false
      }
    },
    data(){
      return {
      }
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
      async handlePractitionersUpdate(practitioners){
        this.$emit('setPractitioners', practitioners);
        this.closeModal();
      }
    }
  }
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }
  
  .modal-structure {
    background-color: #FAFCFF;
    padding: 20px;
   
    height: 100%;
    padding: 24px 24px 0px 24px;
    overflow-y: scroll;
    width: 372px;
  }
  
  .modal-header {
    margin-bottom: 22px;
  }
  
  .close-icon{
    cursor: pointer;
  }

  .find-therapist-block{
    padding: 0px 0px 24px 0px;
    background: transparent;
  }

    
  </style>