<template>
    <section class="container h-100 align-items-center d-flex flex-column justify-content-center">
        <div class="w-100 h-100 d-flex justify-content-center align-items-center flex-column main-wrapper" v-if="dialogs == null || dialogs.length == 0">
            <h1 style="margin-bottom: 12px;" class="text-center fw-bold">Chats</h1>
            <p style="margin-bottom: 24px;">Messages with your Therapist can be found here. All data is 100% confidentially.</p>
            <h2 style="margin-bottom: 38px;">Sorry, but you don’t have any chats to show.</h2>
            <img src="../assets/images/no-therapists.svg" alt="">
        </div>
        <div class="w-100 d-flex align-items-center flex-column main-wrapper" v-else>
            <h1 class="text-center fw-bold">Chats</h1>
            <div @click="$router.push({
                    name: 'chat_page', 
                    query: {
                      dialogId: dialog._id, 
                    }
                  })" class="steps-wrapper-block" 
                  v-for="dialog in dialogs" :key="dialog._id"
                  style="cursor: pointer;"
            >
                <div class="d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                        <div class="contact-image-block">
                            <img :src="dialog.occupant.main_image" alt="" class="img-fluid rounded-circle contact-image">
                        </div>
                        <div>
                            <h2 class="therapist_name" style="margin-bottom: 0px;" >{{dialog.occupant.full_name}}</h2>
                            <div v-if="$store.state.therapist.id == null" class="d-flex">   
                                <p  class="therapist_provider_type" style="margin-bottom: 0px; margin-right: 5px;" 
                                    v-for="(providerType, index) in dialog.occupant.provider_types" :key="index"
                                >{{providerType}}</p>
                            </div>
                            <span style="font-size: 18px; font-weight: 400;">{{dialog.last_message}}</span>
                        </div>
                    </div>
                    <div class="d-flex flex-column justify-content-between align-items-end">
                        <div style="font-size: 14px; ">{{convertToTimeString(dialog.last_message_date_sent)}}</div>
                        <div class="chat-messages-quantity-block" v-if="dialog.unread_messages_count > 0">
                            <span style="color: #fff; font-size: 10px; position: absolute;">{{ dialog.unread_messages_count }}</span>
                        </div>
                    </div>
                </div>
            </div> 
        </div> 
    </section>
</template>

<script>
import connectyCube from "../config/connectycube-config"
import axios from '@/axios';

export default{
    data(){
        return {
            user: null,
            dialogs: [],
        }
    },
    methods: {
        connectUser() {
            connectyCube.createSession()
                .then(() => {
                    return connectyCube.login({
                        id: this.user.cc_id,
                        password: this.user.cc_password,
                        login: this.user.cc_username
                    });
                })
                .then(async () => {
                    await this.getChats();
                })
                .catch((error) => {
                console.error('Ошибка авторизации', error);
            });
        },
        async getMyUserData(){
            if(this.$store.state.user.firstName != null){
                await axios.get('client/profile/show ', {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`
                    },
                }).then((response) => {
                    this.user = response.data;
                });
            } else if (this.$store.state.therapist.id != null) {
                await axios.get('therapist/profile/', {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`
                    },
                }).then((response) => {
                    this.user = response.data;
                });
            }
        },
        async getChats(){
            
            const retrievedDialogs = await connectyCube.chat.dialog.list({});

            this.dialogs = retrievedDialogs.items;
            this.dialogs.forEach(async (dialog) => {
                
                if(this.$store.state.therapist.id != null){
                    dialog.occupant = JSON.parse(dialog.extensions.client);
                } else {
                    dialog.occupant = JSON.parse(dialog.extensions.practitioner);
                }
            });
        },
        async getPractitionerProfile(practitionerCcId){
            const response = await axios.get(`client/${practitionerCcId}/get-chat-profile`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                },
            });

            return response.data;
        },
        convertToTimeString(timestamp) {
            // Создание объекта Date из Unix timestamp (в секундах)
            // Умножение на 1000, потому что JavaScript ожидает миллисекунды
            if(timestamp === null) {
                return null;
            }

            const date = new Date(timestamp * 1000);

            // Получение часов и минут
            const hours = date.getHours();
            const minutes = date.getMinutes();

            // Форматирование часов и минут для вывода в формате HH:MM
            // Добавление ведущего нуля, если число меньше 10
            const formattedHours = hours.toString().padStart(2, '0');
            const formattedMinutes = minutes.toString().padStart(2, '0');

            // Возвращение конечной строки формата "часы:минуты"
            return `${formattedHours}:${formattedMinutes}`;
        },
    },
    async mounted(){
        await this.getMyUserData();
        await this.connectUser();
    }
}
</script>

<style scoped>
.steps-wrapper-block{
    width: 612px;
    padding: 20px;
    background: #fff;
    border-radius: 16px;
    margin-bottom: 12px;
}

.contact-image{
    width: 64px;
    height: 64px;
    border: 1px solid #E6E8ED;
}

.contact-image-block{
    margin-right: 24px;
}

.chat-messages-quantity-block{
    width: 16px; 
    height: 16px; 
    background: #F75384; 
    border-radius: 16px; 
    position: relative; 
    display: flex; 
    justify-content: center; 
    align-items: center;
}
</style>