<template>
    <div class="container-fluid overflow-hidden">

        <h1 style="font-weight: 600; margin-top: 80px">Complete profile</h1>
        <p>Your profile isn’t completed yet. You must connect your Stripe account and set up your schedule.</p>

        <div class="w-100">
            <div class="row flex-nowrap overflow-auto hide-scrollbar">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card d-flex align-items-center" style="padding-top: 48px; padding-bottom: 48px">
                        <img src="../assets/images/stripe-block.svg" alt="">
                        <h2 style="font-weight: 600;">Stripe account</h2>
                        <p>Set up your Stripe account to get paid.</p>
                        <button class="update-button" @click="$router.push('/therapist/add-card')">Update</button>
                    </div>
                </div>
                <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-7 col-xs-12">
                    <div class="card d-flex align-items-center" style="padding-top: 48px; padding-bottom: 48px">
                        <img src="../assets/images/schedule-icon.svg" alt="">
                        <h2 style="font-weight: 600;">Setup schedule</h2>
                        <p>So that Patients know your availability.</p>
                        <button class="update-button" @click="this.$router.push('/therapist/calendar')">Update</button>
                    </div>
                </div> -->
            </div>
        </div>

            <section class="home-section therapists-section py-5">
                <div class="meet-therapists">
                <div class="meet-therapists-content">
                    <div class="meet-therapists-content-text-wrapper">
                        <h2 class="meet-therapists-heading">Dashboard</h2>
                        <p class="meet-therapists-content-text">You will find the analytics from the sessions with your patients here.</p>
                    </div>
                    <button class="find-more-button" @click="this.$router.push('/therapist/dashboard')">Find out more</button>
                </div>
                </div>
            </section>
    </div>
</template>

<style scoped>
    .update-button{
        background: #43C9F3;
        border: 1px solid #43C9F3;
        color: #000;
        padding: 8px 24px 8px 24px;
        border-radius: 50px;
        font-weight: bold;
    }
    .therapists-section{
        padding-top: 20px !important;
    }
    .meet-therapists{
        background: linear-gradient(46deg, #43C9F3 0%, #3CAEE0 100%);
        width: 100%;
        border-radius: 16px;
    }

    .meet-therapists-heading{
        width: 100%;
        font-size: 38px;
        font-weight: 600;
        margin-bottom: 8px;
    }
    .meet-therapists-content{
        background-image: url('../assets/images/home-therapists-section.svg');
        background-repeat: no-repeat;
        background-position: 98%;
        background-position-y: 100%;
        background-size: 16%;
        padding: 48px;
        width: 100%;
        height: 100%;
    }

    .meet-therapists-content-text-wrapper{
        margin-bottom: 32px;
        width: 65%
    }

    .meet-therapists-content-text{
        width: 100%;
        font-size: 20px;
        font-weight: 400;
    }

    .find-more-button{
        border-radius: 22px;
        padding: 8px 24px;
        border: 1px solid var(--font-dark-80, #3A466E);
        background: var(--main-colors-white, #FFF);
        font-weight: 600;
    }
</style>

