<template>
    <section class="container h-100">
        <div class="breadcrumbs d-flex">
            <div class="breadcrumb-element d-flex"><img src="../assets/images/home-icon.svg" alt=""><a href="/">Home</a></div>
            <img src="../assets/images/arrow-right.svg" alt="">
            <div class="breadcrumb-element d-flex" @click="$router.push('/get-help')">Get help right now</div>
            <img src="../assets/images/arrow-right.svg" alt="">
            <div class="breadcrumb-element d-flex">Booking</div>
        </div>
        
        <section class="h-auto d-flex flex-column justify-content-center">
            <section class="w-100 type-section d-flex flex-column align-items-center" v-if="isSearching">
                <div class="col-xl-4 col-lg-6 col-md-8 col-sm-8 col-xs-8 searching-therapists">
                </div>

                <h2 class="fw-bold text-center">Please wait, we are searching Therapist for you...</h2>
                
            </section>

            <section v-if="isTherapistFound" class="w-100 d-flex flex-column align-items-center ">
                <h1 class="fw-bold text-center" >Your Therapist</h1>
                <p class="text-center type-description">We found a Therapist for your case. You can start a consultation right now.</p>

                <div class="col-xl-6 col-lg-6 col-md-8 col-sm-8 col-xs-8 booking-data">
                    <div class="therapist-basic-description d-flex">
                        <img :src="therapistData.main_image" alt="">
                        <div class="text-part">
                            <h4 class="therapist-name">{{therapistData.first_name}} {{therapistData.last_name}}</h4>
                            <p>{{therapistData.provider_type}}</p>
                            <p>{{therapistData.years_of_experience}} years of experience</p>
                        </div>
                    </div>
                    <hr>
                    <h4 class="therapist-specialization-title">Therapist specialization:</h4>
                    <div class="d-flex justify-content-start flex-wrap">
                        <span class="therapist-specialization" v-for="issue in therapistData.issue" :key="issue.issue_id">{{issue.issue_name}}</span> 
                    </div>
                </div>

                <div class="col-xl-4 col-lg-6 col-md-8 col-sm-8 col-xs-8 d-flex flex-column align-items-center booking-button-wrapper">
                    <button class="btn btn-primary" @click="bookSession()">£25<img src="../assets/images/circle.svg" alt="">Pay</button>
                </div>
            </section>
            

            <section class="w-100 type-section d-flex flex-column align-items-center" v-if="noAvailableTherapists">
                <h1 class="fw-bold text-center">Sorry, right now all our Therapists are busy</h1>
                <p class="text-center type-description">You will get a refund for this session in the next 24 hours. At now you can schedule an appointment with one of our Therapists who will be able to see you very soon. </p>

                <div class="col-xl-4 col-lg-6 col-md-8 col-sm-8 col-xs-8 no-therapists">
                </div>

                <div class="col-xl-4 col-lg-6 col-md-8 col-sm-8 col-xs-8 d-flex flex-column align-items-center booking-button-wrapper">
                    <button class="btn btn-primary" @click="$router.push('/found-therapists')">See available Therapists</button>
                </div>
            </section>
        </section>
    </section>
</template>

<script>
import axios from '@/axios';

export default{

    data(){
        return{
            therapistData: {},
            isSearching: true,
            isTherapistFound: false,
            noAvailableTherapists: false,
            selectedIssueId: null
        }
    },
    async mounted(){
        if(this.$route.query.issueId){
            this.selectedIssueId = this.$route.query.issueId
        }

        await this.findTherapist();
    },  
    methods: {
        async findTherapist(){
            if(this.selectedIssueId != null){
                try {
                    await axios.get('/find-therapist/get-help-right-now', {
                        params: {
                            issue_id: this.selectedIssueId
                        },
                        headers: {
                            Authorization: `Bearer ${this.$store.state.accessToken}`
                        }, 
                    }).then((response) => { // использование стрелочной функции здесь
                        if(!response.data){
                            this.noAvailableTherapists = true
                        } else {
                            this.therapistData = response.data;
                            this.isSearching = false;
                            this.isTherapistFound = true;
                        }
                    })
                } catch (error) {
                    this.noAvailableTherapists = true;
                    this.isSearching = false;
                    this.isTherapistFound = false;
                    console.error('Ошибка при получении данных от API:', error)
                }
            }
        },

        async bookSession(){
            try {
                await axios.post('/appointment/create-session', {
                        practitioner_id: this.therapistData.practitioner_id,
                        fee: 25,
                        booking_type: 'instant'
                    }, 
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.accessToken}`
                        }
                    }).then(async (response) => { 
                        if(this.customerId != null){
                            this.payWithExistingCard(response.data);
                        }else{
                            this.$router.push({name:'add_card', query: { 
                                    // therapistsData: response.data,
                                    session: JSON.stringify(response.data),
                                    price: 25,
                                    process: "get-help-process",
                                    issueId: this.selectedIssueId 
                                } 
                            });
                        }
                        
                    })
            } catch (error) {
                // this.$router.push('/');
                console.error('Ошибка при получении данных от API:', error)
            }
        },

        async payWithExistingCard(sessionData){
            try {
                await axios.post('payment/stripe/existing-card-payment', {
                        session_id: sessionData.session_id,
                        fee_amount: 25,
                        customer_id: this.customerId
                    }, 
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.accessToken}`
                        }
                    }).then(() => { // использование стрелочной функции здесь
                        this.$router.push({name: 'get_help_session_is_booked', query: {
                            issueId: this.issueId
                        }})
                    })
            } catch (error) {
                this.$router.push('/');
                console.error('Ошибка при получении данных от API:', error)
            }
        },
    }
}
</script>

<style scoped>
    .searching-therapists{
        padding: 0px 32px 0px 32px;
        border-radius: 16px;
        margin-bottom: 32px;
        background-image: url('./../assets/images/searching-therapisits.svg');
        height: 240px;
        background-position: center;
        background-repeat: no-repeat;
    }

    .btn-primary {
        padding: 8px 0;
        border-radius: 22px;
        background: linear-gradient(46deg, #43C9F3 0%, #3CAEE0 100%);
        color: #fff;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 85%;
        margin-bottom: 24px;
    }
    .btn-primary img {
        margin: 0px 12px;
    }

    .no-therapists{
        padding: 0px 32px 0px 32px;
        border-radius: 16px;
        margin-bottom: 32px;
        background-image: url('./../assets/images/no-therapists.svg');
        height: 240px;
        background-position: center;
        background-repeat: no-repeat;
    }

    
  .breadcrumbs{
      padding: 18px 0px;
  }
  .breadcrumb-element{
      margin-left: 6px;
      margin-right: 6px;
      cursor: pointer;
  }
  .breadcrumb-element a{
      text-decoration: none;
      color: #13204E;
  }

  .breadcrumb-element img{
      margin-right: 6px;
  }
  .type-section{
      padding: 38px 0px 60px;
  }
  h1{
      font-size: 38px;
      line-height: 46px;
      margin-bottom: 12px;
  }

  h2{
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      margin-bottom: 0px;
  }

  h4{
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 4px;
  }
  .detail-block-section{
      margin-bottom: 16px;
  }
  .detail-block-section:last-child{
      margin-bottom: 0px;
  }
  .detail-block-section p{
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 0px;
  }
  .detail-block-section span{
      padding: 4px 12px;
      background: #3A466E;
      border-radius: 15px;
      width: fit-content;
      color: #fff;
      font-size: 12px;
  }
  .type-section{
      line-height: 24px;
  }
  .type-description{
      margin-bottom: 32px;
  }
  .booking-data{
      background: #fff;
      padding: 32px 32px 27px 32px;
      border-radius: 16px;
      margin-bottom: 32px;
  }
  .btn-primary {
      padding: 8px 0;
      border-radius: 22px;
      background: linear-gradient(46deg, #43C9F3 0%, #3CAEE0 100%);
      color: #fff;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 85%;
      margin-bottom: 24px;
  }
  .btn-primary img {
      margin: 0px 12px;
  }
  .booking-button-wrapper p{
      width: 85%;
      font-size: 14px;
      line-height: 22px;
  }
  .therapist-basic-description{
      width: 100%;
      display: flex;
      align-items: center;
  }
  .therapist-basic-description img{
      width: 96px;
      height: 96px;
  }
  .therapist-name{
      font-size: 20px;
      font-family: Libre Franklin;
      font-weight: 600;
      line-height: 28px;
      margin-bottom: 0px;
  }
  .therapist-basic-description p{
      margin-bottom: 0px;
  }
  .text-part{
      margin-left: 8px;
  }

  .therapist-specialization{
      padding: 4px 12px;
      background: #9DA3B7;
      border-radius: 15px;
      width: fit-content;
      color: #fff;
      font-size: 12px;
      margin-bottom: 5px;
      margin-right: 8px;
  }
  .therapist-specialization-title{
      margin-bottom: 12px;
  }
  .all-specs-text{
      margin-top: 14px;
      margin-bottom: 0px;
  }
</style>