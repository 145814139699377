<template>
    <section class="container h-100 d-flex align-items-center">
        
        <div class="w-100 d-flex align-items-center flex-column main-wrapper">
            <div class="w-100 content-block-wrapper">
                <div class="d-flex align-items-center">
                    <div class="back-arrow-block">
                        <a href="">
                            <img src="../assets/images/back-arrow-button.svg" alt="">
                        </a>
                    </div>
                    <div class="progress-container">
                        <div class="steps-container">
                            <div class="steps">
                                <div class="step active"><span>1</span></div>
                                <div class="d-flex flex-column align-items-center progress-content-block">
                                    <span>General info</span>
                                    <div class="progress-bar-wrapper">
                                        <div class="progress-bar" :style="{ width: progress + '%' }"></div>
                                    </div>
                                </div>
                                <img src="../assets/images/separation-dot.svg" alt="">
                                <div class="step"><span>2</span></div>
                                <img src="../assets/images/separation-dot.svg" alt="">
                                <div class="step"><span>3</span></div>
                            </div>
                        </div>
                        </div>
                        
                </div>
            </div>
            
            <div v-if="profileDataStep == 1" class="personal-info-wrapper-block">
                <h2>Personal information</h2>

                <div class="personal-info-block-part">
                    <p class="block-part-title">Gender</p>
                    <div class="chips-block">
                        <div v-for="(gender, index) in therapistData.gender_list"
                            :key="index"
                            class="chip"
                            :class="{ active: this.therapistData.gender == gender }"
                            @click="this.therapistData.gender = gender" 
                        >
                            {{ gender }}
                        </div>
                    </div>
                </div>
                <div class="personal-info-block-part" v-if="selectedTitle != null">
                    <p class="block-part-title">Choose your academic title —<span style="font-style: italic;">Optional</span></p>
                    <div class="chips-block">
                        <div
                            v-for="(title, index) in therapistData.title_list"
                            :key="index"
                            class="chip"
                            :class="{ active: this.therapistData.title_id == title.id }"
                            @click="this.therapistData.title_id = title.id"
                        >
                            {{ title.name }}
                        </div>
                    </div>
                </div>
                <div class="personal-info-block-part">
                    <p class="block-part-title">Date of birth</p>
                    <input v-model="this.therapistData.date_of_birth" type="date" class="w-100 sign-field"  placeholder="Enter your date of birth">
                </div>
                <div class="personal-info-block-part">
                    <p class="block-part-title">Phone </p>
                    <div class="d-flex justify-content-between">
                        <select v-model="therapistData.phone_code" class="phone-codes-select">
                            <option :value="country.code" v-for="(country, index) in countries" :key="index">
                                {{ country.code }} {{ country.name }}
                            </option>
                        </select>
                        <input v-model="therapistData.phone_number" type="text" class="sign-field"  placeholder="Phone number">
                    </div>
                </div>
                <div class="personal-info-block-part">
                    <p class="block-part-title">Timezone</p>
                    <select  class="timezones-select" v-model="therapistData.timezone_id">
                        <option :value="timezone.timezone_id" v-for="(timezone, index) in timezones" :key="index">
                            {{ timezone.timezone_name }}
                        </option>
                    </select>
                </div>
            </div>
            <div v-if="profileDataStep == 2" class="personal-info-wrapper-block">
                <h2>Good! Choose your languages</h2>

                <div v-for="(sortedLanguages, index) in languages" :key="index">
                    <h5 class="value-title">{{ sortedLanguages[0].language_name[0] }}</h5>

                    <div class="chips-block select-chips-block">
                        <div
                            v-for="(language, index) in sortedLanguages"
                            :key="index"
                            class="chip"
                            :class="{ active: selectedLanguages.includes(language.language_id) }"
                        >
                                <p 
                                    role="button" 
                                    style="color: #000"
                                    @click="!selectedLanguages.includes(language.language_id) ? selectedLanguages.push(language.language_id) : null">
                                    {{ language.language_name }}
                                </p>

                            <img 
                                v-if="selectedLanguages.includes(language.language_id)" 
                                class="chip-close-button" style="margin-left: 5px" src="../assets/images/delete-chip-black.svg" 
                                @click="selectedLanguages.splice(selectedLanguages.indexOf(language.language_id), 1)" 
                                alt=""
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="profileDataStep == 3" class="personal-info-wrapper-block ">
                <h2>Professional body</h2>

                <div class="d-flex flex-column align-items-center w-100" v-for="(license, index) in licenses" :key="index">
                    <div class="personal-info-block-part w-100">
                        <p class="block-part-title">Specialization</p>
                        <select class="timezones-select w-100" v-model="license.specialization_id">
                            <option :value="specialization.provider_id" v-for="(specialization, index) in specializations" :key="index">
                                {{ specialization.provider_type }}
                            </option>
                        </select>
                    </div>
                    <div class="personal-info-block-part w-100">
                        <p class="block-part-title">Registration/Licensing</p>
                        <select class="timezones-select w-100" v-model="license.license_id">
                            <option :value="licensingBody.license_body_id" v-for="(licensingBody, index) in licensingBodies" :key="index">
                                {{ licensingBody.name }}
                            </option>
                        </select>
                    </div>

                    <div class="personal-info-block-part w-100">
                        <p class="block-part-title">Registration / Licence no.</p>
                        <input v-model="license.license_number" type="text" class="sign-field w-100"  placeholder="License body">
                    </div>

                    <div class="w-100">
                        <a @click="deleteLicense(index)" style="color: #2F80ED; font-size: 14px; text-decoration: underline;">Remove license</a>
                    </div>
                </div>   
            </div>

            <div v-if="profileDataStep == 4" class="personal-info-wrapper-block ">
                <h2>Confirm your identity</h2>

                <p>Please upload your passport or driver's license to confirm your identity. This is for ID confirmation only and will not be published on your profile. </p>

                <img src="../assets/images/passport.png" alt="" style="margin-bottom: 32px">

                <div class="d-flex flex-column align-items-center w-100">
                    <label role="button" for="image-upload" class="upload-document-button d-flex align-items-center">Upload a document</label>
                    
                    <input v-show="false" type="file" id="image-upload" @change="handleDocumentFileUpload"/>
                </div>
            </div>

            <div v-if="profileDataStep == 5" class="personal-info-wrapper-block ">
                <h2>One more thing. Recent background check</h2>

                <p style="margin-bottom: 32px">Please upload a DBS or recent background check here. </p>

                <div class="d-flex flex-column align-items-center w-100">
                    <label role="button" for="image-upload" class="upload-document-button d-flex align-items-center">Upload DBS</label>
                    
                    <input v-show="false" type="file" id="image-upload" @change="handleDBSFileUpload"/>
                </div>

                <p style="margin-top: 32px">If you don't have a recent DBS you can apply for one here: <a href="https://dbs-online.org.uk/apply/" style="color: #2F80ED">https://dbs-online.org.uk/apply/</a> then upload the document once when the check is complete. If you don't submit a criminal background check we'll display a note for the public on your profile stating that no background check has been carried out. </p>
            </div>

            <button class="next-step-button" @click="goToNextStep" style="margin-bottom: 80px">Next step</button>
        </div>
    </section>
</template>

<script>
import axios from '@/axios';

    export default{
        data(){
            return {
                profileDataStep: 1,
                genders: ['Male', 'Female', 'Non-binary'],
                selectedGender: null,
                titles: ['Dr.', 'Mr.', 'Prof.', 'Ms.'],
                selectedTitle: {},
                showPhoneNumberCode: false,
                selectedLanguages: [],
                progress: 25,
                languages: [],
                licenseNumber: null,
                therapistData: {},
                timezones: [],
                licenses: [
                    {
                        license_id: null,
                        license_number: null,
                        specialization_id: null
                    }
                ],
                countries: [
                    {
                        "code": "+7 840",
                        "name": "Abkhazia"
                    },
                    {
                        "code": "+93",
                        "name": "Afghanistan"
                    },
                    {
                        "code": "+355",
                        "name": "Albania"
                    },
                    {
                        "code": "+213",
                        "name": "Algeria"
                    },
                    {
                    "code": "+1 684",
                    "name": "American Samoa"
                    },
                    {
                    "code": "+376",
                    "name": "Andorra"
                    },
                    {
                    "code": "+244",
                    "name": "Angola"
                    },
                    {
                    "code": "+1 264",
                    "name": "Anguilla"
                    },
                    {
                    "code": "+1 268",
                    "name": "Antigua and Barbuda"
                    },
                    {
                    "code": "+54",
                    "name": "Argentina"
                    },
                    {
                    "code": "+374",
                    "name": "Armenia"
                    },
                    {
                    "code": "+297",
                    "name": "Aruba"
                    },
                    {
                    "code": "+247",
                    "name": "Ascension"
                    },
                    {
                    "code": "+61",
                    "name": "Australia"
                    },
                    {
                    "code": "+672",
                    "name": "Australian External Territories"
                    },
                    {
                    "code": "+43",
                    "name": "Austria"
                    },
                    {
                    "code": "+994",
                    "name": "Azerbaijan"
                    },
                    {
                    "code": "+1 242",
                    "name": "Bahamas"
                    },
                    {
                    "code": "+973",
                    "name": "Bahrain"
                    },
                    {
                    "code": "+880",
                    "name": "Bangladesh"
                    },
                    {
                    "code": "+1 246",
                    "name": "Barbados"
                    },
                    {
                    "code": "+1 268",
                    "name": "Barbuda"
                    },
                    {
                    "code": "+375",
                    "name": "Belarus"
                    },
                    {
                    "code": "+32",
                    "name": "Belgium"
                    },
                    {
                    "code": "+501",
                    "name": "Belize"
                    },
                    {
                    "code": "+229",
                    "name": "Benin"
                    },
                    {
                    "code": "+1 441",
                    "name": "Bermuda"
                    },
                    {
                    "code": "+975",
                    "name": "Bhutan"
                    },
                    {
                    "code": "+591",
                    "name": "Bolivia"
                    },
                    {
                    "code": "+387",
                    "name": "Bosnia and Herzegovina"
                    },
                    {
                    "code": "+267",
                    "name": "Botswana"
                    },
                    {
                    "code": "+55",
                    "name": "Brazil"
                    },
                    {
                    "code": "+246",
                    "name": "British Indian Ocean Territory"
                    },
                    {
                    "code": "+1 284",
                    "name": "British Virgin Islands"
                    },
                    {
                    "code": "+673",
                    "name": "Brunei"
                    },
                    {
                    "code": "+359",
                    "name": "Bulgaria"
                    },
                    {
                    "code": "+226",
                    "name": "Burkina Faso"
                    },
                    {
                    "code": "+257",
                    "name": "Burundi"
                    },
                    {
                    "code": "+855",
                    "name": "Cambodia"
                    },
                    {
                    "code": "+237",
                    "name": "Cameroon"
                    },
                    {
                    "code": "+1",
                    "name": "Canada"
                    },
                    {
                    "code": "+238",
                    "name": "Cape Verde"
                    },
                    {
                    "code": "+ 345",
                    "name": "Cayman Islands"
                    },
                    {
                    "code": "+236",
                    "name": "Central African Republic"
                    },
                    {
                    "code": "+235",
                    "name": "Chad"
                    },
                    {
                    "code": "+56",
                    "name": "Chile"
                    },
                    {
                    "code": "+86",
                    "name": "China"
                    },
                    {
                    "code": "+61",
                    "name": "Christmas Island"
                    },
                    {
                    "code": "+61",
                    "name": "Cocos-Keeling Islands"
                    },
                    {
                    "code": "+57",
                    "name": "Colombia"
                    },
                    {
                    "code": "+269",
                    "name": "Comoros"
                    },
                    {
                    "code": "+242",
                    "name": "Congo"
                    },
                    {
                    "code": "+243",
                    "name": "Congo, Dem. Rep. of (Zaire)"
                    },
                    {
                    "code": "+682",
                    "name": "Cook Islands"
                    },
                    {
                    "code": "+506",
                    "name": "Costa Rica"
                    },
                    {
                    "code": "+385",
                    "name": "Croatia"
                    },
                    {
                    "code": "+53",
                    "name": "Cuba"
                    },
                    {
                    "code": "+599",
                    "name": "Curacao"
                    },
                    {
                    "code": "+537",
                    "name": "Cyprus"
                    },
                    {
                    "code": "+420",
                    "name": "Czech Republic"
                    },
                    {
                    "code": "+45",
                    "name": "Denmark"
                    },
                    {
                    "code": "+246",
                    "name": "Diego Garcia"
                    },
                    {
                    "code": "+253",
                    "name": "Djibouti"
                    },
                    {
                    "code": "+1 767",
                    "name": "Dominica"
                    },
                    {
                    "code": "+1 809",
                    "name": "Dominican Republic"
                    },
                    {
                    "code": "+670",
                    "name": "East Timor"
                    },
                    {
                    "code": "+56",
                    "name": "Easter Island"
                    },
                    {
                    "code": "+593",
                    "name": "Ecuador"
                    },
                    {
                    "code": "+20",
                    "name": "Egypt"
                    },
                    {
                    "code": "+503",
                    "name": "El Salvador"
                    },
                    {
                    "code": "+240",
                    "name": "Equatorial Guinea"
                    },
                    {
                    "code": "+291",
                    "name": "Eritrea"
                    },
                    {
                    "code": "+372",
                    "name": "Estonia"
                    },
                    {
                    "code": "+251",
                    "name": "Ethiopia"
                    },
                    {
                    "code": "+500",
                    "name": "Falkland Islands"
                    },
                    {
                    "code": "+298",
                    "name": "Faroe Islands"
                    },
                    {
                    "code": "+679",
                    "name": "Fiji"
                    },
                    {
                    "code": "+358",
                    "name": "Finland"
                    },
                    {
                    "code": "+33",
                    "name": "France"
                    },
                    {
                    "code": "+596",
                    "name": "French Antilles"
                    },
                    {
                    "code": "+594",
                    "name": "French Guiana"
                    },
                    {
                    "code": "+689",
                    "name": "French Polynesia"
                    },
                    {
                    "code": "+241",
                    "name": "Gabon"
                    },
                    {
                    "code": "+220",
                    "name": "Gambia"
                    },
                    {
                    "code": "+995",
                    "name": "Georgia"
                    },
                    {
                    "code": "+49",
                    "name": "Germany"
                    },
                    {
                    "code": "+233",
                    "name": "Ghana"
                    },
                    {
                    "code": "+350",
                    "name": "Gibraltar"
                    },
                    {
                    "code": "+30",
                    "name": "Greece"
                    },
                    {
                    "code": "+299",
                    "name": "Greenland"
                    },
                    {
                    "code": "+1 473",
                    "name": "Grenada"
                    },
                    {
                    "code": "+590",
                    "name": "Guadeloupe"
                    },
                    {
                    "code": "+1 671",
                    "name": "Guam"
                    },
                    {
                    "code": "+502",
                    "name": "Guatemala"
                    },
                    {
                    "code": "+224",
                    "name": "Guinea"
                    },
                    {
                    "code": "+245",
                    "name": "Guinea-Bissau"
                    },
                    {
                    "code": "+595",
                    "name": "Guyana"
                    },
                    {
                    "code": "+509",
                    "name": "Haiti"
                    },
                    {
                    "code": "+504",
                    "name": "Honduras"
                    },
                    {
                    "code": "+852",
                    "name": "Hong Kong SAR China"
                    },
                    {
                    "code": "+36",
                    "name": "Hungary"
                    },
                    {
                    "code": "+354",
                    "name": "Iceland"
                    },
                    {
                    "code": "+91",
                    "name": "India"
                    },
                    {
                    "code": "+62",
                    "name": "Indonesia"
                    },
                    {
                    "code": "+98",
                    "name": "Iran"
                    },
                    {
                    "code": "+964",
                    "name": "Iraq"
                    },
                    {
                    "code": "+353",
                    "name": "Ireland"
                    },
                    {
                    "code": "+972",
                    "name": "Israel"
                    },
                    {
                    "code": "+39",
                    "name": "Italy"
                    },
                    {
                    "code": "+225",
                    "name": "Ivory Coast"
                    },
                    {
                    "code": "+1 876",
                    "name": "Jamaica"
                    },
                    {
                    "code": "+81",
                    "name": "Japan"
                    },
                    {
                    "code": "+962",
                    "name": "Jordan"
                    },
                    {
                    "code": "+7 7",
                    "name": "Kazakhstan"
                    },
                    {
                    "code": "+254",
                    "name": "Kenya"
                    },
                    {
                    "code": "+686",
                    "name": "Kiribati"
                    },
                    {
                    "code": "+965",
                    "name": "Kuwait"
                    },
                    {
                    "code": "+996",
                    "name": "Kyrgyzstan"
                    },
                    {
                    "code": "+856",
                    "name": "Laos"
                    },
                    {
                    "code": "+371",
                    "name": "Latvia"
                    },
                    {
                    "code": "+961",
                    "name": "Lebanon"
                    },
                    {
                    "code": "+266",
                    "name": "Lesotho"
                    },
                    {
                    "code": "+231",
                    "name": "Liberia"
                    },
                    {
                    "code": "+218",
                    "name": "Libya"
                    },
                    {
                    "code": "+423",
                    "name": "Liechtenstein"
                    },
                    {
                    "code": "+370",
                    "name": "Lithuania"
                    },
                    {
                    "code": "+352",
                    "name": "Luxembourg"
                    },
                    {
                    "code": "+853",
                    "name": "Macau SAR China"
                    },
                    {
                    "code": "+389",
                    "name": "Macedonia"
                    },
                    {
                    "code": "+261",
                    "name": "Madagascar"
                    },
                    {
                    "code": "+265",
                    "name": "Malawi"
                    },
                    {
                    "code": "+60",
                    "name": "Malaysia"
                    },
                    {
                    "code": "+960",
                    "name": "Maldives"
                    },
                    {
                    "code": "+223",
                    "name": "Mali"
                    },
                    {
                    "code": "+356",
                    "name": "Malta"
                    },
                    {
                    "code": "+692",
                    "name": "Marshall Islands"
                    },
                    {
                    "code": "+596",
                    "name": "Martinique"
                    },
                    {
                    "code": "+222",
                    "name": "Mauritania"
                    },
                    {
                    "code": "+230",
                    "name": "Mauritius"
                    },
                    {
                    "code": "+262",
                    "name": "Mayotte"
                    },
                    {
                    "code": "+52",
                    "name": "Mexico"
                    },
                    {
                    "code": "+691",
                    "name": "Micronesia"
                    },
                    {
                    "code": "+1 808",
                    "name": "Midway Island"
                    },
                    {
                    "code": "+373",
                    "name": "Moldova"
                    },
                    {
                    "code": "+377",
                    "name": "Monaco"
                    },
                    {
                    "code": "+976",
                    "name": "Mongolia"
                    },
                    {
                    "code": "+382",
                    "name": "Montenegro"
                    },
                    {
                    "code": "+1664",
                    "name": "Montserrat"
                    },
                    {
                    "code": "+212",
                    "name": "Morocco"
                    },
                    {
                    "code": "+95",
                    "name": "Myanmar"
                    },
                    {
                    "code": "+264",
                    "name": "Namibia"
                    },
                    {
                    "code": "+674",
                    "name": "Nauru"
                    },
                    {
                    "code": "+977",
                    "name": "Nepal"
                    },
                    {
                    "code": "+31",
                    "name": "Netherlands"
                    },
                    {
                    "code": "+599",
                    "name": "Netherlands Antilles"
                    },
                    {
                    "code": "+1 869",
                    "name": "Nevis"
                    },
                    {
                    "code": "+687",
                    "name": "New Caledonia"
                    },
                    {
                    "code": "+64",
                    "name": "New Zealand"
                    },
                    {
                    "code": "+505",
                    "name": "Nicaragua"
                    },
                    {
                    "code": "+227",
                    "name": "Niger"
                    },
                    {
                    "code": "+234",
                    "name": "Nigeria"
                    },
                    {
                    "code": "+683",
                    "name": "Niue"
                    },
                    {
                    "code": "+672",
                    "name": "Norfolk Island"
                    },
                    {
                    "code": "+850",
                    "name": "North Korea"
                    },
                    {
                    "code": "+1 670",
                    "name": "Northern Mariana Islands"
                    },
                    {
                    "code": "+47",
                    "name": "Norway"
                    },
                    {
                    "code": "+968",
                    "name": "Oman"
                    },
                    {
                    "code": "+92",
                    "name": "Pakistan"
                    },
                    {
                    "code": "+680",
                    "name": "Palau"
                    },
                    {
                    "code": "+970",
                    "name": "Palestinian Territory"
                    },
                    {
                    "code": "+507",
                    "name": "Panama"
                    },
                    {
                    "code": "+675",
                    "name": "Papua New Guinea"
                    },
                    {
                    "code": "+595",
                    "name": "Paraguay"
                    },
                    {
                    "code": "+51",
                    "name": "Peru"
                    },
                    {
                    "code": "+63",
                    "name": "Philippines"
                    },
                    {
                    "code": "+48",
                    "name": "Poland"
                    },
                    {
                    "code": "+351",
                    "name": "Portugal"
                    },
                    {
                    "code": "+1 787",
                    "name": "Puerto Rico"
                    },
                    {
                    "code": "+974",
                    "name": "Qatar"
                    },
                    {
                    "code": "+262",
                    "name": "Reunion"
                    },
                    {
                    "code": "+40",
                    "name": "Romania"
                    },
                    {
                    "code": "+7",
                    "name": "Russia"
                    },
                    {
                    "code": "+250",
                    "name": "Rwanda"
                    },
                    {
                    "code": "+685",
                    "name": "Samoa"
                    },
                    {
                    "code": "+378",
                    "name": "San Marino"
                    },
                    {
                    "code": "+966",
                    "name": "Saudi Arabia"
                    },
                    {
                    "code": "+221",
                    "name": "Senegal"
                    },
                    {
                    "code": "+381",
                    "name": "Serbia"
                    },
                    {
                    "code": "+248",
                    "name": "Seychelles"
                    },
                    {
                    "code": "+232",
                    "name": "Sierra Leone"
                    },
                    {
                    "code": "+65",
                    "name": "Singapore"
                    },
                    {
                    "code": "+421",
                    "name": "Slovakia"
                    },
                    {
                    "code": "+386",
                    "name": "Slovenia"
                    },
                    {
                    "code": "+677",
                    "name": "Solomon Islands"
                    },
                    {
                    "code": "+27",
                    "name": "South Africa"
                    },
                    {
                    "code": "+500",
                    "name": "South Georgia and the South Sandwich Islands"
                    },
                    {
                    "code": "+82",
                    "name": "South Korea"
                    },
                    {
                    "code": "+34",
                    "name": "Spain"
                    },
                    {
                    "code": "+94",
                    "name": "Sri Lanka"
                    },
                    {
                    "code": "+249",
                    "name": "Sudan"
                    },
                    {
                    "code": "+597",
                    "name": "Suriname"
                    },
                    {
                    "code": "+268",
                    "name": "Swaziland"
                    },
                    {
                    "code": "+46",
                    "name": "Sweden"
                    },
                    {
                    "code": "+41",
                    "name": "Switzerland"
                    },
                    {
                    "code": "+963",
                    "name": "Syria"
                    },
                    {
                    "code": "+886",
                    "name": "Taiwan"
                    },
                    {
                    "code": "+992",
                    "name": "Tajikistan"
                    },
                    {
                    "code": "+255",
                    "name": "Tanzania"
                    },
                    {
                    "code": "+66",
                    "name": "Thailand"
                    },
                    {
                    "code": "+670",
                    "name": "Timor Leste"
                    },
                    {
                    "code": "+228",
                    "name": "Togo"
                    },
                    {
                    "code": "+690",
                    "name": "Tokelau"
                    },
                    {
                    "code": "+676",
                    "name": "Tonga"
                    },
                    {
                    "code": "+1 868",
                    "name": "Trinidad and Tobago"
                    },
                    {
                    "code": "+216",
                    "name": "Tunisia"
                    },
                    {
                    "code": "+90",
                    "name": "Turkey"
                    },
                    {
                    "code": "+993",
                    "name": "Turkmenistan"
                    },
                    {
                    "code": "+1 649",
                    "name": "Turks and Caicos Islands"
                    },
                    {
                    "code": "+688",
                    "name": "Tuvalu"
                    },
                    {
                    "code": "+1 340",
                    "name": "U.S. Virgin Islands"
                    },
                    {
                    "code": "+256",
                    "name": "Uganda"
                    },
                    {
                    "code": "+380",
                    "name": "Ukraine"
                    },
                    {
                    "code": "+971",
                    "name": "United Arab Emirates"
                    },
                    {
                    "code": "+44",
                    "name": "United Kingdom"
                    },
                    {
                    "code": "+1",
                    "name": "United States"
                    },
                    {
                    "code": "+598",
                    "name": "Uruguay"
                    },
                    {
                    "code": "+998",
                    "name": "Uzbekistan"
                    },
                    {
                    "code": "+678",
                    "name": "Vanuatu"
                    },
                    {
                    "code": "+58",
                    "name": "Venezuela"
                    },
                    {
                    "code": "+84",
                    "name": "Vietnam"
                    },
                    {
                    "code": "+1 808",
                    "name": "Wake Island"
                    },
                    {
                    "code": "+681",
                    "name": "Wallis and Futuna"
                    },
                    {
                    "code": "+967",
                    "name": "Yemen"
                    },
                    {
                    "code": "+260",
                    "name": "Zambia"
                    },
                    {
                    "code": "+255",
                    "name": "Zanzibar"
                    },
                    {
                    "code": "+263",
                    "name": "Zimbabwe"
                    }
                ],   
                specializations: [],
                licensingBodies: [],
                dbsFile: null,
                documentFile: null,
                isEditing: false,
            }
        },
        async mounted(){
            if (typeof this.$route.query.isEditing !== 'undefined') {
                this.isEditing = this.$route.query.isEditing;
            }

            await axios.get('/language/get-languages').then((response) => {
                this.languages = this.sortAndGroupLanguages(response.data);
            });

            await axios.get('therapist/get-languages', {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).then((response) => {
                response.data.forEach((language) => {
                    this.selectedLanguages.push(language.language_id);
                })
            });

            await this.getTherapistData().then(async () => {    
                this.selectGender(this.therapistData.gender);
            })
            
            await axios.get('/therapist/get-title-list', {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).then((response) => {
                console.log(response.data);
                console.log(this.therapistData);
                this.therapistData.title_list = response.data
            });

            await axios.get('/therapist/get-gender-list', {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).then((response) => {
                this.therapistData.gender_list = response.data
            });

            await axios.get('therapist/get-gender', {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).then((response) => {
                this.therapistData.gender = response.data
            });

            await axios.get('therapist/get-title', {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).then((response) => {
                this.therapistData.title_id = response.data.id
            });

            await axios.get('therapist/get-phone-code', {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).then((response) => {
                this.therapistData.phone_code = response.data;
            });

            await axios.get('therapist/get-phone-number', {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).then((response) => {
                if(typeof response.data == 'string'){
                    this.therapistData.phone_number = response.data;
                }
            });

            await axios.get(`/therapist/get-timezone-list`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                },
            }).then((response) => {
                this.timezones = response.data;
            })

            await axios.get('therapist/get-timezone', {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).then((response) => {
                this.therapistData.timezone_id = response.data.timezone_id;
            });

            await axios.get('/therapist/get-specialization-list', {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                },
            }).then((response) => {
                this.specializations = response.data;
            });
            await axios.get('/therapist/get-licensing-body-list', {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                },
            }).then((response) => {
                this.licensingBodies = response.data;
            });

            await this.prepareLicense();
        },
        methods: {
            addLicense(){
                this.licenses.push(
                    {
                        license_id: null,
                        license_number: null,
                        specialization_id: null
                    }
                )
            },
            deleteLicense(index){
                this.licenses.splice(index, 1)
            },
            async getTherapistData(){
                await axios.get(`/therapist/${this.$store.state.therapist.id}/get-profile`).then(async (response) => {
                    this.therapistData = await response.data;
                })
            },
            selectGender(gender) {
                this.selectedGender = gender;
            },
            selectTitle(title) {
                this.selectedTitle = title;
            },
            unwrapProxy(proxyObject){
                return JSON.parse(JSON.stringify(proxyObject));
            },
            async goToNextStep(){
                switch (this.profileDataStep) {
                    case 1:
                        await this.firstStepProcess()
                        break;
                    case 2: 
                        await this.secondStepProcess()
                        break;
                    case 3: 
                        await this.thirdStepProcess()
                        break;
                    case 4: 
                        await this.fourthStepProcess()
                        break;
                    case 5: 
                        await this.fifthStepProcess()
                        console.log(this.isEditing);
                        if(this.isEditing){
                            this.$router.push('/therapist/professional-info?isEditing=true');
                        }else{  
                            this.$router.push('/therapist/professional-info');
                        }
                        break;
                    default:
                        break;
                }
                this.getTherapistData();
                this.profileDataStep += 1;
            },
            async firstStepProcess(){
                this.therapistData.dob = this.convertDate(this.therapistData.date_of_birth);
                await axios.put('/therapist/update-profile', {
                    ...this.therapistData
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`
                    }
                });
            },
            async secondStepProcess(){
                await axios.post('/therapist/add-language-list', {
                    language_list: this.selectedLanguages
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`
                    }
                });
            },
            async thirdStepProcess(){
                await axios.post('/therapist/add-license-list', {
                    license_list: JSON.stringify(this.licenses)
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`
                    }
                });
            },
            async fourthStepProcess() {
                let formData = new FormData(); // Создаем экземпляр FormData
                formData.append('file', this.documentFile); // Добавляем файл в FormData. 'file' - это ключ, по которому сервер ожидает получить файл

                try {
                    await axios.post('/therapist/upload-document-file', formData, {
                        headers: {
                            'Authorization': `Bearer ${this.$store.state.accessToken}`,
                            'Content-Type': 'multipart/form-data' // Этот заголовок очень важен при отправке файлов
                        }
                    });
                } catch (error) {
                    // Обработка возможных ошибок при отправке
                    console.error('Ошибка при загрузке файла:', error);
                }
            },
            async fifthStepProcess() {
                let formData = new FormData(); // Создаем экземпляр FormData
                formData.append('file', this.dbsFile); // Добавляем файл в FormData. 'file' - это ключ, по которому сервер ожидает получить файл

                try {
                    await axios.post('/therapist/upload-profile-dbs', formData, {
                        headers: {
                            'Authorization': `Bearer ${this.$store.state.accessToken}`,
                            'Content-Type': 'multipart/form-data' // Этот заголовок очень важен при отправке файлов
                        }
                    });
                } catch (error) {
                    // Обработка возможных ошибок при отправке
                    console.error('Ошибка при загрузке файла:', error);
                }
            },
            convertDate(inputDate) {
                if (inputDate) {
                    const dateParts = inputDate.split('-'); // Разделение YYYY-MM-DD
                    const year = dateParts[0];
                    const month = dateParts[1];
                    const day = dateParts[2];

                    // Конвертация в формат d.m.Y
                    this.formattedDate = `${day}.${month}.${year}`;
                }
            },
            sortAndGroupLanguages(languages) {
                // Сортируем языки по названию
                const sortedLanguages = languages.sort((a, b) => a.language_name.localeCompare(b.language_name));

                // Группируем отсортированные языки по первой букве названия
                const groupedLanguages = sortedLanguages.reduce((acc, language) => {
                    const firstLetter = language.language_name[0]; // Получаем первую букву названия языка
                    if (!acc[firstLetter]) {
                    acc[firstLetter] = []; // Создаем новый массив, если такого ключа еще нет
                    }
                    acc[firstLetter].push(language); // Добавляем язык в соответствующий массив
                    return acc;
                }, {});

                return groupedLanguages;
            },
            selectLanguage(id) {
                if(!this.selectedLanguages.includes(id)){
                    this.selectedLanguages.push(id);
                }
            },
            disselectLanguage(id) {
                const index = this.selectedLanguages.indexOf(id);
                this.selectedLanguages.splice(index, 1);
            },
            handleDBSFileUpload(event){
                this.dbsFile = event.target.files[0]; // Получаем выбранный файл
            },
            handleDocumentFileUpload(event){
                this.documentFile = event.target.files[0]; // Получаем выбранный файл
            },
            async prepareLicense(){
                if(typeof this.therapistData.licence == 'string'){
                    const specialization = this.specializations.find((specialization) => {
                        return specialization.provider_type == this.therapistData.provider_types[0]
                    })

                    const licenseBody = this.licensingBodies.find((licensingBody) => {
                        return licensingBody.name == this.therapistData.licence
                    })

                    this.licenses[0] = {
                        specialization_id: specialization.provider_id,
                        license_id: licenseBody.license_body_id,
                        license_number: this.therapistData.licence_number
                    };
                }
            }
        }
    }
</script>

<style scoped>
.personal-info-wrapper-block{
    width: 612px;
    padding: 48px;
    background: #fff;
    border-radius: 16px;
    margin-bottom: 32px;
    margin-top: 32px;
    border: 1px solid #E6E8ED;
}

.personal-info-wrapper-block > h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
}

.content-block-wrapper{
    padding: 18px 24px 18px 24px;
    background: #fff;
    border-radius: 16px;
    margin-bottom: 32px;
    border: 1px solid #E6E8ED;
    margin-top: 24px;
}

.next-step-button{
    width: 311px;
    margin-top: 18px;
}

.phone-codes-select{
    width: 152px;
    background: #fff;
    height: 44px;
    border: 1px solid #E6E8ED;
    border-radius: 8px;
    padding: 10px 40px 10px 12px;
    -webkit-appearance: none; /* Удалить стандартную стрелку в WebKit-браузерах */
    -moz-appearance: none;    /* Удалить стандартную стрелку в Firefox */
    appearance: none;         /* Стандартный способ, который может не поддерживаться всеми браузерами */
    background-image: url('../assets/images/arrow-down.svg'); /* Путь к вашей кастомной стрелке */
    background-repeat: no-repeat;
    background-position: right 16px center; /* Сдвигаем стрелку на 10px от правого края */
}

.timezones-select{
    width: 100%;
    background: #fff;
    height: 44px;
    border: 1px solid #E6E8ED;
    border-radius: 8px;
    padding: 10px 40px 10px 12px;
    -webkit-appearance: none; /* Удалить стандартную стрелку в WebKit-браузерах */
    -moz-appearance: none;    /* Удалить стандартную стрелку в Firefox */
    appearance: none;         /* Стандартный способ, который может не поддерживаться всеми браузерами */
    background-image: url('../assets/images/arrow-down.svg'); /* Путь к вашей кастомной стрелке */
    background-repeat: no-repeat;
    background-position: right 16px center; /* Сдвигаем стрелку на 10px от правого края */
}

.sign-field{
    background: #fff;
    height: 44px;
    border: 1px solid #E6E8ED;
    border-radius: 8px;
    padding: 10px 12px 10px 12px;
    line-height: 24px;
    width: 350px;
}

.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 64px);
}

.progress-bar {
  height: 100%;
  background-color: #43C9F3;
  border-radius: 5px 0 0 5px; /* Rounded corners on the left side */
}

.progress-bar-wrapper {
  height: 4px;
  background-color: #E6E8ED;
  border-radius: 5px 0 0 5px; /* Rounded corners on the left side */
  width: 127px;
}

.progress-content-block > span {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; 
    margin-bottom: 4px;
}

.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 371px;
}

.steps img {
    width: 6px;
    height: 6px;
}

.step {
  display: flex;
  align-items: center;
}

.step span {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 600;
  background-color: transparent;
  color: var(--font-dark-80, #3A466E);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #E6E8ED;
}

.back-arrow-block{
    background: #F3F3F6;
    border: 1px solid #E6E8ED;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
}

.chips-block{
    display: flex;
}

.personal-info-block-part{
    margin-bottom: 32px;
}

.chip{
    padding: 4px 12px 4px 12px;
    background-color: #fff;
    display: flex;
    width: fit-content;
    align-items: center;
    font-size: 12px;
    height: 28px;
    color: #000;
    font-weight: bold;
    border-radius: 16px;
    margin-right: 12px;
    margin-bottom: 3px;
    cursor: pointer;
  }

.chip.active{
    background-color: #43C9F3;
    color: #000;
}

.chip > p{
    margin-bottom: 0px;
}

.block-part-title{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 12px;
}

.block-part-title > span 
{
    font-weight: 500;
}

.step.active span {
  background-color: #43C9F3; /* Active step color */
}

.value-title{
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    color: #3A466E;
    margin-bottom: 8px;
}

.select-chips-block{
    margin-bottom: 32px;
}

.upload-document-button{
    border: 1px solid #000;
    padding: 8px 73px;
    background: #fff;
    font-size: 16px;
    font-weight: 600;
    border-radius: 50px;
}

@media (max-width: 612px) { 
  .personal-info-wrapper-block{
    width: calc(100% - 24px);
    padding: 24px;
  }
}
</style>