<template>
  <div v-if="isOpen" class="modal-overlay d-flex w-100 justify-content-end">
    <div class="modal-structure d-flex flex-column align-items-center col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-6">
      <div class="modal-header d-flex justify-content-end w-100">
        <img class="close-icon" src="../../assets/images/close-icon.svg" @click="closeModal" alt="">
      </div>
      
      <div v-if="!completedSession" class="modal-body w-100 d-flex flex-column align-items-center">
          <h3 class="text-center sidebar_title">Book an appointment</h3>
          <p class="text-center" >Select type of session</p>
          <div class="toggle-switch-wrapper">
              
            <div class="toggle-switch-internal mb-3">
                  <div class="toggle-option" :class="{ active: selectedOption === 'option1' }" @click="selectedOption = 'option1'">
                      <span>Online</span>
                  </div>
                  <div class="toggle-option" :class="{ active: selectedOption === 'option2' }" @click="selectedOption = 'option2'">
                      <span>Face-to-face</span>
                  </div>
                  <div class="toggle-slider" :class="{ 'option2': selectedOption === 'option2' }"></div>
              </div>
            </div>
          <hr>
          <div class="w-100">
            <p class="sidebar_sub_title">What service do you want?</p>
            <select name="" id="" class="service-selection w-100">
                <option value="">Couple therapy</option>
                <option value="">Couple therapy2</option>
            </select>
            <div class="w-50 d-flex additional-meeting-data">
                <div class="therapist-data-short-description">
                    <span><img class="therapist-data-short-description-img" src="../../assets/images/star-icon.svg" alt="">4,8</span>
                </div>
                <div class="therapist-data-short-description">
                    <span><img class="therapist-data-short-description-img" src="../../assets/images/tag-black.svg" alt="">£160</span>
                </div>
            </div>
          </div>
            <div class="calender-structure">
              <p class="sidebar_sub_title calender-title">What service do you want?</p>
              <p class="calender-description">Select type of session</p>
              <div class="w-100 calender_block">
                  <div class="row justify-content-between calender-row">
                      <button @click="moveSlider(-7)" class="calender-switch-button"><img src="../../assets/images/back_icon.svg" alt=""></button>
                      <div class="days-row">
                          <div
                              v-for="(day, index) in days"
                              :key="index"
                              class="day_of_the_week_block"
                              >   
                              <div>
                                  <div 
                                      :class="['day_of_the_week', { 'selected': selectedDate === day.fullDate }]"
                                      @click="day.past ? null : selectDate(day.fullDate)"
                                  >{{ day.dayOfWeek }}</div>
                                  <div class="dateText">{{ day.date }} {{ day.month.toLowerCase() }}</div>
                              </div>
                                  
                              
                          </div>
                      </div>
                      <button @click="moveSlider(7)" class="calender-switch-button"><img src="../../assets/images/next_icon.svg" alt=""></button>
                  </div> 
                  <div 
                      id="timeBlockContainer" 
                      @mousedown="handleMouseDown" 
                      @mouseup="handleMouseUp" 
                      @mouseleave="handleMouseUp" 
                      @mousemove="handleMouseMove"
                      
                      ref="timeBlockContainer"
                      >
                      <div
                          v-for="time in times"
                          :key="time"
                          :class="['timeBlock', { 'selected': selectedTime === time }]"
                          @click="selectTime(time)"
                      >
                          {{ time }}
                      </div>
                  </div>
              </div>
            </div>

            <button style="border-radius: 50px; margin-top: 24px; margin-bottom: 24px;" class="next-step-button">Book session</button>

            <div class="d-flex align-items-center"><img src="../../assets/images/instant-booking.svg" alt=""><p style="margin-bottom: 0px;">Instant booking available</p></div>
        
      </div>
      <div v-else class="modal-body w-100 d-flex flex-column align-items-center">
          <div class="d-flex flex-column align-items-center">
            <img src="../../assets/images/changed-booking.svg" alt="">
            <h4>Your booking is changed!</h4>
          </div>
          
      </div>
    </div>
    
  </div>
</template>

<script>
import axios from '@/axios';

export default {
  props: ['isOpen', 'session'],
  data(){
    return {
      selectedOption: 'option1', // Default selected option
      startDate: this.getMonday(new Date()),
      selectedDate: "",
      selectedTime: "",
      days: [],
      times: ["9:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00"],
      isDragging: false,
      startX: 0,
      lastScrollLeft: 0,
      completedSession: false
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    getMonday(d) {
        d = new Date(d);
        let day = d.getDay();
        let diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is Sunday
        return new Date(d.setDate(diff));
    },
    selectDate(date) {
        this.selectedDate = date;
    },
    moveSlider(direction) {
        this.startDate.setDate(this.startDate.getDate() + direction);
        this.generateDays();
    },
    generateDays() {
        this.days = [];
        const today = new Date().setHours(0, 0, 0, 0);
        for (let i = 0; i < 5; i++) {
            const dateObj = new Date(this.startDate);
            dateObj.setDate(this.startDate.getDate() + i);

            const dayOptions = { weekday: "short" };
            const dateOptions = { day: "numeric" };
            const monthOptions = { month: "short" };
            const generalDateOptions = { weekday: "short", day: "numeric", month: "short" };

            const dayStr = new Intl.DateTimeFormat("en-US", dayOptions).format(dateObj);
            const dateStr = new Intl.DateTimeFormat("en-US", dateOptions).format(dateObj);
            const monthStr = new Intl.DateTimeFormat("en-US", monthOptions).format(dateObj)
            const fullDateStr = new Intl.DateTimeFormat("en-US", generalDateOptions).format(dateObj);

            this.days.push({
                dayOfWeek: dayStr,
                date: dateStr,
                month: monthStr,
                fullDate: fullDateStr,
                past: dateObj < today,
            });
        }
    },
    handleMouseDown(event) {
        event.preventDefault();
        this.isDragging = true;
        this.startX = event.clientX;
        this.lastScrollLeft = this.$refs.timeBlockContainer.scrollLeft;
    },
    handleMouseUp() {
        this.isDragging = false;
    },
    handleMouseMove(event) {
        if (!this.isDragging) return;
        const dx = this.startX - event.clientX;
        this.$refs.timeBlockContainer.scrollLeft = this.lastScrollLeft + dx;
    },
    selectTime(time){
        this.selectedTime = time;
    },
    async payWithExistingCard(sessionData){
        try {
            await axios.post('appointment/edit-session', {
                    type: sessionData.session_id,
                    appointment_id: this.session.appointment_id,
                    location_id: this.session.location_id,
                    appointment_datetime: this.createDateTime()
                }, 
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`
                    }
                }).then(() => { // использование стрелочной функции здесь
                    this.completedSession = true;
                })
        } catch (error) {
            this.$router.push('/');
            console.error('Ошибка при получении данных от API:', error)
        }
    },
    createDateTime() {
        // Предполагается, что dateStr имеет формат 'Tue, Oct 31', а timeStr - '12:00'
        const dateParts = this.selectedDate.split(', ')[1].split(' '); // Разбиваем строку на части
        const timeParts = this.selectedTime.split(':'); // Разбиваем время на часы и минуты

        // Создаем новую дату для текущего года
        const dateTime = new Date(new Date().getFullYear(), this.months[dateParts[0]], dateParts[1], timeParts[0], timeParts[1]);
        
        return dateTime;
    },
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-structure {
  background-color: #FAFCFF;
  padding: 20px;
 
  height: 100%;
  padding: 24px 96px 24px 96px;
}

.modal-header {
  margin-bottom: 22px;
}

.close-icon{
  cursor: pointer;
}

.toggle-slider.option2 {
    transform: translateX(calc(100%));
 }
  
  .toggle-switch-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
 }

  .type-description{
    margin-bottom: 32px;
 }

  .toggle-switch {
    position: relative;
    width: 370px;
    height: 38px;
    background-color: #F3F3F6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    border-radius: 15px;
    border: 1px solid var(#E6E8ED);
 }

  .toggle-switch-internal {
    position: relative;
    width: 100%;
    height: 38px;
    background-color: #F3F3F6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    border-radius: 15px;
    border: 1px solid var(#E6E8ED);
 }

  .toggle-option {
    cursor: pointer;
    z-index: 2;
    width:50%;
    text-align: center;
    transition: all 0.3s ease;
 }
  
  .toggle-option.active span {
    font-weight: bold;
 }

  .toggle-slider {
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(50% - 2px);
    height: calc(100% - 4px);
    background-color: #fff;
    border-radius: 15px;
    transition: all 0.3s ease;
    z-index: 1;
    box-shadow: 0px 3px 8px 0px #182D420D;
 }
  
  .toggle-slider.option2 {
    transform: translateX(calc(100%));
 }

  .sidebar_title{
    font-size: 24px;
    font-weight: bold;
 }

  .sidebar_sub_title{ 
    font-size: 16px;
    font-weight: bold;
 }

  .service-selection{
    background: #F3F3F6;
    padding: 8px 12px 8px 12px;
    min-height: 44px;
    border-radius: 8px;
    border: 1px solid#E6E8ED;
 }

  .additional-meeting-data{
    margin-top: 15px;
    margin-bottom: 28px;
 }

 .calender-structure{
  width: 100%;
 }
  .day {
    padding: 10px;
    margin: 5px;
    border: 1px solid black;
 }
  
  .selected {
    background: linear-gradient(73.96deg, #43C9F3 10.17%, #3CAEE0 91.5%) !important;
    color: #fff !important;
 }

 .day_of_the_week{
    width: 36px;
    height: 36px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border-radius: 20px;
    background: #F3F3F6;
    color: #6B7492;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
 }

 .day_of_the_week_block{
    width: 39px;
    padding: 0px;
    margin-left: 4px;
    margin-right: 6px;
 }

 .calender-switch-button{
    width: 6px;
    padding: 0px;
    background: transparent;
    border: none;
    margin-left: 1px;
    margin-right: 0px;
}

 .calender_block{
    padding: 24px;
    border-radius: 16px;
    border: 1px solid var(--main-colors-dark-15, #DADCE4);
    background: var(--main-colors-white, #FFF);
 }

 .calender-row{
    margin-left: 0px;
    margin-right: 0px;
 }

 .days-row{
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px;
    display: flex;
    width: fit-content;
 }

 #timeBlockContainer {
    display: flex;
    width: 100%;
    overflow: hidden;
    cursor: grab;
    white-space: nowrap;
    user-select: none;
    height: 28px;
    margin-top: 16px;
  }
  
  #timeBlockContainer:active {
    cursor: grabbing;
  }
  
  .timeBlock {
    width: 57px;
    height: 28px;
    padding: 4px 12px 4px 12px;
    border-radius: 15px;
    background: #F3F3F6;
    gap: 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: #6B7492;
    margin-right: 8px;
  }
  
  .profile-image{
    border-radius: 100px;
    border: 1px solid #E6E8ED;
  }
  
  .calender-title{
    margin-bottom: 12px;
  }

  .calender-description{
    margin-bottom: 8px;
  }

  @media (max-width: 768px) {
    .modal-structure{
      padding: 24px;
    }
  }
  
</style>