<template>
    <div class="container">
        <h1 class="fw-bold text-center" style="margin-top: 70px; margin-bottom: 48px">My Sessions</h1>
        <section class="w-100 type-section d-flex flex-column align-items-center no-sessions-section" v-if="sessions.length === 0">
            
            <p class="text-center type-description">Sorry, but you don’t have any sessions to show. </p>

            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-8 col-xs-8 no-sessions">
            </div>
        </section>

        <section v-if="sessions.length != 0">
            <div class="col-12">
                <div v-for="session in sessions" :key="session.id" class="w-100 session-block">
                    <div class="session-info">
                        <div class="therapist-block d-flex align-items-center">
                            <div class="">
                                <img  alt="Psychotherapist" class="img-fluid rounded-circle therapist_image">
                            </div>
                            <div class="therapist-info">
                                <div class="d-flex align-items-center therapist_name">
                                    {{ session.practitioner.first_name }} {{ session.practitioner.last_name}}
                                </div>
                                <div class="d-flex align-items-center therapist_provider_type">
                                    <span v-for="provider_type in session.practitioner.provider_types" :key="provider_type.index">{{ provider_type }}</span>
                                </div>
                            </div>
                           
                        </div>
                       
                        <div class="session-details d-flex">
                            <div class="d-flex align-items-center session-datetime">
                                <div class="datetime-element">
                                    <img src="../assets/images/calender.svg" alt=""> 
                                    <p class="session_datetime"> {{ session.date }}</p>
                                </div>
                                <div class="datetime-element">
                                    <img src="../assets/images/clock.svg" alt="">
                                    <p class="session_datetime"> {{ session.time }}</p>
                                </div>
                            </div>
                            <div class="devider"></div>
                            <div class="d-flex">
                                <div class="d-flex align-items-center session-type">
                                    <img src="../assets/images/online-icon.svg" alt="">
                                    <p>{{ session.booking_type }}</p>
                                </div>
                                <div class="d-flex align-items-center session-type">
                                    <img src="../assets/images/individual-therapy.svg" alt="">
                                    <p v-for="(service, index) in session.services" :key="index">{{service.service_name}}>{{ session.type }}</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                    <div class="session-actions">
                        <div class="d-flex align-items-center">
                            <button class="edit-button"  @click="openModal(session)">Edit</button>
                        </div>
                        <div class="d-flex align-items-center">
                            <img class="close-img" src="../assets/images/close-icon.svg" @click="deleteSession(session.appointment_id)" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <ModalComponent v-if="showModal" :isOpen="showModal" :session="currentEditableSession" @close="showModal = false" />
    </div>
</template>

<script>
import ModalComponent from './Components/SessionModalComponent.vue';
import axios from '@/axios';

export default {
  components: {
    ModalComponent
  },
  data() {
    return {
        sessions: [],
        currentEditableSession: null,
        showModal: false
    }
  }, 
  async mounted(){
    await this.getSessionData();
  },
  methods: {
    async getSessionData(){
        try {
            const response = await axios.get('/appointment/get-upcoming-sessions', {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`
                    }
                });
            if (response.data && response.data.length > 0) {
                this.sessions = response.data
            }
            this.sessions.forEach(
                async (session) => await this.getDateAndTimeOfSession(session)
            )
        } catch (error) {
            console.log(error);
        }
    },
    changeComponent(componentName, data = null) {
      this.currentComponent = componentName;
      this.currentData = data;
    },
    async getDateAndTimeOfSession(session){

        // Преобразование строки в объект Date
        var dateObj = new Date(session.appointment_datetime);

        // Получение даты
        var date = dateObj.getDate(); // День
        var month = dateObj.getMonth() + 1; // Месяц (начиная с 0)
        var year = dateObj.getFullYear(); // Год

        // Получение времени
        var hours = dateObj.getHours(); // Часы
        var minutes = dateObj.getMinutes(); // Минуты

        // Форматирование даты и времени в виде строк
        var dateString = `${year}-${month}-${date}`;
        var timeString = `${hours}:${minutes}`;

        session.date = dateString;
        session.time = timeString;
    },
    openModal(session){
        this.currentEditableSession = session;
        this.showModal = true;
    }
  },
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
    .no-sessions{
        padding: 0px 32px 0px 32px;
        border-radius: 16px;
        margin-bottom: 32px;
        background-image: url('../assets/images/no-therapists.svg');
        height: 240px;
        background-position: center;
        background-repeat: no-repeat;
    }
    .type-description{
        margin-bottom: 32px;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
    }

    .no-sessions-section{
        margin-top: 70px
    }

    .session-block{
        display: flex;
        padding: 20px;
        justify-content: space-between;
        border-radius: 16px;
        border: 1px solid #E6E8ED;
        background:#FFF;

        /* Shadow/Small Dark */
        box-shadow: 0px 4px 8px 0px rgba(51, 83, 196, 0.08);
    }
    .session-info{
        width: 60%;
        display: flex;
        justify-content: space-between;
        height: 48px;
    }
    .session-actions{
        display: flex;
        width: fit-content;
        align-items: center;
    }

    .session_datetime{
        margin-bottom: 0px;
    }

    .edit-button
    {
        min-width: 85px;
        margin: 0px;
        color: #43C9F3;
        background: #fff;
        border: 1px solid #43C9F3;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        border-radius: 50px;
        padding: 4px 24px 4px 24px;
        transition: 0.3s;
    }
    .edit-button:hover{
        color: #fff;
        background: #43C9F3;
    }

    .close-img{
        width: 16px;
    }

    .therapist_image{
        width: 48px;
        height: 48px;
    }

    .therapist-info{
        margin-bottom: 0px;
        margin-left: 12px;
    }

    .therapist_name{
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    .therapist_provider_type {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
    .session-type p{
        margin-bottom: 0px;
        margin-left: 4px; 
    }

    .session-type{
        margin-left: 24px;
    }

    .devider{
        width: 1px;
        height: 100%;
        background: #E6E8ED;
    }

    .session-details{
        padding: 13px 0px 13px 0px;
    }

    .datetime-element{
        display: flex;
        align-items: center;
        margin-right: 24px;
    }

    .datetime-element > p{
        margin-left: 4px;
    }

    .edit-button{
        margin-right: 22px;
    }
</style>